import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import moment from 'moment/moment'

import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { doc, filter , plantAndHand, calendar } from '@smartcoop/icons'
import { useSnackbar } from '@smartcoop/snackbar'
import { ChallengesActions } from '@smartcoop/stores/challenges'
import { OperationsActions } from '@smartcoop/stores/operations'
import { selectIsAccredited, selectIsWaiting } from '@smartcoop/stores/operations/selectorOperations'
import { selectIsProfileRTC } from '@smartcoop/stores/organization/selectorOrganization'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { colors } from '@smartcoop/styles'
import { momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import Badge from '@smartcoop/web-components/Badge'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import VerticalDotsIconButton from '@smartcoop/web-components/IconButton/VerticalDotsIconButton'
import MenuPopUp from '@smartcoop/web-components/MenuPopUp'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import Popover from '@smartcoop/web-components/Popover'
import ProjectsList from '@smartcoop/web-containers/fragments/projects/ProjectsList'
import OperationList from '@smartcoop/web-containers/fragments/technical/OperationList'
import TechnicalEvaluationList from '@smartcoop/web-containers/fragments/technical/TechnicalEvaluationList'
import TechnicalPortfolio from '@smartcoop/web-containers/fragments/technical/TechnicalPortfolio'
import TechnicalVisitList from '@smartcoop/web-containers/fragments/technical/TechnicalVisitList'
import CreateProjectModal from '@smartcoop/web-containers/modals/challenges/CreateProjectModal'
import CreateOperationModal from '@smartcoop/web-containers/modals/operations/CreateOperationModal'
import FilterTechnicalPortfolioModal from '@smartcoop/web-containers/modals/technical/FilterTechnicalPortfolioModal'
import FilterTechnicalVisitModal from '@smartcoop/web-containers/modals/technical/FilterTechnicalVisitModal'
import PropertyTechnicalVisitModal from '@smartcoop/web-containers/modals/technical/PropertyTechnicalVisitModal'
import PropertyTechnicalVisitScheduleModal from '@smartcoop/web-containers/modals/technical/PropertyTechnicalVisitScheduleModal'
import RegisterTechnicalEvaluationModal from '@smartcoop/web-containers/modals/technical/RegisterTechnicalEvaluationModal'
import TechnicianGroupsListModal from '@smartcoop/web-containers/modals/technical/TechnicianGroupsListModal'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import {
  Container,
  Row,
  ButtonContainer,
  TabButton,
  OperationHeader,
  OperationDivider
} from './styles'

const TechnicalAreaScreen = () => {
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()
  const t  = useT()
  const history = useHistory()
  const { routes } = useRoutes()
  const location = useLocation()

  const projectsTableRef = useRef()
  const operationsTableRef = useRef()
  const technicalVisitsTableRef = useRef(null)

  const accredited = useSelector(selectIsAccredited)
  const isWaitingAccreditment = useSelector(selectIsWaiting)
  const isRTC = useSelector(selectIsProfileRTC)

  const [listType, setListType] = useState(location?.state?.fromAccreditments ? 'operation' : 'producerPortfolio')
  const [filters, setFilters] = useState({})
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    dispatch(
      TechnicalActions.loadTechnicalOwners()
    )
  },[dispatch])

  useEffect(() => {
    history.replace()
  }, [history])

  const statusBadge = useMemo(() => {
    if(isWaitingAccreditment){
      return {
        title: t('waiting approval'),
        color: '#1643b7'
      }
    }
    if(accredited) {
      return {
        title: `${ t('accredited until') } ${ moment(accredited?.expirationDate, 'YYYY-MM-DD h:mm:ss').format(momentFriendlyDateFormat) }`,
        color: '#289f30'
      }
    }
    return {
      title: t('you are not accredited'),
      color: '#E41D1B'
    }
  },[accredited, isWaitingAccreditment, t])

  const handleFilter = useCallback(
    (values) => setFilters(values),
    []
  )

  const handleCreate = useCallback(
    () => {
      dispatch(
        ChallengesActions.loadChallenges()
      )
      dispatch(
        TechnicalActions.loadTechnicalEvaluationAnswers()
      )
    },
    [dispatch]
  )

  const handleCreateOperation = useCallback(() => {
    if(!isRTC) {
      dispatch(OperationsActions.checkAccredited())
    }
    dispatch(
      OperationsActions.loadOperations(
        { ...filters }
      )
    )
  }, [dispatch, filters, isRTC])

  const filterData = useCallback(
    () => {
      createDialog({
        id: 'filter-technical-visit',
        Component: listType === 'producerPortfolio' ? FilterTechnicalPortfolioModal : FilterTechnicalVisitModal,
        props: {
          onSubmit: handleFilter,
          filters
        }
      })
    },
    [createDialog, filters, handleFilter, listType]
  )

  const createNewProject = useCallback(
    () => {
      createDialog({
        id: 'create-new-project',
        Component: CreateProjectModal,
        props: {
          onSubmit: handleCreate
        }
      })
    },
    [createDialog, handleCreate]
  )

  const createNewEvaluation = useCallback(
    () => {
      createDialog({
        id: 'evaluation-modal',
        Component: RegisterTechnicalEvaluationModal,
        props: {
          onSubmit: handleCreate
        }
      })
    },
    [createDialog, handleCreate]
  )

  const requestAccreditment = useCallback(
    () => {
      createDialog({
        id: 'confirm-request',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(
              OperationsActions.requestAccreditment(
                () => {
                  snackbar.success(
                    t('accreditment requested')
                  )
                  dispatch(
                    OperationsActions.checkAccredited()
                  )
                }
              )
            )
          },
          textWarning: t('do you really want to request the accreditment')
        }
      })
    },
    [createDialog, dispatch, snackbar, t]
  )

  const createTechnicalVisit = useCallback(
    (_, row = {}) => {
      createDialog({
        id: 'view-edit-technical-visit',
        Component: PropertyTechnicalVisitModal,
        props: {
          fromTechnicalArea: true,
          technicalVisit: row,
          onSuccess: () => {
            setLoading(true)
            dispatch(TechnicalActions.loadTechnicalVisits(
              { limit: 999999 },
              () => setLoading(false),
              () => setLoading(false)
            ))
          }
        }
      })
    },
    [createDialog, dispatch]
  )
  const createTechnicalVisitSchedule = useCallback((scheduled = false) => {
    createDialog({
      id: 'register-technical-visit',
      Component: PropertyTechnicalVisitScheduleModal,
      props: {
        fromTechnicalArea: true,
        onSuccess: () => {
          snackbar.success(
            t('your {this} was registered', {
              howMany: 1,
              gender: 'male',
              this: t('schedulement')
            }))
          dispatch(TechnicalActions.loadTechnicalVisits(
            { limit: 999999 },
            () => setLoading(false),
            () => setLoading(false)
          ))
        },
        scheduled
      }
    })
  }, [createDialog, dispatch, snackbar, t])


  const dataTableList = useMemo(
    () => ({
      'producerPortfolio': <TechnicalPortfolio filters={ filters } />,
      'technicalVisits': <TechnicalVisitList loading={ loading } filters={ filters } ref={ technicalVisitsTableRef } createTechnicalVisit={ createTechnicalVisit } createTechnicalVisitSchedule={ createTechnicalVisitSchedule } />,
      'projects': <ProjectsList ref={ projectsTableRef }/>,
      'operation': <OperationList ref={ operationsTableRef } accredited={ accredited }/>,
      'technical-evaluation': <TechnicalEvaluationList isTechnical />
    }[listType]),
    [accredited, createTechnicalVisit, createTechnicalVisitSchedule, filters, listType, loading]
  )

  const buttons = useMemo(
    () => ({
      'projects': (
        <Button
          id="create-new-project"
          onClick={ createNewProject }
          color="secondary"
          style={ { flex: 'none', whiteSpace: 'nowrap' } }
        >
          <>
            <I18n>register new participant</I18n>
          </>
        </Button>
      ),
      'technicalVisits': (
        <>
          <Button
            id="create-new-visit"
            onClick={ createTechnicalVisitSchedule }
            color="secondary"
            style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 10 } }
          >
            <>
              <Icon
                icon={ calendar }
                size={ 17 }
                style={ { marginRight: 5 } }
              />
              {t('schedule')}
            </>
          </Button>
          <Button
            id="create-new-visit"
            onClick={ createTechnicalVisit }
            color="secondary"
            style={ { flex: 'none', whiteSpace: 'nowrap' } }
          >
            <>
              <Icon
                icon={ plantAndHand }
                size={ 17 }
                style={ { marginRight: 5 } }
              />
              <I18n>register</I18n>
            </>
          </Button>
        </>
      ),
      'technical-evaluation': (
        <Button
          id="create-new-evaluation"
          onClick={ createNewEvaluation }
          color="secondary"
          style={ { flex: 'none', whiteSpace: 'nowrap' } }
        >
          <>
            <I18n>new evaluation</I18n>
          </>
        </Button>
      ),
      'operation' : (
        <>

        </>
      ),
      'producerPortfolio' : (
        <Popover
          popoverId="groups-menu"
          Component={ VerticalDotsIconButton }
        >
          <MenuPopUp
            options={ [
              {
                icon: null,
                text: t('producer groups'),
                onClick: () => createDialog({
                  id: 'create-new-operation',
                  Component: TechnicianGroupsListModal
                })
              }
            ] }
          />
        </Popover>
      )
    }[listType] || (<></>)),
    [createDialog, createNewEvaluation, createNewProject, createTechnicalVisit, createTechnicalVisitSchedule, listType, t]
  )

  useEffect(() => {
    setFilters({})
  }, [listType])

  useEffect(
    () => {
      dispatch(TechnicalActions.resetTechnicalCurrentOwner())
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const createNewOperation = useCallback(
    () => {
      createDialog({
        id: 'create-new-operation',
        Component: CreateOperationModal,
        props: {
          onSubmit: handleCreateOperation
        }
      })
    },
    [createDialog, handleCreateOperation]
  )


  return (
    <Container>
      <Row>
        <ButtonContainer>
          <OperationHeader>
            <TabButton
              id="producer-portfolio"
              onClick={ () => setListType('producerPortfolio') }
              color={ listType === 'producerPortfolio' ? colors.black : colors.white }
            >
              <I18n>producer portfolio</I18n>
            </TabButton>
            <TabButton
              id="technical-visits"
              onClick={ () => setListType('technicalVisits') }
              color={ listType === 'technicalVisits' ? colors.black : colors.white }
            >
              <I18n>technical visit</I18n>
            </TabButton>
            <TabButton
              id="projects"
              onClick={ () => setListType('projects') }
              color={ listType === 'projects' ? colors.black : colors.white }
            >
              <I18n params={ { howMany: 2 } }>project</I18n>
            </TabButton>
            <TabButton
              id="operation"
              onClick={ () => setListType('operation') }
              color={ listType === 'operation' ? colors.black : colors.white }
            >
              <I18n>operation 365</I18n>
            </TabButton>
            <TabButton
              id="technical-evaluation"
              onClick={ () => setListType('technical-evaluation') }
              color={ listType === 'technical-evaluation' ? colors.black : colors.white }
            >
              <I18n>technical evaluation</I18n>
            </TabButton>
          </OperationHeader>

          <OperationHeader style={ { justifyContent: 'flex-end' } }>
            {(listType === 'technicalVisits' || listType === 'producerPortfolio') && (
              <Button
                id="filter"
                onClick={ filterData }
                color={ isEmpty(filters) ? 'white' : 'secondary' }
                style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 12 } }
              >
                <>
                  <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 }/>
                  <I18n>filtrate</I18n>
                </>
              </Button>
            )}
            {buttons}
          </OperationHeader>
        </ButtonContainer>
      </Row>
      <div>
        {(listType === 'operation') && (
          <OperationDivider>
            <Row>
              <Badge
                backgroundColorBadge={ statusBadge?.color }
                colorBadge={ statusBadge?.color }
                style={ { width: '15vw' } }
              >
                {statusBadge?.title}
              </Badge>
              {!accredited && !isWaitingAccreditment && (
                <Button
                  id="accredit-button"
                  onClick={ requestAccreditment }
                  variant="outlined"
                  style={ { marginLeft: 12, maxWidth: '18vw' } }
                >
                  {t('request accreditment')}
                </Button>
              )}
              <Button
                style={ { marginLeft: 12, maxWidth: '10vw' } }
                id="view-accredit-button"
                onClick={ () => history.push(routes?.accreditmentList?.path) }
                variant="outlined"
              >
                <Icon icon={ doc } size={ 16 } style={ { marginRight: 10 } }/>
                {t('certifications')}
              </Button>
            </Row>

            {accredited && (
              <Row style={ { justifyContent: 'flex-end' } }>
                <Button
                  id="filter"
                  onClick={ filterData }
                  color={ isEmpty(filters) ? 'white' : 'secondary' }
                  style={ { flex: 'none', whiteSpace: 'nowrap', marginRight: 12 } }
                >
                  <>
                    <Icon style={ { paddingRight: 6 } } icon={ filter } size={ 14 }/>
                    <I18n>filtrate</I18n>
                  </>
                </Button>
                <Button
                  id="new-operation"
                  onClick={ createNewOperation }
                  color="secondary"
                  style={ { flex: 'none', whiteSpace: 'nowrap', alignSelf: 'flex-end' } }
                >
                  <>
                    <I18n>register new participant</I18n>
                  </>
                </Button>
              </Row>
            )}
          </OperationDivider>
        )}
        {dataTableList}
      </div>
    </Container>
  )
}

export default TechnicalAreaScreen
