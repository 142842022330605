import toNumber from 'lodash/toNumber'

export default ({ t, field, limit = 0 }) => (YupInstance) =>
  YupInstance.test(
    'minNumberValue',
    t('{this} must be greater than {number}', { this: field, number: limit }),
    (value) => {
      const resolvedLimit = typeof limit === 'object' ? this.resolve(limit) : limit
      if (!value || resolvedLimit === undefined) return true // Passa se valor ou limite não existirem
      return toNumber((value || '').replace(',', '.')) >= toNumber(resolvedLimit)
    }

  )
