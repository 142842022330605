import trimMask from '@meta-awesome/functions/src/trimMask'

import api from '../api'

export const getUser = async (params, { userId }, accessToken) => {
  const { data } = await api.get(`/v1/users/${ userId }`, {
    params,
    headers: accessToken ? {
      'authorization': accessToken
    } : {}
  })
  return data
}

export const getUsers = async (params) => {
  const { data } = await api.get('/v1/users', {
    params
  })
  return data
}

export const getUserByCpf = async (params, { document: doc }) => {
  const { data } = await api.get(`/v1/users/document/${ trimMask(doc) }`, { params })
  return data
}

export const getUserByCpfDateOfBirth = async (
  params,
  { document: doc, dateOfBirth }
) => {
  const { data } = await api.get(`/v1/users/${ trimMask(doc) }/${ dateOfBirth }`, {
    params
  })
  return data
}

export const getUserOrganizations = async (params, { userId }) => {
  const { data } = await api.get(`/v1/users/${ userId }/organizations`, { params })
  return data
}

export const getUserOrganizationsByModule = async ({ userId, moduleSlug }) => {
  const { data } = await api.get(
    `/v1/users/${ userId }/organizations/module/${ moduleSlug }`
  )
  return data
}

export const updateUser = async (params, { userId }) =>
  api.patch(`/v1/users/${ userId }`, params)

export const createProfilePicture = async (params, { userId }) =>
  api.post(`/v1/users/${ userId }/profile-photo`, params, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const preRegistration = async (params) =>
  api.post('/v1/users/pre-registration', params, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const updateProfilePicture = async (params, { userId, photoId }) =>
  api.put(`/v1/users/${ userId }/profile-photo/${ photoId }`, params, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  })

export const deleteUserOrganization = (params, { organizationId }) =>
  api.delete(`/v1/users/organization/${ organizationId }`, { data: params })

export const revokeUserAccess = (params) =>
  api.patch('v1/users/revoke-access', params)
