import React from 'react'

import PropTypes from 'prop-types'

import map from 'lodash/map'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { emptyFilter, plus } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import StateRegistrationRegisterModal from '@smartcoop/web-containers/modals/StateRegistrationRegisterModal'

import StateRegistrationsItemFragment from './StateRegistrationsItemFragment'
import {
  Container,
  Content,
  Header,
  HeaderTitle
} from './styles'

export default function StateRegistrationsFragment({
  stateRegistrations,
  loadStateRegistrations
}) {
  const { createDialog } = useDialog()
  const t = useT()

  const handleOpen = (data = null) => {
    createDialog({
      id: 'state-registration-modal',
      Component: StateRegistrationRegisterModal,
      props: {
        onClose: loadStateRegistrations,
        defaultValues: data
      }
    })
  }

  return (
    <Container>
      <Header>
        <HeaderTitle>
          {t('state registrations')}
        </HeaderTitle>
        <IconButton onClick={ () => handleOpen() }>
          <Icon
            icon={ plus }
            size={ 20 }
            color={ colors.text }
          />
        </IconButton>
      </Header>
      <Content>
        {stateRegistrations?.length ? (
          map(stateRegistrations, (stateRegistration) => (
            <StateRegistrationsItemFragment
              stateRegistration={ stateRegistration }
              handleOpenEditModal={ () => handleOpen(stateRegistration) }
              key={ stateRegistration.id }
              t={ t }
            />
          ))
        ) : (
          <EmptyState
            text={ t('no state registration found') }
            icon={ emptyFilter }
            iconProps={ {
              size: 120,
              style: { marginRight: 40 }
            } }
          />
        )}
      </Content>
    </Container>
  )
}

StateRegistrationsFragment.propTypes = {
  stateRegistrations: PropTypes.array,
  loadStateRegistrations: PropTypes.func.isRequired
}

StateRegistrationsFragment.defaultProps = {
  stateRegistrations: []
}
