/* eslint-disable no-nested-ternary */
import React, { useCallback, forwardRef, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'


import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import join from 'lodash/join'
import map from 'lodash/map'
import replace from 'lodash/replace'
import split from 'lodash/split'
import toString from 'lodash/toString'

import { useDialog } from '@smartcoop/dialog'
import propertyTechnicalVisitSchema from '@smartcoop/forms/schemas/property/propertyTechnicalVisit.schema'
import technicalVisitScheduleSchema from '@smartcoop/forms/schemas/property/technicalVisitSchedule.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { noImage, plus, signature as iconSignature, warning, eyeOpened } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { getOpenGrowingSeasonsByProperty } from '@smartcoop/services/apis/smartcoopApi/resources/growingSeasons'
import { getPropertiesByOwner, getPropertyGrowingSeasonsHistory } from '@smartcoop/services/apis/smartcoopApi/resources/property'
import { getTechnicalGoals, getTechnicalEvaluationProducers } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { selectModuleIsTechnical } from '@smartcoop/stores/module/selectorModule'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { selectCurrentOwner, selectCurrentOwnerFamilyGroup, selectTechnicalOwners, selectTechnicalVehicles } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import { PHYTOSANITARY_APPLICATION_PRODUCT_GROUPS_OBJECT, SEEDTREATMENT_PRODUCT_GROUPS_OBJECT } from '@smartcoop/utils/constants'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import Badge from '@smartcoop/web-components/Badge'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import DataTable from '@smartcoop/web-components/DataTable'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import VerticalDotsIconButton from '@smartcoop/web-components/IconButton/VerticalDotsIconButton'
import InputDate from '@smartcoop/web-components/InputDate'
import InputFile from '@smartcoop/web-components/InputFile'
import InputHour from '@smartcoop/web-components/InputHour'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import MenuPopUp from '@smartcoop/web-components/MenuPopUp'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import Popover from '@smartcoop/web-components/Popover'
import ThumbnailImage from '@smartcoop/web-components/ThumbnailImage'
import useFile from '@smartcoop/web-containers/hooks/useFile'
import { ButtonsContainer } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import SignatureModal from '@smartcoop/web-containers/modals/SignatureModal/SignatureModal'
import TechnicalVehiclesModal from '@smartcoop/web-containers/modals/technical/TechnicalVehiclesModal'
import TechnicalVisitRecommendationModal from '@smartcoop/web-containers/modals/technical/TechnicalVisitRecommendationModal'

import { Container, TextDate, Gallery, CheckboxContainer, Title, ContainerSchedulement, SignatureContainer } from './styles'

const PropertyTechnicalVisitForm = forwardRef((props, formRef) => {
  const { style, technicalVisit, disabled, onSuccess, handleClose, readOnly, scheduled, growingSeasonId, fromTechnicalArea } = props
  const dispatch = useCallback(useDispatch(), [])
  const [isEditing, setIsEditing] = useState(disabled)
  const [notify, setNotify] = useState(technicalVisit[0]?.notify || false)
  const [selectedVehicle, setSelectedVehicle] = useState(technicalVisit?.[0]?.kmControl?.vehicle || false)
  const [units, setUnits] = useState(map(technicalVisit, item => item?.estimateUnity))
  const [goalsOptions, setGoalsOptions] = useState([])
  const [growingSeasons, setGrowingSeasons] = useState([])
  const [growingSeasonsOptions, setGrowingSeasonsOptions] = useState([])
  const [fieldsGrowingSeasonIds, setFieldsGrowingSeasonIds] = useState(!isEmpty(technicalVisit?.[0]?.id) ? filter(map(technicalVisit, item => item?.growingSeasonId || item?.fieldId), item => item) :  growingSeasonId ? [growingSeasonId] : [])
  const [technicalVisitFormFiles, setTechnicalVisitFormFiles] = useState([])
  const [recommendations, setRecommendations] = useState(map(groupBy(technicalVisit[0]?.visitRecommendations, 'bulkId'), grouped => ({ ...grouped[0], growingSeasonIds: map(grouped, item => item?.growingSeasonId), cropManagement: grouped[0]?.cropManagement === 'true' })) || [])
  const [propertyId, setPropertyId] = useState(technicalVisit[0]?.propertyId || useSelector(selectCurrentPropertyId) || null)

  const [properties, setProperties] = useState([])

  const [signatures, setSignatures] = useState([])

  const {
    selectedFiles,
    receivedFiles,
    isEmpty: isEmptyFiles,
    handleAdd,
    createFormData,
    handleRemove
  } = useFile([], filter(technicalVisit?.[0]?.technicalVisitImages, item => !includes(item.fileKey, 'signature'))|| [])

  const { createDialog } = useDialog()
  const t = useT()
  const currentOwnerFamilyGroup = useSelector(selectCurrentOwnerFamilyGroup)
  const isTechnicalModule = useSelector(selectModuleIsTechnical)
  const currentUser = useSelector(selectUser)
  const currentOwner= useSelector(selectCurrentOwner)
  const technicalOwners = useSelector(selectTechnicalOwners)
  const technicalVehicles = useSelector(selectTechnicalVehicles)

  const [producer, setProducer] = useState(technicalVisit?.[0]?.users[0]?.id || currentOwner?.id || null)
  const [owners, setOwners] = useState([])

  const technicalVehiclesOptions = useMemo(() => map(technicalVehicles, item => ({
    label: item?.plate,
    value: item?.id,
    item
  })), [technicalVehicles])

  useEffect(() => {
    const getOwners = async() => {
      const { data: { data } } = await getTechnicalEvaluationProducers({ limit: 99999 })

      setOwners(map(data, item => ({ label: item?.name, value: item?.id })))
    }

    getOwners()
  }, [])

  const availableProducers = useMemo(() => filter(technicalOwners, item => item?.proprietaryTechnician?.read || item?.proprietaryTechnician?.assistance),[technicalOwners])

  const userOptions = useMemo(() => [currentUser, ...currentOwnerFamilyGroup] ,[currentOwnerFamilyGroup, currentUser])

  const loadFieldOptions = useCallback(
    async () => {
      const { data } = await getOpenGrowingSeasonsByProperty({ propertyId })
      if(technicalVisit?.[0]?.fieldId && !technicalVisit?.[0]?.growingSeasonId) {
        const { data: { data: gsHistory } } = await getPropertyGrowingSeasonsHistory({ isPlanned: false }, { propertyId })
        const updatedData = map(gsHistory, item => ({ ...item, field: item?.fieldData }))
        setGrowingSeasons([...data, ...updatedData])
      } else {
        setGrowingSeasonsOptions(map(data, item => {
          const childrenPolygon = find(item?.field?.childrenPolygons, polygon => polygon?.id === item?.childrenPolygonId)
          return (
            {
              label: `${ item?.field?.fieldName } - ${ !isEmpty(childrenPolygon) ? (`${ childrenPolygon.name  } -`) : '' } ${ item?.crop?.description }/${ item.sowingYear }`,
              value: item.id
            }
          )
        }))
        setGrowingSeasons(data)
      }
    },
    [propertyId, technicalVisit]
  )

  const loadTechnicalVehicles = useCallback((params = {}) => {
    dispatch(TechnicalActions.loadTechnicalVehicles({ limit: 9999, ...params }))
  }, [dispatch])

  useEffect(() => {
    if(!isEmpty(propertyId)) {
      loadFieldOptions()
    }
  },[loadFieldOptions, loadTechnicalVehicles, propertyId])

  useEffect(() => {
    loadTechnicalVehicles()
  }, [loadTechnicalVehicles])

  const fieldsOptions = useMemo(() => {
    if(technicalVisit?.[0]?.fieldId && !technicalVisit?.[0]?.growingSeasonId) {
      const foundFields = []
      map(technicalVisit, visit => {
        const found = find(flatMap(growingSeasons, gs => gs.field), gsField => gsField?.id === visit.fieldId)
        if(found){
          foundFields.push({
            label: found?.fieldName,
            value: found?.id
          })
        }
      })

      return !isEmpty(foundFields) ? foundFields : null
    }
    return null
  }, [growingSeasons, technicalVisit])

  useEffect(() => {
    if(fieldsOptions && !isEditing) {
      setFieldsGrowingSeasonIds([])
    }
  },[fieldsOptions, isEditing])

  const loadTechnicalGoals = useCallback(
    async () => {
      const { data: { data } } = await getTechnicalGoals()
      setGoalsOptions(map(data, item => ({ value: item?.id, label: item?.description })))
    },
    []
  )

  useEffect(() => {
    loadTechnicalGoals()
  },[loadFieldOptions, loadTechnicalGoals])

  const handleSubmitRegisterTechnicalVisit = useCallback(
    (data) => {
      const formData = createFormData('uploads')
      if(!isEmpty(signatures)) {
        const signaturesUpdated = []
        if(!isEmpty(signatures)) {
          map(signatures, item => {
            signaturesUpdated.push({ filename: `signature-${ item?.userId }.png`, file: replace(item.signature, 'data:image/png;base64,', '') })
          })
        }
        formData.append('signatures', JSON.stringify(signaturesUpdated))
      }

      createDialog({
        id: 'confirm-register-property-technical-visit',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(TechnicalActions.saveTechnicalVisit(
              {
                ...data,
                fields: !isEmpty(data?.fields) ? map([...data?.fields], (item,index) => {
                  const foundGrowingSeason = find(growingSeasons, gs => gs.id === fieldsGrowingSeasonIds[index])
                  if(foundGrowingSeason) {
                    return {
                      ...item,
                      growingSeasonId: foundGrowingSeason?.id,
                      id: foundGrowingSeason?.fieldId
                    }
                  }
                  return {
                    ...item,
                    id: fieldsGrowingSeasonIds[index]
                  }
                }) : [],
                visitId: !isEmpty(technicalVisit) ? technicalVisit[0]?.visitId : null,
                visitDateTime: scheduled ? null : `${ data?.dateVisit } ${ data?.hour }`,
                predictedDate: scheduled ? `${ data?.predictedDate } ${ data?.hour }` : technicalVisit[0]?.predictedDate,
                status: scheduled ? 'agendada' : 'realizada',
                notify,
                propertyId,
                oldImages: receivedFiles,
                visitRecommendations: map(recommendations, item => {
                  const newItem = { ...item, operationId: item?.operation?.id, observation: !isEmpty(item?.observations) ? item?.observations : item?.observation, itens: !isEmpty(item?.items) ? item?.items : item?.itens }
                  delete newItem.mockedId
                  delete newItem.tableData
                  return newItem
                }),
                kmControl: isEmpty(data?.kmControl?.vehicleId) ? null : (technicalVisit?.[0]?.kmControl?.id ? ({
                  ...data?.kmControl,
                  id: technicalVisit?.[0]?.kmControl?.id || null
                }) : data?.kmControl)
              },
              formData,
              propertyId,
              onSuccess,
              handleClose,
              !isEmpty(technicalVisit) && technicalVisit[0]?.visitId
            ))
          },
          textWarning: !isEmpty(technicalVisit) && technicalVisit[0].id && technicalVisit[0].visitDateTime ? t('are you sure you want to edit this technical visit') : t('are you sure you want to register this technical visit')
        }
      })
    },
    [createDialog, createFormData, dispatch, fieldsGrowingSeasonIds, growingSeasons, handleClose, notify, onSuccess, propertyId, receivedFiles, recommendations, scheduled, signatures, t, technicalVisit]
  )

  const renderSignatures = useMemo(() => !isEmpty(signatures) ? map(signatures, item => {
    const foundUser = find(userOptions, user => user.id === item.userId)
    return (
      <SignatureContainer>
        <ThumbnailImage
          key={ item.userId }
          src={ downloadFromBase64(item.signature) }
          onClose={ isEditing ? null : () => setSignatures(filter([...signatures], signature => signature.userId !== item.userId)) }
          style={ { width: 300, height: 150 } }
        />
        {foundUser?.name}
      </SignatureContainer>
    )
  }) : null, [isEditing, signatures, userOptions])

  const dateAndTime = useMemo(
    () => !isEmpty(technicalVisit) && ({
      'hour': moment(`${ technicalVisit[0].visitDateTime }`, 'YYYY-MM-DD HH:mm').format('HH:mm'),
      'date': moment(`${ technicalVisit[0].visitDateTime }`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
    }),
    [technicalVisit]
  )

  const unitProductivity = useMemo(
    () => (
      [
        {
          label: 'kg/ha',
          value: 'kg/ha'
        },
        {
          label: 'ton/ha',
          value: 'ton/ha'
        },
        {
          label: 'sacas/ha',
          value: 'sacas/ha'
        }
      ]
    ),
    []
  )

  const visitModeOptions = useMemo(
    () => (
      [
        {
          label: 'Presencial',
          value: 'presential'
        },
        {
          label: 'Virtual',
          value: 'virtual'
        }
      ]
    ),
    []
  )

  const handleClickClose = useCallback(
    (list, removedFile) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => handleRemove(list, removedFile),
          textWarning: t('are you sure you want to remove this file')
        }
      })
    },
    [createDialog, handleRemove, t]
  )

  const openSignatureModal = useCallback(
    () => {
      createDialog({
        id: 'signature-modal',
        Component: SignatureModal,
        props: {
          onSubmit: (signature) => setSignatures(old => [...old, signature]),
          signatures
        }
      })
    },
    [createDialog, signatures]
  )

  const renderFile = useCallback(async (file) => {
    const { data: { file: data } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_TECHNICAL_VISIT_IMAGES_ID, fileKey: file?.fileKey })

    return downloadFromBase64(data)
  }, [])

  useEffect(() => {
    const currentSignatures = filter(technicalVisit[0]?.technicalVisitImages, item => includes(item.fileKey, 'signature'))
    const getSignatures = async () => {
      const updatedSignatures = await Promise.all(map(currentSignatures, async item => {
        const fileSrc = await renderFile(item)

        return {
          userId: item.fileKey.substring(item.fileKey.indexOf('signature-')+10, item.fileKey.indexOf('.')),
          signature: fileSrc
        }
      }))
      setSignatures(updatedSignatures)
    }
    getSignatures()

  },[renderFile, technicalVisit])

  useEffect(() => {
    const getRenderedFiles = async() => {
      const receivedElements = await Promise.all(map(receivedFiles, async(file) => {
        const fileSrc = await renderFile(file)
        return (
          <ThumbnailImage
            key={ file.id }
            src={ fileSrc }
            onClose={ isEditing ? null : () => handleClickClose('receivedFiles', file) }
          />
        )}).concat(

        map(selectedFiles, (file, index) => (
          <ThumbnailImage
            key={ `${ index }-${ file.name }` }
            src={ URL.createObjectURL(file) }
            onClose={ isEditing ? null : () => handleClickClose('selectedFiles', file) }
            name={ file?.name }
          />
        ))
      ))

      setTechnicalVisitFormFiles(receivedElements)
    }

    getRenderedFiles()
  },[createDialog, handleClickClose, isEditing, receivedFiles, renderFile, selectedFiles, t])

  const applyToManagement = useCallback((data) => {
    const fields = formRef.current.getFieldValue('fields')
    const formData = new FormData()
    createDialog({
      id: 'confirm-register-property-technical-visit',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          dispatch(TechnicalActions.saveTechnicalVisit(
            {
              ...technicalVisit[0],
              technicianId: technicalVisit?.[0]?.technicianId,
              technicianRegistry: technicalVisit?.[0]?.technicalCooperative?.[0]?.registry,
              fields: !isEmpty(fields) ? map([...fields], (item,index) => {
                const foundGrowingSeason = find(growingSeasons, gs => gs.id === fieldsGrowingSeasonIds[index])
                if(foundGrowingSeason) {
                  return {
                    ...item,
                    growingSeasonId: foundGrowingSeason?.id,
                    id: foundGrowingSeason?.fieldId
                  }
                }
                return {
                  ...item,
                  id: fieldsGrowingSeasonIds[index]
                }
              }) : [],
              visitId: !isEmpty(technicalVisit) ? technicalVisit[0]?.visitId : null,
              visitDateTime: technicalVisit[0]?.visitDateTime,
              predictedDate: technicalVisit[0]?.predictedDate,
              status: scheduled ? 'agendada' : 'realizada',
              notify,
              propertyId,
              oldImages: receivedFiles,
              visitRecommendations: map(recommendations, item => {
                const newItem = { ...item, operationId: item?.operation?.id }
                delete newItem.mockedId
                delete newItem.tableData
                if(newItem?.id === data?.id) {
                  newItem.cropManagement = true
                }
                return newItem
              })
            },
            formData,
            propertyId,
            onSuccess,
            handleClose,
            !isEmpty(technicalVisit) && technicalVisit[0]?.visitId
          ))
        },
        textWarning: t('are you sure you want to apply this recommendation to fields')
      }
    })
  }, [createDialog, dispatch, fieldsGrowingSeasonIds, formRef, growingSeasons, handleClose, notify, onSuccess, propertyId, receivedFiles, recommendations, scheduled, t, technicalVisit])

  const phytosanitaryProductGroups = useCallback((recommendation) => {
    const cropManagementItem = recommendation?.items || recommendation?.itens || []
    const itemProductGroups = map(cropManagementItem?.formFields, item => recommendation?.operation?.slug === 'aplicacao-fitossanitaria' ? PHYTOSANITARY_APPLICATION_PRODUCT_GROUPS_OBJECT[item?.productGroup] : SEEDTREATMENT_PRODUCT_GROUPS_OBJECT[item?.productGroup])
    return join(itemProductGroups, ' | ')
  },[])

  const soilPrepareItems = useCallback((recommendation) => {
    const cropManagementItem = recommendation?.items || recommendation?.itens || []

    return join(cropManagementItem?.type, ' | ')
  }, [])


  const recommendationColumns = useMemo(
    () => [
      {
        title: t('field/crop'),
        field: 'growingSeasonIds',
        render: row => map(map(isEmpty(row?.growingSeasonIds) ? [row?.growingSeasonId] : row?.growingSeasonIds, item => find(growingSeasons, field => item === field.id)), item => {
          const foundChildrenPolygon = find(item?.field?.childrenPolygons, childrenPolygon => childrenPolygon?.id === item?.childrenPolygonId)
          const childrenData = !isEmpty(foundChildrenPolygon) ? `${ foundChildrenPolygon?.name } -` : ''
          return `${ childrenData } ${ item?.field?.fieldName } - ${ item?.crop?.description }/${ item?.sowingYear }`
        }).join(' | ')
      },
      {
        title: t('operation'),
        field: 'operationSlug',
        render: row => (
          <div style={ { display: 'flex', flexDirection: 'column' } }>
            <div>
              {row?.operation?.name}
            </div>
            { row?.operation?.slug === 'aplicacao-fitossanitaria' || row?.operation?.slug ===  'tratamento-de-sementes' ? (
              <div style={ { fontSize: 11 } }>
                {phytosanitaryProductGroups(row)}
              </div>
            ) : null }
            { row?.operation?.slug === 'preparo-de-solo' ? (
              <div style={ { fontSize: 11 } }>
                {soilPrepareItems(row)}
              </div>
            ) : null }
          </div>
        ),
        width: '25%'
      },
      {
        title: t('predicted date'),
        field: 'predictedDate',
        render: row => moment(row?.predictedDate, momentBackDateFormat).format(momentFriendlyDateFormat),
        width: '25%'
      },
      {
        align: 'center',
        title: t('status'),
        field: 'cropManagement',
        render: row => (
          <Badge
            backgroundColorBadge={ toString(row?.cropManagement) !== 'false' ? colors.secondary : colors.darkGrey  }
            colorBadge={ toString(row?.cropManagement) !== 'false' ? colors.secondary : colors.darkGrey  }
          >
            {toString(row?.cropManagement) !== 'false' ? t('scheduled') : t('recommended') }
          </Badge>
        ),
        width: '25%'
      }
    ],
    [growingSeasons, phytosanitaryProductGroups, soilPrepareItems, t]
  )

  const recommendationActions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: () => applyToManagement(row),
        disabled: !row?.cropManagement,
        tooltip: t('apply to management'),
        iconButtonProps: {
          id: 'apply-management',
          variant: 'outlined',
          size: 'small',
          color: colors.secondary,
          style: {
            width: 70,
            height: 30,
            borderRadius: 5
          }
        },
        icon: () => !row?.cropManagement ? null : (
          <div style={ { display: 'flex', flexDirection: 'row', fontSize: 12, fontWeight: 'bold' } }>
            <Icon icon={ warning } size={ 14 } color={ colors.secondary } style={ { marginRight: 4 } }/>
            Aceitar
          </div>
        )
      })
    ],
    [applyToManagement, t]
  )

  const recommendationViewActions = useMemo(() => [
    (row) => ({
      position: 'row',
      onClick: () => createDialog({
        id: 'view-recommendation',
        Component: TechnicalVisitRecommendationModal,
        props: {
          producer: find(availableProducers, (item) => item?.id === producer),
          propertyId,
          growingSeasonId,
          readOnly: true,
          defaultValues: { id: row?.mockedId, ...row, items: row?.itens ? isString(row?.itens) ? JSON.parse(row?.itens) : row?.itens : row?.items, growingSeasonIds: isEmpty(row?.growingSeasonIds) ? [row?.growingSeasonId] : row?.growingSeasonIds }
        }
      }) ,
      tooltip: t('view'),
      iconButtonProps: {
        id: 'view-recommendation',
        variant: 'outlined',
        size: 'small',
        color: colors.primary,
        style: {
          width: 30,
          height: 30,
          borderRadius: 5
        }
      },
      icon: () => (
        <>
          <Icon icon={ eyeOpened } size={ 14 } color={ colors.primary }/>
        </>
      )
    })
  ],[availableProducers, createDialog, growingSeasonId, producer, propertyId, t])

  useEffect(() => {
    const getProperties = async () => {
      const { data: { data } } = await getPropertiesByOwner({}, { ownerId: producer })
      setProperties(data)
    }
    if(!isEmpty(producer)) {
      getProperties()
    }
  },[producer])

  const propertiesOptions = useMemo(
    () => !producer
      ? []
      : map(
        properties,
        (item) => ({
          ...item,
          value: item.id,
          label: item?.name
        })) || [],
    [producer, properties]
  )

  const handleManageTechnicalVehicles = useCallback(() => {
    createDialog({
      id: 'technical-vehicles-modal',
      Component: TechnicalVehiclesModal,
      props: {
        onClose: loadTechnicalVehicles
      }
    })
  },[createDialog, loadTechnicalVehicles])

  const onKmChange = useCallback(() => {
    const data = formRef.current.getData()
    if(!isEmpty(toString(data?.kmControl?.endMileage)) && !isEmpty(toString(data?.kmControl?.endMileage))) {
      formRef.current.getFieldRef('kmControl.traveledMileage').setValue(data?.kmControl?.endMileage - data?.kmControl?.startMileage < 0 ? '0' : data?.kmControl?.endMileage - data?.kmControl?.startMileage)
    } else {
      formRef.current.getFieldRef('kmControl.traveledMileage').setValue('')
    }
  }, [formRef])

  const onDirtKmChange = useCallback(() => {
    const data = formRef.current.getData()
    if(!isEmpty(toString(data?.kmControl?.traveledMileage)) && !isEmpty(toString(data?.kmControl?.dirtRoadMileage))) {
      formRef.current.getFieldRef('kmControl.mileageOnAsphalt').setValue(data?.kmControl?.traveledMileage - data?.kmControl?.dirtRoadMileage < 0 ? '0' : toString(data?.kmControl?.traveledMileage - data?.kmControl?.dirtRoadMileage))
    } else {
      formRef.current.getFieldRef('kmControl.mileageOnAsphalt').setValue('')
    }
  }, [formRef])

  return (
    <Container>
      <Form
        style={ { ...style } }
        ref={ formRef }
        schemaConstructor={ scheduled ? technicalVisitScheduleSchema : propertyTechnicalVisitSchema }
        onSubmit={ handleSubmitRegisterTechnicalVisit }
        schemaProps={ { maxKm: technicalVisit?.[0]?.kmControl?.startMileage || selectedVehicle?.maxKm } }
      >
        <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, marginBottom: 20 } }>
          {fromTechnicalArea ? (
            <div style={ { display: 'flex', alignSelf: 'flex-start', flex: 1, flexDirection: 'row', width: '100%' } }>
              <InputSelect
                detached
                label={ t('producer', { howMany: 1, gender: 'male' }) }
                value={ producer }
                options={ owners }
                onChange={ value => {
                  setPropertyId(null)
                  setProducer(value)
                } }
                disabled={ isEditing }
                style={ { marginRight: 5 } }
                fullWidth
              />
              <InputSelect
                detached
                label={ t('property', { howMany: 1 }) }
                options={ propertiesOptions }
                onChange={ value => setPropertyId(value) }
                value={ propertyId }
                disabled={ isEditing }
                fullWidth
              />
            </div>
          ) : null }

          {technicalVisit[0]?.predictedDate && (
            <ContainerSchedulement>
              <legend>{t('schedulement data')}</legend>
              <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1 } }>
                <div style={ { display: 'flex', alignSelf: 'flex-start', flex: 1, flexDirection: 'row', width: '100%' } }>
                  <InputDate
                    name="predictedDate"
                    label="Data"
                    style={ { minWidth: 160 } }
                    value={ moment(`${ technicalVisit[0].predictedDate }`, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD') }
                    disabled
                    detached
                  />
                  <InputHour
                    name="hour"
                    label={ t('hour') }
                    style={ { maxWidth: 160, marginLeft: 5 } }
                    value={ moment(`${ technicalVisit[0].predictedDate }`, 'YYYY-MM-DD HH:mm').format('HH:mm') }
                    disabled
                    detached
                  />
                  <InputSelect
                    fullWidth
                    label={ t('visit mode') }
                    name="visitMode"
                    options={ visitModeOptions }
                    style={ { marginLeft: 5, maxWidth: 200 } }
                    defaultValue={ technicalVisit[0]?.visitMode }
                    disabled
                  />
                  <InputSelect
                    fullWidth
                    label={ t('goal') }
                    name="visitGoalId"
                    options={ goalsOptions }
                    style={ { marginLeft: 5, minWidth: 200 } }
                    defaultValue={ technicalVisit[0]?.visitGoalId }
                    disabled
                  />
                </div>

                <InputText
                  name="scheduleNote"
                  label={ t('observation', { howMany: 2 }) }
                  fullWidth
                  multiline
                  value={ technicalVisit[0]?.scheduleNote }
                  rows={ 4 }
                  disabled
                  detached
                />
              </div>
            </ContainerSchedulement>
          )}
          <div style={ { display: 'flex', alignSelf: 'flex-start', flex: 1, flexDirection: 'row', width: '100%' } }>
            <InputDate
              name={ scheduled ? 'predictedDate' : 'dateVisit' }
              label="Data da visita"
              defaultValue={ dateAndTime?.date }
              disabled={ isEditing }
              style={ { minWidth: 160 } }
              pickerProps={ { maxDate: moment().format() } }
            />
            <InputHour
              name="hour"
              label={ t('hour') }
              style={ { maxWidth: 160, marginLeft: 5 } }
              defaultValue={ dateAndTime ? dateAndTime?.hour : '' }
              disabled={ isEditing }
            />
            {technicalVisit[0]?.predictedDate ? null : (
              <InputSelect
                fullWidth
                label={ t('visit mode') }
                name="visitMode"
                options={ visitModeOptions }
                defaultValue={ technicalVisit[0]?.visitMode }
                disabled={ isEditing || readOnly }
                style={ { marginLeft: 5, maxWidth: 200 } }
              />
            )}
            {technicalVisit[0]?.predictedDate ? null : (
              <InputSelect
                fullWidth
                label={ t('goal') }
                name="visitGoalId"
                options={ goalsOptions }
                defaultValue={ technicalVisit[0]?.visitGoalId }
                disabled={ isEditing || readOnly }
                style={ { marginLeft: 5, minWidth: 200 } }
              />
            )}
          </div>

          <div style={ { display: 'flex', alignSelf: 'flex-start', flex: 1, flexDirection: 'row', width: '100%' } }>
            <InputText
              name="visitCode"
              label={ t('visit code') }
              style={ {
                maxWidth: 160,
                marginRight: 5
              } }
              fullWidth
              disabled={ isEditing }
              defaultValue={ technicalVisit[0]?.visitCode }
            />

            <InputText
              name={ scheduled ? 'scheduleNote' : 'observations' }
              label={ t('observation', { howMany: 2 }) }
              fullWidth
              multiline
              defaultValue={ technicalVisit[0]?.observations }
              disabled={ isEditing }
              rows={ 4  }
            />
          </div>

          <CheckboxContainer>
            <CheckboxButton
              value={ notify }
              checked={ notify }
              style={ { width: '100%', marginBottom: '20px' } }
              onChange={ () => setNotify(!notify) }
              label={ scheduled ? t('notify the producer about the schedulement') : t('notify the producer about the realization of this visit') }
              disabled={ isEditing }
              hideColor
            />
          </CheckboxContainer>

          {!scheduled && (
            <>
              {!isEmptyFiles &&
            <InputFile
              idInput="contained-btn-file"
              onChange={ handleAdd }
              maxNumberFile={ 20 - selectedFiles.length }
              maxNumberFileDisplay={ 20 }
              inputProps={ {
                accept: 'image/png,image/jpeg,image/tiff,application/pdf,application/msword,application/xls,application/xlsx,application/doc,application/docx,application/vnd.ms-excel',
                multiple: true,
                disabled: readOnly || isEditing
              } }
              buttonProps={ {
                id: 'attach-docs',
                color: 'secondary',
                disabled: isEditing || readOnly
              } }
              disabled={ isEditing || readOnly }
            >
              <I18n>add files</I18n>
            </InputFile>
              }
              <Gallery isCenter={ isEmptyFiles }>
                {isEmptyFiles ?
                  <EmptyState
                    text={ t('no files added') }
                    icon={ noImage }
                    isInputFile
                    maxNumberFile={ 20 - selectedFiles.length }
                    maxNumberFileDisplay={ 20 }
                    file={ {
                      idInput: 'contained-button-file',
                      inputProps: {
                        accept: 'image/png,image/jpeg,image/tiff,application/pdf,application/msword,application/xls,application/xlsx,application/doc,application/docx,application/vnd.ms-excel',
                        multiple: true,
                        disabled: isEditing || readOnly
                      }
                    } }
                    action={ {
                      text: t('add files') ,
                      onClick: handleAdd,
                      disabled: isEditing || readOnly
                    } }
                    buttonProps={ {
                      id: 'attach-documents',
                      color: 'secondary',
                      disabled: isEditing || readOnly
                    } }
                    disabled={ isEditing || readOnly }
                  />
                  :
                  technicalVisitFormFiles
                }
              </Gallery>
              {!isTechnicalModule ? null : (
                <>
                  <Title style={ { marginBottom: 20, alignSelf: 'flex-start' } }>
                    {t('km control')}
                  </Title>
                  <div style={ { display: 'flex', flex: 1, flexDirection: 'row', width: '100%', justifyContent: 'space-between' } }>
                    <div style={ { display: 'flex', flexDirection: 'row' } }>
                      <InputSelect
                        fullWidth
                        label={ t('plate') }
                        name="kmControl.vehicleId"
                        options={ technicalVehiclesOptions }
                        defaultValue={ technicalVisit[0]?.kmControl?.vehicleId }
                        onChange={ (_, item) => setSelectedVehicle(item?.item) }
                        style={ { width: 140 } }
                        disabled={ readOnly }
                      />
                      <Popover
                        popoverId="technical-vehicles-menu"
                        Component={ VerticalDotsIconButton }
                      >
                        <MenuPopUp
                          options={ [
                            {
                              icon: null,
                              text: t('manage vehicles'),
                              onClick: handleManageTechnicalVehicles
                            }
                          ] }
                        />
                      </Popover>
                    </div>
                    {!isEmpty(selectedVehicle) ? (
                      <>
                        <InputNumber
                          name="kmControl.startMileage"
                          label={ t('start km') }
                          fullWidth
                          disabled={ readOnly }
                          defaultValue={ technicalVisit[0]?.kmControl?.startMileage || selectedVehicle?.maxKm }
                          style={ { marginLeft: 5 } }
                          onChange={ onKmChange }
                        />
                        <InputNumber
                          name="kmControl.endMileage"
                          label={ t('end km') }
                          fullWidth
                          disabled={ readOnly }
                          defaultValue={ technicalVisit[0]?.kmControl?.endMileage }
                          style={ { marginLeft: 5 } }
                          onChange={ onKmChange }
                        />
                        <InputNumber
                          name="kmControl.traveledMileage"
                          label={ t('km traveled') }
                          fullWidth
                          disabled
                          visibleButDisabled
                          defaultValue={ technicalVisit?.[0]?.kmControl?.endMileage - technicalVisit?.[0]?.kmControl?.startMileage }
                          style={ { marginLeft: 10 } }
                          onChange={ onDirtKmChange }
                        />
                        <InputNumber
                          name="kmControl.dirtRoadMileage"
                          label={ t('dirt road mileage') }
                          fullWidth
                          disabled={ readOnly }
                          defaultValue={ technicalVisit?.[0]?.kmControl?.dirtRoadMileage }
                          style={ { marginLeft: 5 } }
                          onChange={ onDirtKmChange }
                        />
                        <InputNumber
                          name="kmControl.mileageOnAsphalt"
                          label={ t('mileage on asphalt') }
                          fullWidth
                          disabled
                          visibleButDisabled
                          defaultValue={ technicalVisit?.[0]?.kmControl?.mileageOnAsphalt }
                          style={ { marginLeft: 5 } }
                        />
                      </>
                    ) : null }
                  </div>
                </>
              )}
              {(!readOnly || (readOnly && !isEmpty(fieldsGrowingSeasonIds))) && (
                <div style={ { width: '100%', marginTop: '20px' } }>
                  <Title style={ { marginBottom: 20 } }>
                    {t('visited fields')}
                  </Title>
                  <InputSelect
                    multiple
                    detached
                    fullWidth
                    label="Selecione"
                    options={ isEditing && fieldsOptions ? fieldsOptions : growingSeasonsOptions }
                    value={ fieldsGrowingSeasonIds }
                    onChange={ setFieldsGrowingSeasonIds }
                    disabled={ isEditing }
                  />
                  {map(fieldsGrowingSeasonIds, (item, index) => {
                    const currentGrowingSeason = find(growingSeasons, gs => gs.id === item)
                    const currentField = find(flatMap(growingSeasons, gs => gs.field), gsField => gsField?.id === item)

                    return (
                      <div key={ item } style={ { display: 'flex', flex: 1, flexDirection: 'column', marginLeft: '20px' } }>
                        <TextDate style={ { width: '40vw', marginRight: '15px' } }>
                          {currentGrowingSeason ? `${ currentGrowingSeason?.field?.fieldName } - ${ currentGrowingSeason?.crop?.description }/${ currentGrowingSeason?.sowingYear }` : currentField?.fieldName }
                        </TextDate>
                        <div key={ item } style={ { display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' } }>
                          <InputSelect
                            name={ `fields[${ index }].estimateUnity` }
                            fullWidth
                            style={ { marginRight: '10px'  } }
                            label={ t('estimate in') }
                            options={ unitProductivity }
                            defaultValue={ technicalVisit[index]?.estimateUnity }
                            disabled={ isEditing }
                            onChange={ e => {
                              const currentUnits = units
                              currentUnits[index] = e.target.value
                              setUnits([...currentUnits])
                            } }
                          />
                          <InputNumber
                            name={ `fields[${ index }].productivityEstimate` }
                            style={ { marginLeft: '10px' } }
                            fullWidth
                            label={ t('estimate productivity') }
                            disabled={ isEditing || isEmpty(units[index]) }
                            defaultValue={ technicalVisit[index]?.productivityEstimate ? toString(technicalVisit[index]?.productivityEstimate) : '' }
                          />
                          <InputSelect
                            name={ `fields[${ index }].aditionalInformations.climateEvent` }
                            style={ { marginLeft: '10px'  } }
                            fullWidth
                            label={ t('climate event') }
                            options={ map(['Vento', 'Geada', 'Granizo', 'Excesso de chuva', 'Estiagem'], option => ({ value: option, label: option })) }
                            defaultValue={ technicalVisit[index]?.aditionalInformations?.climateEvent }
                            disabled={ isEditing }
                          />
                        </div>
                        <div key={ item } style={ { display: 'flex', flex: 1, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' } }>
                          <InputSelect
                            name={ `fields[${ index }].aditionalInformations.phenologicalStage` }
                            fullWidth
                            label={ t('phenological stage') }
                            options={ currentGrowingSeason?.crop?.extraAttributes?.phenologicalStage ? map(split(currentGrowingSeason?.crop?.extraAttributes?.phenologicalStage, ';'), stage => ({ label: stage, value: stage })) : [] }
                            defaultValue={ technicalVisit[index]?.aditionalInformations?.phenologicalStage }
                            disabled={ isEditing }
                            style={ { marginRight: '10px' } }

                          />
                          <InputSelect
                            name={ `fields[${ index }].aditionalInformations.stage` }
                            style={ { marginRight: '10px'  } }
                            fullWidth
                            label={ t('stage') }
                            options={ map(['Pré Semeadura', 'Semeadura', 'Desenvolvimento', 'Maturação', 'Colheita'], option => ({ value: option, label: option })) }
                            defaultValue={ technicalVisit[index]?.aditionalInformations?.stage }
                            disabled={ isEditing }
                          />
                          <InputNumber
                            name={ `fields[${ index }].aditionalInformations.numberOfPlants` }
                            fullWidth
                            label={ t('number of plants per meter') }
                            disabled={ isEditing }
                            defaultValue={ technicalVisit[index]?.aditionalInformations?.numberOfPlants ? toString(technicalVisit[index]?.aditionalInformations?.numberOfPlants) : '' }
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
              {!scheduled && !isEditing && propertyId && (
                <div style={ { display: 'flex', flexDirection: 'row', flex: 1, width: '100%', justifyContent: 'space-between', marginBottom: 10, marginTop: 20 } }>
                  <Title>
                    {t('recommendations')}
                  </Title>
                  <IconButton
                    onClick={ () => createDialog({
                      id: 'new-recommendation',
                      Component: TechnicalVisitRecommendationModal,
                      props: {
                        growingSeasonId,
                        propertyId,
                        producer: find(availableProducers, (item) => item?.id === producer),
                        onSubmit: (response) => setRecommendations(old => [...old, { mockedId: uuidv4(), ...response }])
                      }
                    }) }
                    style={ { backgroundColor: colors.secondary } }
                  >
                    <Icon size={ 16 } icon={ plus } color={ colors.black } />
                  </IconButton>
                </div>
              )}
              {!scheduled && (
                <DataTable
                  columns={ recommendationColumns }
                  data={ recommendations }
                  onDeleteClick={ isEditing ? null : (_, row) => setRecommendations(old => filter(old, item => row?.mockedId !== item?.mockedId)) }
                  onEditClick={ isEditing ? null : (_, row) => createDialog({
                    id: 'new-recommendation',
                    Component: TechnicalVisitRecommendationModal,
                    props: {
                      growingSeasonId,
                      propertyId,
                      producer: find(availableProducers, (item) => item?.id === producer),
                      onSubmit: (response) => setRecommendations(old => map(old, item => item.mockedId === response?.mockedId ? response : item)),
                      defaultValues: { id: row?.mockedId, ...row, items: row?.itens ? isString(row?.itens) ? JSON.parse(row?.itens) : row?.itens : row?.items, growingSeasonIds: isEmpty(row?.growingSeasonIds) ? [row?.growingSeasonId] : row?.growingSeasonIds }
                    }
                  }) }
                  id="table-recommendations"
                  hasPagination={ false }
                  hidePagination
                  actions={ !isTechnicalModule ? [...recommendationActions, ...recommendationViewActions] : [...recommendationViewActions] }
                />
              )}
            </>
          )}
          {!scheduled && (
            <Gallery isCenter={ isEmpty(signatures) }>
              {isEmpty(signatures) ?
                <EmptyState
                  text={ t('no signature added') }
                  icon={ iconSignature }
                  iconProps={ { color: 'black', size: 150 } }
                  labelStyle={ { marginTop: 0 } }
                  action={ {
                    text: t('add signature') ,
                    onClick: openSignatureModal,
                    disabled: isEditing
                  } }
                  buttonProps={ {
                    id: 'add-signature',
                    color: 'secondary',
                    disabled: isEditing
                  } }
                />
                :
                renderSignatures
              }
            </Gallery>
          )}

          {!isEmpty(signatures) ? (
            <Button
              id="button"
              color="secondary"
              onClick={ openSignatureModal }
              disabled={ isEditing }
            >
              {t('add signature')}
            </Button>
          ) : null}
        </div>

        { !readOnly && (
          <ButtonsContainer>
            <Button
              id="cancel-technical-visit-form"
              onClick={ handleClose }
              style={ { flex: 1, marginRight: 10 } }
              variant="outlined"
            >
              <I18n>cancel</I18n>
            </Button>

            {!isEmpty(technicalVisit) && isEditing && (
              <Button
                id="edit-technical-visit-form"
                onClick={ () => setIsEditing(!isEditing) }
                style={ { flex: 1, marginRight: 10 } }
                color="secondary"
              >
                <I18n>edit</I18n>
              </Button>
            )}

            {!isEditing && (
              <Button
                id="submit-technical-visit-form"
                onClick={ () => formRef.current.submit() }
                style={ { flex: 1 } }
              >
                <I18n>save</I18n>
              </Button>
            )}
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
})

PropertyTechnicalVisitForm.propTypes = {
  style: PropTypes.object,
  handleClose: PropTypes.func,
  technicalVisit: PropTypes.object,
  growingSeasonId: PropTypes.string,
  disabled: PropTypes.bool,
  fromTechnicalArea: PropTypes.bool,
  readOnly: PropTypes.bool,
  scheduled: PropTypes.bool,
  onSuccess: PropTypes.func
}

PropertyTechnicalVisitForm.defaultProps = {
  style: {},
  handleClose: () => {},
  technicalVisit: [],
  disabled: false,
  fromTechnicalArea: false,
  growingSeasonId: null,
  readOnly: false,
  scheduled: false,
  onSuccess: () => {}
}

export default PropertyTechnicalVisitForm
