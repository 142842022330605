import React, { useCallback , useState, useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import withObservables from '@nozbe/with-observables'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import toNumber from 'lodash/toNumber'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import { useDialog } from '@smartcoop/dialog'
import changeLotsSchema from '@smartcoop/forms/schemas/dairyFarm/changeLots.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { magnifier } from '@smartcoop/icons'
import { getLots } from '@smartcoop/services/apis/smartcoopApi/resources/lot'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import Icon from '@smartcoop/web-components/Icon'
import InputSelect from '@smartcoop/web-components/InputSelect'
import CheckboxesModal from '@smartcoop/web-containers/modals/CheckboxesModal'

import {
  Container,
  Row,
  ButtonsContainer
} from './styles'

const ChangeLotsForm = (props) => {
  const {
    loading,
    onSubmit,
    setIsLoading,
    onCancel,
    animalsList,
    formRef,
    propertyId
  } = props

  const t = useT()
  const { createDialog } = useDialog()

  const userWrite = useSelector(selectUserCanWrite)

  const [lotOptions, setLotOptions] = useState([])
  const [animals, setAnimals] = useState([])

  const activeAnimals = useMemo(() => filter(animalsList, item => item?.active), [animalsList])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit(data)
    },
    [onSubmit]
  )

  const loadLots = useCallback(async () => {
    const { data: { data } } = await getLots({}, { propertyId })

    setLotOptions(map(data, item => ({
      label: item.name,
      value: item.id
    })))
  },[propertyId])


  const onChangeLots = useCallback((lots) => {
    setIsLoading(true)
    const updatedAnimalsFromLots = []
    const func = map(lots, (lot) => {
      const newAnimalsId = map(filter(activeAnimals, (item) => item?.lotId === lot), (item) => item?.animalId)
      updatedAnimalsFromLots.push(...newAnimalsId)
    })
    Promise.all(func).then(() => {
      const currentAnimals = [...animals]
      const oldAndNotNew = filter(currentAnimals, (item) => !includes(updatedAnimalsFromLots, item))
      const updatedAnimals = [...oldAndNotNew, ...updatedAnimalsFromLots]
      formRef.current.getFieldRef('animalsIds').setValue(updatedAnimals)
      setAnimals(updatedAnimals)
    })
    setIsLoading(false)
  },[animals, activeAnimals, formRef, setIsLoading])


  useEffect(() => {
    loadLots()
  },[loadLots])


  return (
    <Form
      style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
      ref={ formRef }
      schemaConstructor={ changeLotsSchema }
      onSubmit={ handleSubmit }
    >
      <Container>
        <Row>
          <InputSelect
            onChange={ ({ target: { value } }) => setAnimals(value) }
            label={ t('earrings') }
            name="animalsIds"
            checkBoxSelectAll
            options={ orderBy(map(activeAnimals, item => ({ label: item?.earringCode, value: item?.animalId })), item => isNaN(toNumber(item?.label)) ? item?.label : toNumber(item?.label)) }
            multiple
            fullWidth
            style={ { marginRight: 10 } }
            order={ false }
          />
          <Button
            id="search-lot-button"
            onClick={ () => createDialog(
              {
                id: 'select-lots-modal',
                Component: CheckboxesModal,
                props: {
                  items: lotOptions,
                  title: t('lot', { howMany: 2 }),
                  onConfirm: (response) => {
                    onChangeLots(response)
                  }
                }
              }
            ) }
            variant="outlined"
            disabled={ isEmpty(activeAnimals) }
            style={ { marginBottom: 15 } }
          >
            <Icon
              icon={ magnifier }
              style={ { color: '#000' } }
            />
          </Button>
        </Row>
        <InputSelect
          label={ t('lot', { howMany: 1 }) }
          name="lotId"
          options={ lotOptions }
        />
      </Container>

      <ButtonsContainer>
        <Button
          id="web-cancel-form-button"
          onClick={ onCancel }
          color={ colors.white }
          disabled={ loading }
          style={ { marginLeft: '10px', marginRight: '10px' } }
        >
          <I18n>cancel</I18n>
        </Button>
        <Button
          id="web-save-form-button"
          onClick={ () => formRef.current.submit() }
          disabled={ loading || !userWrite }
          style={ { marginLeft: '10px', marginRight: '10px' } }
        >
          <I18n>save</I18n>
        </Button>
      </ButtonsContainer>
    </Form>
  )
}

ChangeLotsForm.propTypes = {
  loading: PropTypes.bool,
  propertyId: PropTypes.string,
  animalsList: PropTypes.array,
  onSubmit: PropTypes.func,
  setIsLoading: PropTypes.func,
  formRef: PropTypes.object.isRequired,
  onCancel: PropTypes.func
}

ChangeLotsForm.defaultProps = {
  loading: false,
  propertyId: null,
  animalsList: [],
  onSubmit: () => {},
  onCancel: () => {},
  setIsLoading: () => {}
}

const enhance = withObservables(['propertyId'], ({ propertyId }) =>  ({
  animalsList: animalService(database).observeAnimals(propertyId)
}))

const EnhancedChangeLotsForm = enhance(ChangeLotsForm)

export default EnhancedChangeLotsForm