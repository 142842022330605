/* eslint-disable no-nested-ternary */
import React, { useCallback, forwardRef, useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'


import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'


import technicalVisitReportSchema from '@smartcoop/forms/schemas/property/reports/technicalVisitReport.schema'
import { useT } from '@smartcoop/i18n'
import { getFieldsByPropertyId } from '@smartcoop/services/apis/smartcoopApi/resources/field'
import { getPropertiesByOwner } from '@smartcoop/services/apis/smartcoopApi/resources/property'
import { getTechnicalGoals , getTechnicalPortfolio } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { getUsers } from '@smartcoop/services/apis/smartcoopApi/resources/user'
import { selectModuleIsTechnical, selectModuleIsAdmin } from '@smartcoop/stores/module/selectorModule'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwnerId } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import InputDateRange from '@smartcoop/web-components/InputDateRange'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { Container } from './styles'

const TechnicalVisitReportForm = forwardRef((props, formRef) => {
  const { onSubmit, showRecommendations, setShowRecommendations } = props
  const t = useT()

  const currentOwnerId = useSelector(selectCurrentOwnerId)
  const currentProperty = useSelector(selectCurrentProperty)
  const isTechnical = useSelector(selectModuleIsTechnical)
  const isAdmin = useSelector(selectModuleIsAdmin)
  const user = useSelector(selectUser)

  const [producerId, setProducerId] = useState(isTechnical ? currentOwnerId || null : user?.id)
  const [technicianId, setTechnicianId] = useState(null)
  const [propertyId, setPropertyId] = useState(currentProperty?.id || null)
  const [technicalPortfolioData, setTechnicalPortfolioData] = useState(null)
  const [fieldsOptions, setFieldsOptions] = useState(null)
  const [techniciansData, setTechniciansData] = useState([])
  const [goalsOptions, setGoalsOptions] = useState([])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data
      })
    },
    [onSubmit]
  )

  const loadTechnicalGoals = useCallback(
    async () => {
      const { data: { data } } = await getTechnicalGoals()
      setGoalsOptions(map(data, item => ({ value: item?.id, label: item?.description })))
    },
    []
  )

  useEffect(() => {
    loadTechnicalGoals()
  },[loadFieldOptions, loadTechnicalGoals])

  const getTechncialPortfolioData = useCallback(async () => {
    if(isAdmin) {
      const { data } = await getUsers({ limit: 99999, profileId: 6 })
      setTechniciansData(data)
    } else {
      const { data: { data } } = await getTechnicalPortfolio({ limit: 99999 })
      setTechnicalPortfolioData(data)
    }
  }, [isAdmin])

  const getAdminPortfolioData = useCallback(async () => {
    const { data: { data } } = await getTechnicalPortfolio({ limit: 99999, technicianId  })
    setTechnicalPortfolioData(data)
  }, [technicianId])

  useEffect(() => {
    getAdminPortfolioData()
  }, [getAdminPortfolioData, technicianId])

  useEffect(() => {
    if(isTechnical || isAdmin) {
      getTechncialPortfolioData()
    }
  },[getTechncialPortfolioData, isAdmin, isTechnical])


  const loadFieldOptions = useCallback(
    async () => {
      const result = await getFieldsByPropertyId({}, { propertyId })
      setFieldsOptions(map(result, item => ({ value: item?.id, label: item?.fieldName })))
    },
    [propertyId]
  )

  useEffect(() => {
    if(propertyId) {
      loadFieldOptions()
    } else {
      setFieldsOptions([])
    }
  }, [loadFieldOptions, propertyId])

  const producerOptions = useMemo(() => !isTechnical && !isAdmin ? [{ label: user?.name, value: user?.id }] : map(filter(technicalPortfolioData, item => !isEmpty(item.proprietaryTechnician)), item => ({ value: item.id, label: item.name })), [isAdmin, isTechnical, technicalPortfolioData, user.id, user.name])

  const techniciansOptions = useMemo(() => map(techniciansData, item => ({ value: item.id, label: item.name })), [techniciansData])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flex: 1, flexDirection: 'column' } }
        ref={ formRef }
        schemaConstructor={ technicalVisitReportSchema }
        schemaProps={ { isAdmin } }
        onSubmit={ handleSubmit }
      >
        <InputDateRange
          label="Periodo"
          name="period"
          fullWidth
        />
        {isAdmin ? (
          <InputSelect
            label={ t('technician', { howMany: 1, gender: 'male' }) }
            name="technicianId"
            options={ techniciansOptions }
            onChange={ ({ target }) => setTechnicianId(target.value) }
            defaultValue={ null }
          />
        ) : null}
        <InputSelect
          label={ t('producer', { howMany: 1, gender: 'male' }) }
          name="producerId"
          options={ producerOptions }
          onChange={ ({ target }) => setProducerId(target.value) }
          defaultValue={ isAdmin ? null : isTechnical ? currentOwnerId || null : user?.id }
          disabled={ !isAdmin && (currentOwnerId || !isTechnical) }
        />
        <InputSelect
          fullWidth
          label={ t('goal') }
          name="visitGoalId"
          options={ goalsOptions }
        />
        <InputSelect
          label={ t('property', { howMany: 1 }) }
          name="propertyIds"
          options={ !producerId
            ? []
            : (
              producerId && isTechnical
                ? getPropertiesByOwner
                : (isEmpty(currentProperty)
                  ? getPropertiesByOwner
                  : [{ label: currentProperty?.name, value: currentProperty?.id }]
                )
            )
          }
          urlParams={ { ownerId: producerId } }
          onChange={ ({ target }) => setPropertyId(target.value) }
          asyncOptionValueField='id'
          asyncOptionLabelField='name'
          defaultValue={ currentProperty?.id || null }
          disabled={ !isAdmin && (!producerId || currentProperty?.id || !isTechnical) }
        />
        <InputSelect
          label={ t('field', { howMany: 1 }) }
          name="fieldId"
          options={ fieldsOptions }
          disabled={ !propertyId }
        />
        <CheckboxButton
          value={ showRecommendations }
          checked={ showRecommendations }
          onChange={ () => setShowRecommendations(!showRecommendations) }
          label={ t('show recommendations') }
          hideColor
        />
      </Form>
    </Container>
  )
})

TechnicalVisitReportForm.propTypes = {
  onSubmit: PropTypes.func,
  showRecommendations: PropTypes.bool,
  setShowRecommendations: PropTypes.func
}

TechnicalVisitReportForm.defaultProps = {
  onSubmit: () => {},
  setShowRecommendations: () => {},
  showRecommendations: true
}

export default TechnicalVisitReportForm
