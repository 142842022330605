import React, { useEffect, useRef, useCallback, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import I18n, { useT } from '@meta-react/i18n'
import moment from 'moment'

import { isEmpty, map, values } from 'lodash'

import { useDialog } from '@smartcoop/dialog'
import { getInseminations as getInseminationsService } from '@smartcoop/services/apis/smartcoopApi/resources/insemination'
import { useSnackbar } from '@smartcoop/snackbar'
import { AnimalActions } from '@smartcoop/stores/animal'
import { selectCurrentAnimal } from '@smartcoop/stores/animal/selectorAnimal'
import { InseminationActions } from '@smartcoop/stores/insemination'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import { momentBackDateFormat, momentFriendlyDateFormat } from '@smartcoop/utils/dates'
import { formatCurrencyIntl } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import FilterButton from '@smartcoop/web-components/FilterButton'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import FilterInseminationModal from '@smartcoop/web-containers/modals/dairyFarm/FilterInseminationModal'
import RegisterInseminationModal from '@smartcoop/web-containers/modals/dairyFarm/RegisterInseminationModal'

import { Top } from './styles'

const ListInsemination = () => {
  const { createDialog } = useDialog()
  const t = useT()
  const tableRef = useRef(null)
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])

  const [filters, setFilters] = useState({})
  const currentAnimal = useSelector(selectCurrentAnimal)
  const userWrite = useSelector(selectUserCanWrite)

  const registerDisabled = useMemo(
    () =>
      ((currentAnimal?.category !== 'vaca' &&
      currentAnimal?.category !== 'novilha') ||
      (currentAnimal?.animalStatus?.name !== 'Aptas' &&
      currentAnimal?.animalStatus?.name!== 'Vazia' &&
      currentAnimal?.animalStatus?.name!== 'Em protocolo' &&
      currentAnimal?.animalStatus?.name!== 'Inseminada a confirmar' &&
      currentAnimal?.animalStatus?.name!== 'Inseminada' &&
      currentAnimal?.animalStatus?.name!== 'PEV' &&
      currentAnimal?.animalStatus?.name!== 'Nenhum')
      || !userWrite
      ),[currentAnimal, userWrite]
  )

  const columns = useMemo(
    () => [
      {
        title: t('date', { howMany: 1 }),
        field: 'inseminationDate',
        render: (row) => moment(row?.inseminationDate, momentBackDateFormat).format(momentFriendlyDateFormat),
        sorting: true,
        defaultSort: 'desc'
      },
      {
        title: t('bull'),
        field: 'bull.code',
        render: (row) => `${ isEmpty(row.bull) ?  row?.ownBull?.earring?.earringCode : row.bull?.code } - ${ isEmpty(row.bull) ? row?.ownBull?.name : row.bull?.name }`,
        sorting: true
      },
      {
        title: t('bull\'s name'),
        field: 'bull.name',
        render: (row) => isEmpty(row.bull) ? row?.ownBull?.name : row.bull?.name,
        sorting: true
      },
      {
        title: t('sexing'),
        field: 'sexing',
        render: (row) => row?.sexing ? 'Sim' : 'Não',
        sorting: true
      },
      {
        title: t('type'),
        field: 'type',
        render: (row) => (map(row.inseminationType, ({ name }, index) => index === row.inseminationType.length - 1 ? name : `${ name  }, `)),
        sorting: true
      },
      {
        title: t('inseminator'),
        field: 'inseminador',
        render: (row) => row?.inseminador?.name || ' - ',
        sorting: true
      },
      {
        title: t('value'),
        field: 'cost.value',
        render: (row) => row?.cost?.value && formatCurrencyIntl(row?.cost?.value),
        sorting: true
      }
    ], [t]
  )

  const urlParams = useMemo(
    () => ({
      animalId: currentAnimal?.id
    }), [currentAnimal.id]
  )

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )

  const handleSetFilters = useCallback(
    (filterValues) => {
      setFilters({
        ...filterValues,
        q: filterValues?.name ?? null
      })
    }, []
  )

  const openFilterModal = useCallback(
    () => {
      createDialog({
        id: 'filter-fields',
        Component: FilterInseminationModal,
        props: {
          onSubmit: handleSetFilters,
          filters
        }
      })
    },
    [createDialog, filters, handleSetFilters]
  )

  useEffect(
    () => {
      dispatch(InseminationActions.loadInseminationTypes())
    },[dispatch]
  )

  useEffect(
    () => {
      dispatch(AnimalActions.loadCurrentAnimal())
    },[dispatch]
  )

  const openCreateModal = useCallback(
    (_, insemination = {}) => {
      if(currentAnimal?.animalStatus?.name=== 'Inseminada a confirmar') {
        createDialog({
          id: 'confirm-inseminate',
          Component: ConfirmModal,
          props: {
            onConfirm: () => {
              createDialog({
                id: 'create-fields',
                Component: RegisterInseminationModal,
                props: {
                  onSubmit: reloadDataTable,
                  insemination,
                  animalId: currentAnimal?.id
                }
              })
            },
            textWarning: t('Este animal já possui uma inseminação ativa sem diagnóstico informado. Deseja reinseminar este animal?')
          }
        })
      } else {
        createDialog({
          id: 'create-fields',
          Component: RegisterInseminationModal,
          props: {
            onSubmit: reloadDataTable,
            insemination,
            animalId: currentAnimal?.id
          }
        })
      }
    },
    [createDialog, currentAnimal, reloadDataTable, t]
  )

  const handleDelete = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete-access',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(
              InseminationActions.deleteInsemination(
                row?.id,
                () => {
                  snackbar.success(
                    t('your {this} was removed', { howmany: 1, gender: 'female', this: t('insemination') })
                  )
                  reloadDataTable()
                }
              )
            )
          },
          textWarning: t('are you sure you want to remove the {this}?', {
            howMany: 1,
            gender: 'female',
            this: t('insemination')
          })
        }
      })
    },
    [createDialog, dispatch, reloadDataTable, snackbar, t]
  )

  return (
    <>
      <Top>
        <div>
          <FilterButton
            onClick={ openFilterModal }
            isActive={ !values(filters).every(isEmpty) }
          />
        </div>
        <div>
          <Button
            id="register-insemination"
            onClick={ openCreateModal }
            color="secondary"
            disabled={ registerDisabled }
          >
            <I18n params={ { this: t('insemination') } }>{'register {this}'}</I18n>
          </Button>
        </div>
      </Top>
      <div style={ { marginTop: 20, width: 'calc(100% - 30px)' } }>
        <DataTable
          columns={ columns }
          data={ getInseminationsService }
          queryParams={ filters }
          tableRef={ tableRef }
          urlParams={ urlParams }
          onEditClick={ openCreateModal }
          onDeleteClick={ handleDelete }
          disabled={ !userWrite }
          id="list-insemination-table"
        />
      </div>
    </>
  )
}

export default ListInsemination
