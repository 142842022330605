/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import useWebSocket, { ReadyState } from 'react-use-websocket'

import AWS from 'aws-sdk'
import moment from 'moment/moment'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import map from 'lodash/map'

import { makeStyles } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'

import { useT } from '@smartcoop/i18n'
import { send } from '@smartcoop/icons'
import { createIaLog, updateIaLog } from '@smartcoop/services/apis/smartcoopApi/resources/logs'
import { selectIaToken } from '@smartcoop/stores/authentication/selectorAuthentication'
import { selectCurrentPropertyId } from '@smartcoop/stores/property/selectorProperty'
import { selectUserExtraAttributes, selectUserId } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import { momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import AudioRecorder from '@smartcoop/web-components/AudioRecorder'
import IAHeader from '@smartcoop/web-components/IAHeader'
import IAMessageItem from '@smartcoop/web-components/IAMessageItem'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'
import Switch from '@smartcoop/web-components/Switch'
import TextArea from '@smartcoop/web-components/TextArea'

import { ButtonGroup, FooterContainer, MessagesContainer, Loader } from './styles'

AWS.config.update({
  region: process.env.REACT_APP_AWS_POLLY_REGION,
  credentials: new AWS.Credentials({
    region: process.env.REACT_APP_AWS_POLLY_REGION,
    accessKeyId: process.env.REACT_APP_AWS_POLLY_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_POLLY_SECRET_ACCESS_KEY
  })
})

const polly = new AWS.Polly()

const useStyles = makeStyles({
  dialog: {
    position: 'absolute',
    right: 0,
    bottom: 0
  },
  header: {
    padding: 0,
    margin: 0
  }
})

const IAModal = ({ id, open }) => {
  const t = useT()
  const classes = useStyles()

  const messagesEndRef = useRef(null)

  const [message, setMessage] = useState()
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([])
  const [listening, setListening] = useState(false)
  const [audioSrc, setAudioSrc] = useState(null)
  const [speaking, setSpeaking] = useState(null)
  const [currentAudio, setCurrentAudio] = useState(null)
  const [audioBuffers, setAudioBuffers] = useState([])
  const [enabledTest, setEnabledTest] = useState(false)
  const [messageId, setMessageId] = useState(null)
  const [sessionId, setSessionId] = useState(null)

  const UserID = useSelector(selectUserId)
  const iaToken = useSelector(selectIaToken)
  const extraAttributes = useSelector(selectUserExtraAttributes)

  const isFirefox = useMemo(() => /firefox/i.test(navigator.userAgent), [])

  const synth = window.speechSynthesis

  function updateMessageWithAudioStatus(messageText, isPlaying) {
    setMessages((prevMessages) => prevMessages.map((messageItem) => {
      if (messageItem.message === messageText) {
        return { ...messageItem, isAudioPlaying: isPlaying }
      }
      return messageItem
    }))
  }


  const synthesizeSpeech = (text) => {
    const params = {
      OutputFormat: 'mp3',
      Text: text,
      VoiceId: 'Vitoria',
      TextType: 'text',
      Engine: 'neural',
      SampleRate: '22050'
    }

    if (audioBuffers[text]) {
      setSpeaking(true)
      const audioBlob = audioBuffers[text]
      const audioUrl = URL.createObjectURL(audioBlob)
      const audioElement = new Audio(audioUrl)
      setCurrentAudio(audioElement)
      audioElement.play()
      audioElement.onended = () => {
        setMessages((prevMessages) => prevMessages.map((messageItem) => ({
          ...messageItem,
          isAudioPlaying: false
        })))
        setCurrentAudio(null)
      }
      setSpeaking(false)
      return
    }


    polly.synthesizeSpeech(params, (err, data) => {
      setSpeaking(true)
      if (err) {
        console.log(err, err.stack)
      } else if (data && data.AudioStream instanceof Buffer) {
        const audioBlob = new Blob([data.AudioStream], { type: 'audio/mp3' })
        const audioUrl = URL.createObjectURL(audioBlob)
        const audioElement = new Audio(audioUrl)
        setAudioBuffers((old) => ({ ...old, [text]: audioBlob }))
        setCurrentAudio(audioElement)
        audioElement.play()
        audioElement.onended = () => {
          setMessages((prevMessages) => prevMessages.map((messageItem) => ({
            ...messageItem,
            isAudioPlaying: false
          })))
          setCurrentAudio(null)
        }
        setSpeaking(false)
      }
    })
  }

  const speakText = (text) => {
    const options = {
      method: 'POST',
      headers: {
        'xi-api-key': '07a2df7500c2f8419524dd11e422e204',
        'Content-Type': 'application/json'
      },
      body: `{"text":"${ text }","model_id":"eleven_multilingual_v2","voice_settings":{"stability":0.5,"similarity_boost": 0.75,"style":0,"use_speaker_boost":true}}`
    }

    fetch('https://api.elevenlabs.io/v1/text-to-speech/vibfi5nlk3hs8Mtvf9Oy', options)
      .then(response => response.blob())
      .then(blob => {
        const audioUrl = URL.createObjectURL(blob)
        setAudioSrc(audioUrl)
      })
      .catch(() => setSpeaking(false))
  }


  const pauseSpeaking = useCallback((messageText) => {
    if (currentAudio) {
      currentAudio.pause()
      setMessages((prevMessages) => prevMessages.map((messageItem) => {
        if (messageItem.message === messageText) {
          return { ...messageItem, isAudioPlaying: false, isAudioPaused: true }
        }
        return messageItem
      })
      )
    }
  }, [currentAudio])


  const resumeSpeaking = useCallback((messageText) => {
    if (currentAudio) {
      currentAudio.play()
      setMessages((prevMessages) => prevMessages.map((messageItem) => {
        if (messageItem.message === messageText) {
          return { ...messageItem, isAudioPlaying: true, isAudioPaused: false }
        }
        return messageItem
      })
      )
    }
  }, [currentAudio])

  const handleListen = () => {
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)()
    recognition.interimResults = true
    recognition.lang = 'pt-BR'

    recognition.onstart = () => setListening(true)
    recognition.onend = () => setListening(false)

    recognition.onresult = (event) => {
      const currentTranscript = Array.from(event.results)
        .map(result => result[0])
        .map(result => result.transcript)
        .join('')

      setMessage(currentTranscript)
    }

    if (listening) {
      recognition.stop()
    } else {
      recognition.start()
    }
  }


  const propertyId = useSelector(selectCurrentPropertyId)
  const url = process.env.REACT_APP_IA_SOCKET_URL
  const headers = {
    Authorization: iaToken,
    UserID
  }

  // Function to format headers into URL parameters
  const formatHeaders = () => Object.keys(headers)
    .map(key => `${ encodeURIComponent(key) }=${ encodeURIComponent(headers[key]) }`)
    .join('&')

  // Formatted URL with headers as query parameters
  const formattedUrl = `${ url }?${ formatHeaders() }`

  const [hasConnectedInitially, setHasConnectedInitially] = useState(false)
  const [shouldReconnect, setShouldReconnect] = useState(true)

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(formattedUrl, {
    shouldReconnect: () => shouldReconnect
  })


  const closeConnection = useCallback(() => {
    const socket = getWebSocket()
    if (socket) {
      setSessionId(null)
      socket.close()
    }
  }, [getWebSocket])

  useEffect(() => () => {
    synth.cancel()
    closeConnection()
  }, [closeConnection, synth])

  const handleCreateLog = useCallback(async (content, type = 'question') => {

    if (!sessionId) {
      return
    }

    try {
      if (type === 'answer' && messageId) {
        await updateIaLog({ answer: content, sessionId }, { messageId })
        setMessageId(null)
      } else if (type === 'question') {
        const { data: response } = await createIaLog({ question: content, sessionId })
        setMessageId(response?.id)
      }
    } catch (error) {
      console.error('Erro ao criar log:', error)
    }
  }, [sessionId, messageId])

  useEffect(() => {
    if (lastMessage !== null) {

      setMessages((prevMessages) => {
        const isDuplicate = prevMessages.some(
          (msg) => msg.message === lastMessage.data
        )
        if (isDuplicate) return prevMessages

        setLoading(false)
        handleCreateLog(lastMessage.data, 'answer')
        return [
          ...prevMessages,
          {
            message: lastMessage.data,
            receiver: true,
            sendAt: moment().format(momentBackDateTimeFormat),
            id: uuidv4()
          }
        ]
      })

    }
  }, [lastMessage, handleCreateLog])

  const onSubmitMessage = useCallback(() => {
    if (message) {
      if (readyState !== ReadyState.OPEN) {
        setSessionId(null)
        setShouldReconnect(true)
      }

      setLoading(true)
      const newMessage = {
        message,
        receiver: false,
        sendAt: moment().format(momentBackDateTimeFormat),
        id: uuidv4()
      }

      setMessages((prevMessages) => [...prevMessages, newMessage])

      const stringified = JSON.stringify({
        action: enabledTest ? 'sendmessage-testes' : 'sendmessage',
        message,
        PropertyID: propertyId
      })

      sendMessage(stringified)
      handleCreateLog(message, 'question')
      setMessage('')
    }
  }, [enabledTest, message, propertyId, sendMessage, handleCreateLog, readyState])


  useEffect(() => {
    if (readyState === ReadyState.OPEN && messages.length === 0 && !hasConnectedInitially) {
      setLoading(true)
      const stringified = JSON.stringify({
        action: 'sendmessage',
        message: 'Olá!',
        PropertyID: propertyId
      })
      sendMessage(stringified)
      setHasConnectedInitially(true)
    }

    if (readyState === ReadyState.OPEN && messages.length >= 1 && !sessionId) {
      setSessionId(uuidv4())
    }
  }, [messages, propertyId, readyState, sendMessage, sessionId, hasConnectedInitially])

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [messages])

  return (
    <Modal
      id={ id }
      open={ open }
      maxWidth="sm"
      fullWidth
      classes={ {
        paper: classes.dialog
      } }
      headerProps={ {
        titleClass: classes.header
      } }
      title={
        <IAHeader
          rightContainer={ extraAttributes?.enabledBotTest ? (
            <FormControlLabel
              style={ { alignSelf: 'flex-end', zIndex: 999 } }
              control={
                <Switch
                  onChange={ () => setEnabledTest(!enabledTest) }
                  checked={ enabledTest }
                  value={ enabledTest }
                />
              }
              label={ t('test mode') }
            />
          ) : null }
        />
      }
      disableEsc
      contentContainerStyle={ { padding: 0, margin: 0, minHeight: 500, display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' } }
      hideBackdrop
    >
      <MessagesContainer>
        {audioSrc && (
          <audio
            src={ audioSrc }
            autoPlay
            onEnded={ () => {
              setAudioSrc(null)
              setSpeaking(null)
            } }

          />
        )}
        {map(messages, (item) => (
          <IAMessageItem key={ item?.id } message={ item } speakText={ process.env.REACT_APP_AWS_POLLY_ENABLE ? synthesizeSpeech : speakText } setSpeaking={ setSpeaking } speaking={ speaking } pauseSpeaking={ pauseSpeaking } resumeSpeaking={ resumeSpeaking } updateMessageWithAudioStatus={ updateMessageWithAudioStatus }/>
        ))}
        {loading ? (
          <IAMessageItem key="loading" message={ { message: <Loader/>, receiver: true } }/>
        ) : null}
        <div ref={ messagesEndRef } />
      </MessagesContainer>
      <FooterContainer>
        <TextArea
          maxLength="5000"
          placeholder={ t('message') }
          onSubmit={ onSubmitMessage }
          onChange={ value => setMessage(value) }
          value={ message }
          disabled={ loading }
          messages={ messages }
        />

        <ButtonGroup>
          { (message || isFirefox) && !listening ? (
            <button type="button" onClick={ onSubmitMessage } disabled={ loading }>
              <Icon icon={ send } color={ colors.primary } size={ 20 } />
            </button>
          ) : (
            <AudioRecorder
              handleTranscript={ handleListen }
              active={ listening }
              disabled={ loading }
            />
          )}
        </ButtonGroup>
      </FooterContainer>
    </Modal>

  )
}

IAModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
}

IAModal.defaultProps = {
}

export default IAModal
