import moment from 'moment/moment'
import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import maxDate from '@smartcoop/forms/validators/maxDate.validator'
import minDate from '@smartcoop/forms/validators/minDate.validator'
import required from '@smartcoop/forms/validators/required.validator'

const propertiesAssets = ({ t, props }) => Yup.object().shape({
  assetDescription: flow(required({ t }))(Yup.string()),

  linkedAssetsId: Yup.string(),

  assetBrand: flow(
    required({ t })
  )(Yup.string()),

  assetYear: flow(
    required({ t })
  )(Yup.string()),

  assetPurchaseDate: props?.isWritedOff ? Yup.string() : flow(
    date({ t, isBirth: true }),
    maxDate({ t, maxReceiptDate: moment().format('YYYY-MM-DD') }),
    required({ t })
  )(Yup.string()),

  startDepreciationDate: props?.isWritedOff || props?.hasMovements ? Yup.string() : flow(
    date({ t }),
    minDate({ t, minReceiptDate: moment().startOf('month').format() }),
    required({ t })
  )(Yup.string()),

  assetTypeId: flow(
    required({ t })
  )(Yup.string()),

  assetValue: flow(
    required({ t })
  )(Yup.string()
    .test('is-greater', t('asset value must be greater than total depreciation'), function(value) {
      const totalDepreciation = parseFloat(props.totalDepreciated)
      return parseFloat(value) >= totalDepreciation
    })
  ),

  assetEstimatedLife: flow(
    required({ t })
  )(Yup.string()),

  assetMonthlyDepreciation: Yup.string().nullable(),

  totalDepreciatedValue: Yup.string().nullable(),

  balanceToDepreciate: Yup.string().nullable(),

  status: props?.isEditable ? flow(
    required({ t })
  )(Yup.string()) : Yup.string().nullable()
})

export default propertiesAssets
