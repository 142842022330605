/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'

const LinkPreview = (props) => {
  const { url, ...rest } = props
  const [previewData, setPreviewData] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(url)
        const data = await response.text()
        const parser = new DOMParser()
        const doc = parser.parseFromString(data, 'text/html')
        const title = doc.querySelector('title')?.textContent || ''
        const description = doc.querySelector('meta[name="description"]')?.getAttribute('content') || ''
        const image = doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || ''

        setPreviewData({ title, description, image })
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }

    fetchData()
  }, [url])

  if (loading) {
    return <p>Carregando...</p>
  }

  if (!previewData) {
    return <p />
  }

  const handleClick = () => {
    window.open(url, '_blank')
  }

  return (
    <div onClick={ handleClick } style={ { cursor: 'pointer', maxWidth: 300 } } { ...rest }>
      <h3>{previewData.title}</h3>
      <p>{previewData.description}</p>
      {previewData.image && <img src={ previewData.image } style={ { maxWidth: 300 } } alt={ previewData.title } />}
    </div>
  )
}

LinkPreview.propTypes = {
  url: PropTypes.string
}

LinkPreview.defaultProps = {
  url: ''
}

export default LinkPreview
