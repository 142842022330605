import React, {
  useCallback,
  useState
} from 'react'

import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import plateMask from '@smartcoop/forms/masks/plate.mask'
import technicalVehiclesSchema from '@smartcoop/forms/schemas/technical/technicalVehicles.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getTechnicalVehiclesBrands } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputNumber from '@smartcoop/web-components/InputNumber'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import {
  Container,
  ButtonsContainer,
  FormContainer
} from './styles'

const RegisterTechnicalVehiclesForm = (props) => {
  const { defaultValues, onSubmit, onCancel, loading, formRef } = props

  const [active, setActive] = useState(defaultValues?.active ?? false)

  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data,
        active: isEmpty(defaultValues) ? true : active
      })
    },
    [active, defaultValues, onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ technicalVehiclesSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <InputText
            label={ t('plate') }
            name="plate"
            fullWidth
            placeholder={ t('plate') }
            defaultValue={ defaultValues?.plate ? trimMask(defaultValues?.plate) : '' }
            setMask={ plateMask }
            maxLength={ 8 }
          />
          <InputSelect
            label={ t('brand') }
            name="brandId"
            options={ getTechnicalVehiclesBrands }
            asyncOptionLabelField="description"
            defaultValue={ defaultValues?.brandId }
          />
          <InputText
            label={ t('model') }
            name="model"
            fullWidth
            placeholder={ t('model') }
            defaultValue={ defaultValues?.model }
          />
          <InputNumber
            label={ t('year', { howMany: 1 }) }
            name="year"
            fullWidth
            placeholder={ t('year', { howMany: 1 }) }
            defaultValue={ defaultValues?.year }
            notSeparate
            maxLength={ 4 }
          />
          { isEmpty(defaultValues) ? null : (
            <RadioGroup
              label={ `${ t('status') }:` }
              options={ [
                {
                  label: t('active', { gender: 'male' }),
                  value: true
                },
                {
                  label: t('inactive', { gender: 'male' }),
                  value: false
                }
              ] }
              variant="row"
              value={ active }
              onChange={ (e) => setActive(e.value) }
              detached
              style={ { alignSelf: 'flex-start' } }
            />
          )}

        </FormContainer>
        <ButtonsContainer>
          <Button
            id="web-cancel-form-button"
            onClick={ onCancel }
            color={ colors.white }
            disabled={ loading }
            fullWidth
            style={ { marginRight: 5 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="web-save-form-button"
            onClick={ () => formRef.current.submit() }
            disabled={ loading }
            fullWidth
            style={ { marginLeft: 5 } }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
}

RegisterTechnicalVehiclesForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object.isRequired,
  formRef: PropTypes.object.isRequired
}

RegisterTechnicalVehiclesForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {}
}

export default RegisterTechnicalVehiclesForm