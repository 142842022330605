/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'


import debounce from 'lodash/debounce'
import filter from 'lodash/filter'
import find from 'lodash/find'
import flatten from 'lodash/flatten'
import groupBy from 'lodash/groupBy'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import reduce from 'lodash/reduce'
import size from 'lodash/size'
import uniq from 'lodash/uniq'
import uniqBy from 'lodash/uniqBy'
import values from 'lodash/values'

import Divider from '@material-ui/core/Divider'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  cutLayout,
  shovel,
  openLayout,
  erlenmeyer
} from '@smartcoop/icons'
import { FieldActions } from '@smartcoop/stores/field'
import { selectFieldAnalysis, selectFields, selectFieldsHistory } from '@smartcoop/stores/field/selectorField'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { selectUserCanWrite } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { isValidUUid } from '@smartcoop/utils/formatters'
import { GROWING_SEASON_COLOR, getPolygonCenter } from '@smartcoop/utils/maps'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import Maps from '@smartcoop/web-components/Maps'
import Control from '@smartcoop/web-components/Maps/components/Control'
import Polygon from '@smartcoop/web-components/Maps/components/Polygon'
import PinMarker from '@smartcoop/web-components/Maps/markers/PinMarker'
import FieldAnalysisDetailsFragment from '@smartcoop/web-containers/fragments/FieldAnalysisDetailsFragment'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'
import FilterAnalysis from '@smartcoop/web-containers/modals/DigitalProperty/FilterAnalysis'
import FilterFields from '@smartcoop/web-containers/modals/DigitalProperty/FilterFields'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import FieldListAnalysis from './FieldListAnalysis'
import FieldListHistory from './FieldListHistory'
import FieldListPlanned from './FieldListPlanned'
import FieldListProducer from './FieldListProducer'
import {
  ButtonContainer,
  SpaceButton,
  TabsGroup,
  TabItem,
  MapContainer
} from './styles'

const FieldListScreen = () => {
  const t = useT()
  const history = useHistory()
  const mounted = useRef(false)
  const mapRef = useRef(null)
  const dispatch = useCallback(useDispatch(), [])
  const location = useLocation()

  const { routes } = useRoutes()
  const { createDialog } = useDialog()
  const fields = useSelector(selectFields)
  const fieldsHistory = useSelector(selectFieldsHistory)
  const fieldsAnalysis = useSelector(selectFieldAnalysis)
  const userWrite = useSelector(selectUserCanWrite)

  const [currentMapZoom, setCurrentMapZoom] = useState(null)
  const [filters, setFilters] = useState({})
  const [loading, setLoading] = useState(false)
  const [filterText, setFilterText] = useState('')
  const [propertyHasFields, setPropertyHasFields] = useState(false)
  const [debouncedFilterText, setDebouncedFilterText] = useState('')
  const currentProperty = useSelector(selectCurrentProperty)
  const [opened, setOpened] = useState(true)
  const [tab, setTab] = useState(
    location?.state?.editFields ? null
      : ( location?.state?.isPlanned ? 'planned'
        : ( location?.state?.isFromHistory ? 'history'
          : (location?.state?.isAnalysis ? 'fieldAnalysis' : 'fields')
        )
      )
  )
  const [editFields, setEditFields] = useState(location?.state?.editFields || false)
  const [selectedAnalysis, setSelectedAnalysis] = useState(null)

  const isPlanned = useMemo(() => tab === 'planned', [tab])

  const isFields = useMemo(() => tab === 'fields', [tab])

  const isAnalysis = useMemo(() => tab === 'fieldAnalysis', [tab])

  const tabOptions = useMemo(() => [
    {
      value: 'history',
      label: t('history')
    },
    {
      value: 'fields',
      label: t('current growing seasons')
    },
    {
      value: 'planned',
      label: t('planned')
    }
  ], [t])


  const fieldsWithGrowingSeason = useMemo(() => {
    const updatedFields = []

    map([...fields], field => !isEmpty(field?.growingSeasons) ? map([...field?.growingSeasons], (item, index) => {
      const currentUpdatedField = find([...updatedFields], uf => uf.id === item.fieldId)

      if(currentUpdatedField && item.childrenPolygonId) {
        const updatedGrowingSeasons = [...currentUpdatedField?.growingSeasons]
        updatedGrowingSeasons[index] = { ...item, childrenPolygonId: `${ item.childrenPolygonId }-${ index }` }

        const newChildrenPolygons = filter(currentUpdatedField?.childrenPolygons, cp => find(updatedGrowingSeasons, gs => gs?.childrenPolygonId === cp?.id))

        const growingSeasonPolygonIds = uniq(map(updatedGrowingSeasons, (updatedGsItem) => {
          const gsId = updatedGsItem?.childrenPolygonId
          let newId = gsId

          if(size(newId) > 36){
            newId = gsId.slice(0, -2)
          }

          return newId
        }))


        const newChildrenPolygon = {
          ...find([...item?.fieldData?.childrenPolygons], polygon => polygon.id === item.childrenPolygonId),
          id: `${ item.childrenPolygonId }-${ index }`
        }

        const childrenPolygonList = [...item?.fieldData?.childrenPolygons]

        const emptyChildrenPolygons = filter(childrenPolygonList, cp => !includes(filter(growingSeasonPolygonIds, gs => gs), cp.id) && size(cp.id) === 36)

        const newFieldData = {
          ...currentUpdatedField,
          growingSeasons: [...updatedGrowingSeasons],
          property: field?.property,
          currentWeather: field?.currentWeather,
          childrenPolygons: [
            ...[...newChildrenPolygons, ...emptyChildrenPolygons],
            newChildrenPolygon
          ]
        }

        map([...updatedFields], (updatedField, ufIndex) => {
          if(updatedField?.id === item.fieldId) {
            updatedFields[ufIndex] = newFieldData
          }
        })
      } else if(!item?.childrenPolygonId) {
        if(find(updatedFields, updatedField => updatedField?.id === field?.id)) {
          updatedFields.push({ ...item.fieldData, productivityChallenge: field?.productivityChallenge, growingSeasons: [item], property: field?.property, currentWeather: field?.currentWeather, dontShow: true })
        } else {
          updatedFields.push({ ...item.fieldData, productivityChallenge: field?.productivityChallenge, growingSeasons: [item], property: field?.property, currentWeather: field?.currentWeather })
        }
      } else if (!isEmpty(item.childrenPolygonId) && !currentUpdatedField) {
        const newFieldGrowingSeasonsPolygons = uniq(map(field?.growingSeasons, gs => size(gs.childrenPolygonId) > 36 ? gs.childrenPolygonId.slice(0, -2) : gs.childrenPolygonId))
        const currentFieldChildrenPolygons = map(find(fields, fld => fld.id === item?.fieldId)?.childrenPolygons, fieldCp => fieldCp?.id)

        const includedChildrenPolygons = filter(item.fieldData?.childrenPolygons, cp => includes(newFieldGrowingSeasonsPolygons, cp.id) || includes(currentFieldChildrenPolygons, cp.id))

        updatedFields.push({ ...item.fieldData, productivityChallenge: field?.productivityChallenge, growingSeasons: [item], property: field?.property, currentWeather: field?.currentWeather, childrenPolygons: includedChildrenPolygons })
      }
    }) : updatedFields.push({ ...field }))

    map(updatedFields, (item, index) => {

      let groupedGrowingSeasons = values(groupBy(map(item?.growingSeasons, gs => ({ ...gs, fieldData: { ...gs?.fieldData, childrenPolygons: gs?.fieldData?.childrenPolygons ? JSON.stringify(gs?.fieldData?.childrenPolygons) : null } })), 'fieldData.childrenPolygons'))

      groupedGrowingSeasons = map([...groupedGrowingSeasons], arr => map(arr, gs => ({ ...gs, fieldData: { ...gs?.fieldData, childrenPolygons: gs?.fieldData?.childrenPolygons ? JSON.parse(gs?.fieldData?.childrenPolygons) : null } })))

      if(groupedGrowingSeasons.length > 1) {
        map(groupedGrowingSeasons, (growingSeasons, subIndex) => {
          const currentFieldChildrenPolygons = map(find([...fields], field => field?.id === growingSeasons[0]?.fieldId)?.childrenPolygons, fieldCp => fieldCp?.id)
          const growingSeasonIds = map(growingSeasons, gs => gs.childrenPolygonId)
          const currentGrowingSeasonsPolygonsIds = map(growingSeasons[0].fieldData?.childrenPolygons, cp => cp.id)
          const includedChildrenPolygons = filter(item?.childrenPolygons, cp => includes(growingSeasonIds, cp.id) || (includes(currentFieldChildrenPolygons, cp.id) && includes(currentGrowingSeasonsPolygonsIds, cp.id)))

          if(subIndex === 0) {
            updatedFields[index] = {
              ...updatedFields[index],
              growingSeasons,
              childrenPolygons: growingSeasons[0]?.childrenPolygonId ? includedChildrenPolygons : []
            }
          } else {
            updatedFields.push({
              ...updatedFields[index],
              growingSeasons,
              childrenPolygons: growingSeasons[0]?.childrenPolygonId ? includedChildrenPolygons : [],
              dontShow: true
            })
          }
        })
      }
    })

    return orderBy(updatedFields, ['fieldName', 'area'])
  },[fields])

  const allPolygonCoordinates = useMemo(
    () =>
      reduce(
        fieldsWithGrowingSeason,
        (acc, field) => [...acc, ...(field?.polygonCoordinates || [])],
        []
      ),
    [fieldsWithGrowingSeason]
  )

  const mapRegion = useMemo(
    () =>
      !isEmpty(allPolygonCoordinates)
        ? getPolygonCenter(allPolygonCoordinates)
        :  { latitude: currentProperty?.geolocalization?.latitude, longitude: currentProperty?.geolocalization?.longitude },
    [allPolygonCoordinates, currentProperty]
  )

  const iconChange = useMemo(() => (opened ? openLayout : cutLayout), [opened])

  const handleParams = useCallback(
    (paramValues) =>
      Object.keys(paramValues)
        .filter((key) => typeof paramValues[key] === 'boolean' || paramValues[key])
        .reduce((prev, curr) => ({ ...prev, [curr]: paramValues[curr] }), {}),
    []
  )

  const params = useMemo(() => {
    const filterParams = {
      ...filters,
      q: debouncedFilterText,
      isPlanned
    }
    return handleParams(filterParams)
  }, [debouncedFilterText, filters, handleParams, isPlanned])

  const handleRegisterFieldClick = useCallback(() => {
    if (mapRef && mapRef.current) {
      const newRegion = {
        latitude: mapRef.current.leafletElement.getCenter().lat,
        longitude: mapRef.current.leafletElement.getCenter().lng
      }
      history.push(routes.fieldRegister.path, {
        region: newRegion,
        mapZoom: mapRef.current.viewport.zoom || 13
      })
    } else history.push(routes.fieldRegister.path)
  }, [history, routes])

  const handleFieldHistoryClick = useCallback(
    (field, childrenPolygonId, itemGrowingSeason, growingSeasons) => {
      dispatch(FieldActions.setCurrentFieldHistory(field))
      dispatch(FieldActions.setFieldNavigationData({ growingSeasonId: itemGrowingSeason?.id, growingSeason: itemGrowingSeason, isFromHistory: true, childrenPolygonId, historyGrowingSeason: itemGrowingSeason, growingSeasons }))
      history.push(routes.fieldDetails.path.replace(':fieldId', field?.id))
    },
    [dispatch, history, routes]
  )

  const handleFieldClick = useCallback(
    ({ field, childrenPolygonId, itemGrowingSeason }) => {
      dispatch(FieldActions.setCurrentField(field))
      dispatch(FieldActions.setFieldNavigationData({ fieldId: field?.id, childrenPolygonId: childrenPolygonId && isValidUUid(childrenPolygonId) ? childrenPolygonId : childrenPolygonId && childrenPolygonId.slice(0, -2), isPlanned, growingSeasonId: itemGrowingSeason?.id }))
      history.push(routes.fieldDetails.path.replace(':fieldId', field?.id))
    },
    [dispatch, history, isPlanned, routes]
  )

  const loadFields = useCallback(
    debounce(() => {
      if(tab === 'planned' || tab === 'fields' || (!tab && editFields)) {
        dispatch(
          FieldActions.loadFields(
            params,
            () => setLoading(false),
            () => setLoading(false)
          )
        )
      }
      if(tab === 'fieldAnalysis') {
        dispatch(FieldActions.loadFieldsAnalysis(
          params,
          () => setLoading(false),
          () => setLoading(false)
        ))
      }
      if(tab === 'history') {
        dispatch(
          FieldActions.loadFieldsHistory(
            params,
            () => setLoading(false),
            () => setLoading(false)
          )
        )
      }
    }, 300),
    [dispatch, params, tab]
  )

  const debouncedChangeSearchFilter = useCallback(
    debounce((value) => {
      setDebouncedFilterText(value)
    }, 300),
    []
  )

  const onChangeSearchFilter = useCallback(
    (e) => {
      setFilterText(e.target.value)
      debouncedChangeSearchFilter(e.target.value)
    },
    [debouncedChangeSearchFilter]
  )

  const handleFilter = useCallback((paramValues) => {
    setLoading(true)
    setFilters(paramValues)
  }, [])

  const filterFields = useCallback(() => {
    createDialog({
      id: 'filter-fields',
      Component: FilterFields,
      props: {
        onSubmit: handleFilter,
        filters
      }
    })
  }, [createDialog, filters, handleFilter])

  const filterAnalysis = useCallback(() => {
    createDialog({
      id: 'filter-analysis',
      Component: FilterAnalysis,
      props: {
        onSubmit: handleFilter,
        filters
      }
    })
  }, [createDialog, filters, handleFilter])

  const toggleLayout = useCallback(() => {
    setOpened((old) => !old)
  }, [])

  useEffect(() => {
    if (!isEmpty(currentProperty)) {
      setLoading(true)
      loadFields()
    }
  }, [dispatch, currentProperty, loadFields, tab])

  useEffect(() => {
    dispatch(FieldActions.resetCurrentField())
    dispatch(FieldActions.resetCurrentFieldHistory())
  }, [dispatch])

  useEffect(() => {
    setPropertyHasFields(false)
    if (!isEmpty(currentProperty) && mounted.current) {
      setFilters({})
      setFilterText('')
      setDebouncedFilterText('')
    }
  }, [currentProperty])

  useEffect(() => {
    if (fields?.length) {
      setPropertyHasFields(true)
    }
  }, [fields])

  useEffect(() => {
    mapRef.current && mapRef.current.leafletElement._onResize()
  }, [opened])

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )

  const findPolygonColorById = useCallback((id) => {
    const growingSeasons = flatten(map(fields, item => item.growingSeasons))
    const foundGrowingSeason = find(growingSeasons, item => size(id) > 36 ? id.slice(0, -2) === item.childrenPolygonId : item.childrenPolygonId === id)

    return foundGrowingSeason ? GROWING_SEASON_COLOR[foundGrowingSeason?.crop?.slug] : '#fff'
  },[fields])

  const renderList = useMemo(() => {
    switch (tab) {
      case 'fieldAnalysis':
        return (
          <FieldListAnalysis
            selectedAnalysis={ selectedAnalysis }
            history={ history }
            routes={ routes }
            onChangeSearchFilter={ onChangeSearchFilter }
            filterText={ filterText }
            t={ t }
            analysis={ fieldsAnalysis }
            loading={ loading }
            filters={ filters }
            filterAnalysis={ filterAnalysis }
            handleAnalysisClick={ setSelectedAnalysis }
            loadFields={ loadFields }
          />
        )

      case 'history':
        return (
          <FieldListHistory
            propertyHasFields={ propertyHasFields }
            onChangeSearchFilter={ onChangeSearchFilter }
            filterText={ filterText }
            t={ t }
            fields={ fieldsHistory }
            loading={ loading }
            filterFields={ filterFields }
            filters={ filters }
            handleFieldHistoryClick={ handleFieldHistoryClick }
          />
        )

      case 'planned':
        return (
          <FieldListPlanned
            history={ history }
            routes={ routes }
            propertyHasFields={ propertyHasFields }
            onChangeSearchFilter={ onChangeSearchFilter }
            filterText={ filterText }
            t={ t }
            fields={ flatten(map(fields, item => item.growingSeasons)) }
            loading={ loading }
            handleFieldClick={ handleFieldClick }
            currentProperty={ currentProperty }
            userWrite={ userWrite }
            filters={ filters }
            filterFields={ filterFields }
            editFields={ editFields }
            loadFields={ loadFields }
          />
        )

      case 'fields':
      default:
        return (
          <FieldListProducer
            propertyHasFields={ propertyHasFields }
            onChangeSearchFilter={ onChangeSearchFilter }
            filterText={ filterText }
            t={ t }
            history={ history }
            routes={ routes }
            fields={ editFields ? fields : fieldsWithGrowingSeason }
            loading={ loading }
            handleRegisterFieldClick={ handleRegisterFieldClick }
            handleFieldClick={ handleFieldClick }
            currentProperty={ currentProperty }
            userWrite={ userWrite }
            filters={ filters }
            filterFields={ filterFields }
            editFields={ editFields }
          />
        )
    }
  }, [currentProperty, editFields, fields, fieldsAnalysis, fieldsHistory, fieldsWithGrowingSeason, filterAnalysis, filterFields, filterText, filters, handleFieldClick, handleFieldHistoryClick, handleRegisterFieldClick, history, loadFields, loading, onChangeSearchFilter, propertyHasFields, routes, selectedAnalysis, t, tab, userWrite])


  const renderPolygons = useMemo(() => {
    if(isPlanned || editFields) {
      return map(fields, field => !isEmpty(field?.childrenPolygons) ? map(field?.childrenPolygons, item => (
        <Polygon
          key={ `polygon-${ item.id }` }
          points={ item?.coordinates }
          color={ isPlanned || editFields ? colors.white : findPolygonColorById(item.id) }
        />
      )) : (
        <Polygon
          points={ field?.polygonCoordinates }
          // eslint-disable-next-line no-nested-ternary
          color={ colors.white }
        />))
    }
    return map(uniqBy(fieldsWithGrowingSeason, 'id'), field => !field?.dontShow && !isEmpty(field?.childrenPolygons) ? map(field?.childrenPolygons, item => (
      <Polygon
        key={ `polygon-${ item.id }` }
        points={ item?.coordinates }
        color={ findPolygonColorById(item.id) }
        onClick={ () => isFields ? handleFieldClick({ field, childrenPolygonId: item.id, itemGrowingSeason: find(flatten(map(uniqBy(fieldsWithGrowingSeason, 'id'), fld => fld.growingSeasons)), gs => gs.childrenPolygonId === item.id) }) : null }
      />
    )) : !field?.dontShow && (
      <Polygon
        points={ field?.polygonCoordinates }
        // eslint-disable-next-line no-nested-ternary
        color={ !isEmpty(field?.growingSeasons) ? GROWING_SEASON_COLOR[field?.growingSeasons[0]?.crop?.slug] : colors.white }
        onClick={ () => isFields ? handleFieldClick({ field, itemGrowingSeason: field?.growingSeasons[0] }) : null }
      />))

  }, [editFields, fields, fieldsWithGrowingSeason, findPolygonColorById, handleFieldClick, isFields, isPlanned])

  return (
    <SplitedScreenLayout
      withoutGoBack={ !isAnalysis }
      handleGoBack={ () => {
        setEditFields(false)
        setTab('fields')
        handleFilter({})
        setSelectedAnalysis(null)
      } }
      withoutDivider
      title={ { name: isAnalysis ? t('soil analysis') : t('field', { howMany: 2 }) } }
      divRightStyle={ { padding: 0 } }
      leftActions={ (propertyHasFields && !isAnalysis) && (
        <SpaceButton>
          <ButtonContainer style={ { minWidth: 100 } }>
            {userWrite && (
              <Button
                id="edit-field"
                onClick={ () => {
                  if(editFields) {
                    setTab('fields')
                    setEditFields(false)
                  } else {
                    setTab(null)
                    setEditFields(true)
                  }
                } }
                color={ editFields ? 'primary' : 'white' }
                disabled={ loading || isEmpty(currentProperty) }
                fullWidth
              >
                <I18n>edit</I18n>
              </Button>
            )}
          </ButtonContainer>
          <ButtonContainer style={ { minWidth: 100 } }>
            {userWrite && (
              <Button
                id="register-field"
                onClick={ handleRegisterFieldClick }
                color="secondary"
                disabled={ loading || isEmpty(currentProperty) }
                fullWidth
              >
                <I18n>register</I18n>
              </Button>
            )}
          </ButtonContainer>
        </SpaceButton>
      ) }
      leftChildren={
        opened && (
          <>
            {(propertyHasFields && userWrite && !isAnalysis) && (
              <ButtonContainer style={ { textAlign: 'end', marginLeft: '20px', marginRight: '20px', marginTop: '10px' } }>
                {userWrite && (
                  <Button
                    id="soil-analysis"
                    onClick={ () => {
                      setEditFields(false)
                      setTab('fieldAnalysis')
                    } }
                    color={ isAnalysis ? 'primary' : 'white' }
                    disabled={ loading || isEmpty(currentProperty) }
                    fullWidth
                  >
                    <Icon size={ 17 } icon={ erlenmeyer } color={ colors.black } style={ { marginRight: '5px' } }/>
                    <I18n>soil analysis</I18n>
                  </Button>
                )}
              </ButtonContainer>
            ) }
            <Divider />
            {!isAnalysis && (
              <TabsGroup
                value={ tab }
                onChange={ (_, newTab) => {
                  setTab(newTab)
                  setEditFields(false)
                } }
              >
                {map(tabOptions, option => (
                  <TabItem value={ option.value } key={ option.value } label={ option.label }/>
                ))}
              </TabsGroup>
            )}

            { renderList }
          </>
        )
      }
      rightChildren={
        <MapContainer>
          <Maps
            ref={ mapRef }
            region={ selectedAnalysis ? selectedAnalysis?.pinLocation : mapRegion }
            zoom={ selectedAnalysis ? 16 : 13 }
            style={ { flex: 1, overflow: 'none' } }
            cursor="crosshair"
            layer="google"
            onZoomEnd={ () => setCurrentMapZoom(mapRef?.current?.viewport?.zoom) }
          >
            <Control position="topcenter">
              <div style={ { height: 70, display: 'flex' } }>
                <div
                  style={ {
                    margin: '10px 20px 10px 10px',
                    justifyContent: 'flex-end'
                  } }
                >
                  <Button
                    id="toggle-Layout"
                    color={ colors.white }
                    style={ {
                      padding: '7.5px 10px',
                      fontSize: '0.875rem'
                    } }
                    onClick={ toggleLayout }
                  >
                    <Icon
                      icon={ iconChange }
                      size={ 17 }
                      style={ { marginLeft: 5 } }
                    />
                  </Button>
                </div>
              </div>
            </Control>
            {renderPolygons}
            {isAnalysis && map([...fieldsAnalysis], (analysis) => (
              <PinMarker
                key={ analysis?.id }
                coordinate={ analysis?.pinLocation }
                size={ currentMapZoom === 18 ? 60 : (currentMapZoom === 17 ? 55 : (currentMapZoom === 16 ? 50 : (currentMapZoom === 15 ? 45 : currentMapZoom * 2))) + (selectedAnalysis?.id === analysis?.id ? 12 : 0) }
                customIcon={ shovel }
                onClick={ () => setSelectedAnalysis(analysis) }
                iconAnchor={ {
                  x: 13,
                  y: 38
                } }
              />
            ))}
          </Maps>
          {selectedAnalysis && (
            <FieldAnalysisDetailsFragment
              analysis={ selectedAnalysis }
              closeAnalysis={ () => setSelectedAnalysis(null) }
            />
          )}
        </MapContainer>
      }
    />
  )
}

export default FieldListScreen
