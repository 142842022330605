import React, { useRef, useCallback, useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

import trimMask from '@meta-awesome/functions/src/trimMask'
import moment from 'moment/moment'
import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import toNumber from 'lodash/toNumber'

import stateRegistrationSchema from '@smartcoop/forms/schemas/profile/stateRegistration.schema'
import { useT } from '@smartcoop/i18n'
import { getCities } from '@smartcoop/services/apis/smartcoopApi/resources/organization'
import { UserActions } from '@smartcoop/stores/user'
import { stateOptions } from '@smartcoop/utils/constants'
import { momentBackDateTimeFormat, momentBackDateFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputCep from '@smartcoop/web-components/InputCep'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputText from '@smartcoop/web-components/InputText'
import Modal from '@smartcoop/web-components/Modal'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import { Title, Row } from './styles'

export default function StateRegistrationRegisterModal({
  id,
  open,
  handleClose,
  defaultValues,
  onClose
}) {
  const dispatch = useDispatch()
  const formRef = useRef()
  const t = useT()

  // const [generateInvoiceNote, setGenerateInvoiceNote] = useState(defaultValues?.generateInvoiceNote)
  const [holder, setHolder] = useState(isEmpty(defaultValues) ? true : defaultValues?.holder)
  // const [milkEnable, setMilkEnable] = useState(defaultValues?.milkEnable)
  const [selectedState, setSelectedState] = useState(defaultValues?.state || '')

  const handleSubmit = useCallback((data = {}) => {
    dispatch(UserActions.createUserStateRegistration({
      ...defaultValues,
      ...data,
      postalCode: trimMask(data?.postalCode),
      number: toNumber(data?.number),
      generateInvoiceNote: true,
      holder,
      milkEnable: true
    }, () => {
      onClose()
      handleClose()
    }))

  }, [defaultValues, dispatch, handleClose, holder, onClose])

  const receiveAddress = useCallback(
    (address) => {
      formRef.current.setData({
        city: address?.ibge,
        state: address?.state,
        district: address?.neighborhood,
        street: address?.street
      })
    },
    [formRef]
  )

  useEffect(() => {
    if(defaultValues?.postalCode && formRef?.current) {
      receiveAddress(defaultValues?.postalCode)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[formRef, defaultValues])

  return (
    <Modal
      id={ id }
      open={ open }
      disableFullScreen
      disableEsc
      maxWidth="md"
      fullWidth
      title={
        <Title>{ defaultValues?.id ? t('edit state registration') : t('create state registration')}</Title>
      }
      FooterComponent={ () => (
        <>
          <Button
            id="back"
            onClick={ handleClose }
            variant="outlined"
          >
            <span>{t('go back')}</span>
          </Button>
          <Button
            id="submit"
            onClick={ () => formRef.current.submit() }
          >
            <span>{ t('save') }</span>
          </Button>
        </>
      ) }
    >
      <Form
        ref={ formRef }
        schemaConstructor={ stateRegistrationSchema }
        onSubmit={ handleSubmit }
      >
        <InputText
          name="stateRegistration"
          label={ t('state registration') }
          fullWidth
          defaultValue={ defaultValues?.stateRegistration }
          maxLength={ 11 }
          notSeparate
        />
        <InputCep
          name="postalCode"
          label={ t('cep') }
          fullWidth
          onAddressChange={ (address) => receiveAddress(address) }
          defaultValue={ defaultValues?.postalCode }
        />
        <InputSelect
          name="state"
          label={ t('state', { howMany: 1 }) }
          fullWidth
          options={ stateOptions }
          defaultValue={ defaultValues?.state }
          onChange={ ({ target: { value } }) => setSelectedState(value) }
        />
        <InputSelect
          name="city"
          label={ t('city', { howMany: 1 }) }
          fullWidth
          options={ selectedState ? getCities : [] }
          queryParams={ { limit: 99999, stateInitials: selectedState } }
          asyncOptionLabelField="name"
          asyncOptionValueField="ibgeCode"
          defaultValue={ defaultValues?.city }
        />
        <InputText
          label={ t('neighborhood') }
          name="district"
          fullWidth
          defaultValue={ defaultValues?.district }
        />
        <InputText
          name="street"
          label={ t('street') }
          fullWidth
          defaultValue={ defaultValues?.street }
        />
        <InputText
          name="number"
          label={ t('number') }
          fullWidth
          defaultValue={ defaultValues?.number }
        />
        {isEmpty(defaultValues) ? null : (
          <InputDate
            label={ t('termination date') }
            placeholder={ t('termination date') }
            name="terminationDate"
            fullWidth
            defaultValue={ isEmpty(defaultValues?.terminationDate) ? '' : moment(defaultValues?.terminationDate, momentBackDateTimeFormat).format(momentBackDateFormat) }
            pickerProps={ {
              maxDate: moment().format()
            } }
          />
        )}
        <Row>
          <RadioGroup
            options={ [
              {
                label: t('holder'),
                value: true
              },
              {
                label: t('participant'),
                value: false
              }
            ] }
            variant="row"
            value={ holder }
            onChange={ (e) => setHolder(e.value) }
            detached
          />
        </Row>
      </Form>
    </Modal>
  )
}

StateRegistrationRegisterModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onClose: PropTypes.func,
  defaultValues: PropTypes.object
}

StateRegistrationRegisterModal.defaultProps = {
  onClose: () => {},
  defaultValues: {}
}