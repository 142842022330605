/* eslint-disable no-nested-ternary */
/* eslint-disable no-case-declarations */
import React, { useState, useMemo, useRef, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import I18n, { useT } from '@smartcoop/i18n'
import { selectModuleIsTechnical, selectModuleIsAdmin } from '@smartcoop/stores/module/selectorModule'
import { selectCurrentProperty, selectFamilyGroupAccess, selectFamilyGroupFinancialDataAccess } from '@smartcoop/stores/property/selectorProperty'
import { selectCurrentOwnerExtraAttributes, selectTechnicalFinancialDataAccess } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUserExtraAttributes } from '@smartcoop/stores/user/selectorUser'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import EndOfSeasonsReportForm from '@smartcoop/web-containers/forms/digitalProperty/reports/EndOfSeasonsReportForm'
import IncomeReportForm from '@smartcoop/web-containers/forms/digitalProperty/reports/IncomeReportForm'
import TechnicalEvaluationReportForm from '@smartcoop/web-containers/forms/digitalProperty/reports/TechnicalEvaluationReportForm'
import TechnicalVisitReportForm from '@smartcoop/web-containers/forms/digitalProperty/reports/TechnicalVisitReportForm'
import SplitedScreenLayout from '@smartcoop/web-containers/layouts/SplitedScreenLayout'

import ReportDownloadScreen from '../../ReportDownloadScreen'
import ReportItem from './ReportItem'
import { ButtonsContainer, ContainerList, Container } from './styles'

const ReportsScreen = () => {
  const t = useT()
  const location = useLocation()

  const formRef = useRef(null)
  const [selectedForm, setSelectedForm] = useState(location?.state?.selectedForm || '')
  const [group, setGroup] = useState(null)
  const [generatedGroup, setGeneratedGroup] = useState(null)
  const [model, setModel] = useState('null')
  const [generatedModel, setGeneratedModel] = useState('null')
  const [showRecommendations, setShowRecommendations] = useState(true)
  const [disabledSave, setDisabledSave] = useState(false)
  const [lotTypes, setLotTypes] = useState([])
  const [extraData, setExtraData] = useState({})
  const [showReport, setShowReport] = useState(false)

  const userExtraAttributes = useSelector(selectUserExtraAttributes)
  const currentOwnerExtraAttributes = useSelector(selectCurrentOwnerExtraAttributes)

  const technicalFinancialDataAccess = useSelector(selectTechnicalFinancialDataAccess)
  const familyGroupFinancialDataAccess = useSelector(selectFamilyGroupFinancialDataAccess)

  const familyGroupAccess = useSelector(selectFamilyGroupAccess)
  const isTechnical = useSelector(selectModuleIsTechnical)
  const isAdmin = useSelector(selectModuleIsAdmin)

  const extraAttributes = useMemo(() => userExtraAttributes || {}, [userExtraAttributes])

  // eslint-disable-next-line no-nested-ternary
  const shouldDisplayCosts = useMemo(() => familyGroupAccess ? familyGroupFinancialDataAccess : isTechnical ? technicalFinancialDataAccess && currentOwnerExtraAttributes?.enabledCosts : extraAttributes?.enabledCosts,[currentOwnerExtraAttributes, extraAttributes, familyGroupAccess, familyGroupFinancialDataAccess, isTechnical, technicalFinancialDataAccess])

  const currentProperty = useSelector(selectCurrentProperty)

  const reports = useMemo(() => {
    const currentReports = []
    currentReports.push({
      slug: 'technicalVisit',
      name: t('technical visit'),
      description: 'Visão geral de visitas, avaliações e recomendação técnicas.'
    })

    currentReports.push({
      slug: 'technicalEvaluation',
      name: t('technical evaluation'),
      description: 'Relatório de avaliações técnicas.'
    })

    if(!isAdmin) {
      if(shouldDisplayCosts) {
        currentReports.push({
          slug: 'dre',
          name: t('income statement for the year'),
          description: 'Demonstrativo financeiro completo da propriedade e DRE.'
        })
      }

      if(currentProperty?.id) {
        currentReports.push({
          slug: 'endOfSeason',
          name: t('follow-up of season'),
          description: 'Visão geral dos cultivos, operações e reportes de ocorrências.'
        })
      }
    }

    return currentReports
  }, [currentProperty.id, isAdmin, shouldDisplayCosts, t])

  const RenderForm = useMemo(
    () => ({
      'endOfSeason': EndOfSeasonsReportForm,
      'technicalVisit': TechnicalVisitReportForm,
      'technicalEvaluation': TechnicalEvaluationReportForm,
      'dre': IncomeReportForm
    }[selectedForm]),
    [selectedForm]
  )

  const onSubmitForm = useCallback((data) => {
    setShowReport(true)
    setExtraData(data)
  }, [])

  const defaultReportData = useMemo(() => ({
    showReport,
    ...extraData,
    showRecommendations,
    generatedModel,
    generatedGroup,
    selectedForm,
    propertyId: currentProperty?.id,
    lotTypes
  }), [currentProperty, extraData, generatedGroup, generatedModel, lotTypes, selectedForm, showRecommendations, showReport])

  return (
    <SplitedScreenLayout
      withoutGoBack={ isEmpty(selectedForm) }
      handleGoBack={ () => setSelectedForm('') }
      withoutDivider
      title={ { name: isEmpty(selectedForm) ?  t('reports', { howMany: 2 }) : find(reports, item => item.slug === selectedForm).name }  }
      divRightStyle={ { padding: 0 } }
      divLeftStyle={ { padding: 0, height: '80vh', maxHeight: '80vh' } }
      leftChildren={
        isEmpty(selectedForm) ? (
          <ContainerList>
            {map(reports, (item) => (
              <ReportItem
                report={ item.name }
                description={ item.description }
                key={ item.name }
                onClick={ () => {
                  setShowReport(false)
                  setSelectedForm(item.slug)
                } }
              />
            ))}
          </ContainerList>
        ) : (
          <ContainerList>
            <RenderForm
              ref={ formRef }
              onSubmit={ onSubmitForm }
              setShowRecommendations={ setShowRecommendations }
              showRecommendations={ showRecommendations }
              setDisabledSave={ setDisabledSave }
              group={ group }
              setGroup={ setGroup }
              model={ model }
              setModel={ setModel }
              lotTypes={ lotTypes }
              setLotTypes={ setLotTypes }
            />
            <ButtonsContainer>
              <Button
                id="web-cancel-form-button"
                onClick={ () => setSelectedForm('') }
                color={ colors.white }
              >
                <I18n>cancel</I18n>
              </Button>
              <Button
                id="web-save-form-button"
                onClick={ () => {
                  setGeneratedGroup(group)
                  setGeneratedModel(model)
                  formRef.current.submit()
                } }
                disabled={ disabledSave }
              >
                <I18n>generate</I18n>
              </Button>
            </ButtonsContainer>
          </ContainerList>
        )
      }
      rightChildren={ !isEmpty(selectedForm) ? (
        <Container>
          <ReportDownloadScreen
            defaultReportData={ defaultReportData }
          />
        </Container>
      ) : null
      }
    />
  )
}

export default ReportsScreen
