import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const technicalVehicles = ({ t }) => Yup.object().shape({
  plate: flow(
    required({ t })
  )(Yup.string()),

  brandId: flow(
    required({ t })
  )(Yup.string()),

  model: flow(
    required({ t })
  )(Yup.string()),

  year: flow(
    required({ t })
  )(Yup.string())
})

export default technicalVehicles