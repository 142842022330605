import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import number from '@smartcoop/forms/validators/number.validator'
import required from '@smartcoop/forms/validators/required.validator'

const nfeIntegrator = ({ t }) => Yup.object().shape({
  stateRegistration: flow(
    required({ t }),
    number({ t })
  )(Yup.string()),

  terminationDate: Yup.string().nullable(),

  street: flow(
    required({ t })
  )(Yup.string()),

  number: flow(
    required({ t })
  )(Yup.string()),

  district: flow(
    required({ t })
  )(Yup.string()),

  city: flow(
  )(Yup.string()),

  state: flow(
    required({ t })
  )(Yup.string()),

  postalCode: flow(
    required({ t })
  )(Yup.string())
})

export default nfeIntegrator
