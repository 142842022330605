import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication/duckAuthentication'

// Initial state
const INITIAL_STATE = {
  animals: [],
  listUpdatedAt: null,
  currentAnimal: {},
  isDetail: false,
  error: null,
  activeTab: 'registerAnimal',
  unavailableEarrings: [],
  propertyBulls: [],
  pevList: [],
  milkControlList: [],
  observations: [],
  animalWeight: [],
  inseminators: [],
  bulls: []
}

/**
 * Creating actions and types with reduxsauce.
 */
const { Types, Creators } = createActions({
  loadAnimals: ['params', 'onSuccess', 'onError'],
  loadAnimalsSuccess: ['animals', 'page'],
  setListUpdatedAt: ['listUpdatedAt'],

  loadCurrentAnimal: ['onSuccess', 'OnError'],
  loadCurrentAnimalSuccess: ['currentAnimal', 'onSuccess', 'OnError'],

  saveAnimal: ['params', 'onSuccess', 'onError', 'isEdit'],
  saveOfflineAnimal: ['params', 'onSuccess', 'onError', 'web'],
  deleteAnimal: ['animalId', 'propertyId', 'onSuccess', 'onError'],
  deleteOfflineAnimal: ['animalId', 'onSuccess', 'onError'],

  saveMilkControl: ['params', 'onSuccess', 'onError'],
  saveOfflineMilkControl: ['params', 'onSuccess', 'onError'],

  loadInseminators: ['params', 'onSuccess', 'onError'],
  loadInseminatorsSuccess: ['inseminators'],
  saveInseminator: ['params', 'onSuccess', 'onError'],
  saveOfflineInseminator: ['params', 'onSuccess', 'onError'],
  deleteInseminator: ['inseminatorId', 'onSuccess', 'onError'],

  loadMilkControlList: ['params', 'onSuccess', 'onError'],
  loadMilkControlListSuccess: ['milkControlList'],

  loadAnimalPevList: ['params', 'onSuccess', 'onError'],
  loadAnimalPevListSuccess: ['params', 'page', 'onSuccess'],
  saveAnimalPev: ['pev', 'onSuccess', 'onError', 'isEdit'],
  saveOfflineAnimalPev: ['pev', 'onSuccess', 'onError', 'web'],
  deleteAnimalPev: ['pevId', 'onSuccess', 'onError'],
  saveAnimalWeight: ['params', 'onSuccess', 'onError', 'isEdit'],
  saveOfflineAnimalWeight: ['params', 'onSuccess', 'onError'],
  importAnimalWeight: ['params', 'onSuccess', 'onError'],
  loadAnimalWeight: ['params', 'onSuccess', 'onError'],
  loadAnimalWeightSuccess: ['animalWeight'],
  resetAnimalWeight: [],
  deleteOfflineAnimalPev: ['pevId', 'onSuccess', 'onError'],
  resetAnimalPevList: [],

  deleteAnimalWeight: ['weightId', 'onSuccess', 'onError'],
  deleteOfflineAnimalWeight: ['weightId', 'onSuccess', 'onError'],

  importAnimals: ['params', 'onSuccess', 'onError', 't'],

  loadAnimalStatus: ['onSuccess'],
  loadAnimalLots: ['onSuccess'],
  loadAnimalBreeds: ['onSuccess'],
  loadAnimalBulls: ['onSuccess'],
  loadAnimalInseminationTypes: ['onSuccess'],

  setCurrentAnimal: ['currentAnimal'],
  resetCurrentAnimal: [],

  setIsDetail: ['isDetail'],
  resetIsDetail: [],

  setActiveTab: ['activeTab'],

  loadBulls: ['params', 'onSuccess', 'onError'],
  saveBull: ['params', 'onSuccess', 'onError'],
  loadBullsSuccess: ['params', 'page'],

  loadUnavailableEarrings: ['params'],
  loadUnavailableEarringsSuccess: ['unavailableEarrings'],
  loadPropertyBulls: [],
  loadPropertyBullsSuccess: ['propertyBulls'],

  changeLots: ['params', 'onSuccess', 'onError'],

  loadAnimalObservation: ['params', 'onSuccess', 'onError'],
  loadAnimalObservationSuccess: ['observations', 'page', 'onSuccess'],
  saveAnimalObservation: ['params', 'onSuccess', 'onError', 'isEdit'],
  saveOfflineAnimalObservation: ['params', 'onSuccess', 'onError', 'web'],
  deleteAnimalObservation: ['observationId', 'onSuccess', 'onError'],
  deleteOfflineAnimalObservation: ['observationId', 'onSuccess', 'onError'],
  resetAnimalObservation: [],

  animalError: ['error']
})

markActionsOffline(Creators, [
  'deleteAnimal',
  'saveAnimal',
  'saveAnimalPev',
  'saveMilkControl',
  'deleteAnimalPev',
  'saveAnimalObservation',
  'deleteAnimalObservation'
])

/**
 * Reducers functions
 */

const loadAnimalsSuccess = (state = INITIAL_STATE, { animals }) => ({
  ...state,
  error: INITIAL_STATE.error,
  animals
})

const loadInseminatorsSuccess = (state = INITIAL_STATE, { inseminators }) => ({
  ...state,
  error: INITIAL_STATE.error,
  inseminators
})

const setListUpdatedAt = (state = INITIAL_STATE, { listUpdatedAt }) => ({
  ...state,
  error: INITIAL_STATE.error,
  listUpdatedAt
})

const animalError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const loadBullsSuccess = (state = INITIAL_STATE, { params, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  bulls: page === 1 ? params : [
    ...state.animals,
    ...params
  ]
})


const setCurrentAnimal = (state = INITIAL_STATE, { currentAnimal }) => ({
  ...state,
  currentAnimal
})

const resetcurrentAnimal = (state = INITIAL_STATE) => ({
  ...state,
  currentAnimal: INITIAL_STATE.currentAnimal
})

const resetAnimalWeight = (state = INITIAL_STATE) => ({
  ...state,
  animalWeight: INITIAL_STATE.animalWeight
})

const setIsDetail = (state = INITIAL_STATE, { isDetail }) => ({
  ...state,
  isDetail
})

const loadUnavailableEarringsSuccess = (state = INITIAL_STATE, { unavailableEarrings }) => ({
  ...state,
  unavailableEarrings
})

const loadPropertyBullsSuccess = (state = INITIAL_STATE, { propertyBulls }) => ({
  ...state,
  propertyBulls
})

const loadAnimalWeightSuccess = (state = INITIAL_STATE, { animalWeight }) => ({
  ...state,
  animalWeight
})

const resetIsDetail = (state = INITIAL_STATE) => ({
  ...state,
  isDetail: INITIAL_STATE.isDetail
})

const setActiveTab = (state = INITIAL_STATE, { activeTab }) => ({
  ...state,
  activeTab
})

const loadAnimalPevListSuccess = (state = INITIAL_STATE, { params, page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  pevList: page === 1 ? params : [
    ...state.pevList,
    ...params
  ]
})

const loadMilkControlListSuccess = (state = INITIAL_STATE, { milkControlList }) => ({
  ...state,
  error: INITIAL_STATE.error,
  milkControlList
})

const loadAnimalObservationSuccess = (state = INITIAL_STATE, { observations , page }) => ({
  ...state,
  error: INITIAL_STATE.error,
  observations: page === 1 ? observations : [
    ...state.observations,
    ...observations
  ]
})

const loadCurrentAnimalSuccess = (state = INITIAL_STATE, { currentAnimal }) => ({
  ...state,
  error: INITIAL_STATE.error,
  currentAnimal
})

const resetAnimalPevList = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error,
  pevList: INITIAL_STATE.pevList
})

const resetAnimalObservation = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error,
  observations: INITIAL_STATE.observations
})

const logout = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */

export default createReducer(INITIAL_STATE, {
  [Types.LOAD_ANIMALS_SUCCESS]: loadAnimalsSuccess,

  [Types.LOAD_INSEMINATORS_SUCCESS]: loadInseminatorsSuccess,

  [Types.SET_LIST_UPDATED_AT]: setListUpdatedAt,

  [Types.ANIMAL_ERROR]: animalError,

  [Types.LOAD_CURRENT_ANIMAL_SUCCESS]: loadCurrentAnimalSuccess,

  [Types.LOAD_ANIMAL_PEV_LIST_SUCCESS]: loadAnimalPevListSuccess,
  [Types.LOAD_MILK_CONTROL_LIST_SUCCESS]: loadMilkControlListSuccess,
  [Types.RESET_ANIMAL_PEV_LIST]: resetAnimalPevList,

  [Types.SET_CURRENT_ANIMAL]: setCurrentAnimal,
  [Types.RESET_CURRENT_ANIMAL]: resetcurrentAnimal,

  [Types.SET_IS_DETAIL]: setIsDetail,
  [Types.RESET_IS_DETAIL]: resetIsDetail,

  [Types.LOAD_UNAVAILABLE_EARRINGS_SUCCESS]: loadUnavailableEarringsSuccess,
  [Types.LOAD_PROPERTY_BULLS_SUCCESS]: loadPropertyBullsSuccess,

  [Types.LOAD_ANIMAL_OBSERVATION_SUCCESS]: loadAnimalObservationSuccess,

  [Types.LOAD_ANIMAL_WEIGHT_SUCCESS]: loadAnimalWeightSuccess,

  [Types.LOAD_BULLS_SUCCESS]: loadBullsSuccess,

  [Types.RESET_ANIMAL_OBSERVATION]: resetAnimalObservation,

  [Types.RESET_ANIMAL_WEIGHT]: resetAnimalWeight,

  [Types.SET_ACTIVE_TAB]: setActiveTab,

  [AuthenticationTypes.LOGOUT]: logout
})

export {
  Types as AnimalTypes,
  Creators as AnimalActions
}
