import React, { useRef, useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'

import join from 'lodash/join'
import map from 'lodash/map'

import Autorenew from '@material-ui/icons/Autorenew'


import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { getFiscalFigure } from '@smartcoop/services/apis/smartcoopApi/resources/nfe'
import { useSnackbar } from '@smartcoop/snackbar'
import { NfeActions } from '@smartcoop/stores/nfe'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import FiscalRegisterModal from '@smartcoop/web-containers/modals/FiscalRegisterModal'

import { Container, FilterRow, Badge } from './style'

const FiscalRegisterScreen = () => {
  const t = useT()
  const tableRef = useRef(null)
  const { createDialog } = useDialog()
  const dispatch = useDispatch()
  const snackbar = useSnackbar()

  const reloadDataTable = useCallback(
    () => {
      tableRef.current.onQueryChange()
    },
    []
  )


  const columns = useMemo(
    () => [
      {
        title: 'Figura Fiscal',
        field: 'code',
        sorting: true,
        defaultSort: 'desc'
      },
      {
        title: t('organization', { howMany: 1 }),
        field: 'organization.tradeName',
        sorting: true
      },
      {
        title: 'CFOP',
        field: 'cfop.code',
        sorting: true
      },
      {
        title: t('products'),
        field: 'products',
        render: (row) => {
          const value = map(row?.products, item => item?.shortDescription)
          return join(value, ' | ')
        },
        sorting: true
      },
      {
        title: t('integrations'),
        field: 'integrationCount',
        render: (row) => (<div style={ { display: 'flex', alignItems: 'center', flexDirection: 'row', justifyContent: 'center' } }><Autorenew size={ 20 } color={ colors.grey } style={ { marginRight: 5 } } /><b>{row?.integrationCount}</b></div>),
        sorting: true,
        align: 'center'
      },
      {
        title: t('status'),
        field: 'active',
        sorting: true,
        render: (row) => (
          <Badge
            backgroundColorBadge={ row?.active ? '(40, 159, 48, 0.1)' : '(216, 27, 96, 0.1)'  }
            colorBadge={ row?.active ? '(40, 159, 48, 1)': '(216, 27, 96, 1)'  }
          >
            {row?.active ? t('active', { gender: 'male' }) : t('inactive', { gender: 'male' }) }
          </Badge>
        )
      }
    ],
    [t]
  )

  const openModal = useCallback((_, data) => {
    createDialog({
      id: 'informative-register-file',
      Component: FiscalRegisterModal,
      props: {
        name: t('fiscal register'),
        defaultValues: data,
        reloadDataTable
      }

    })
  }, [createDialog, reloadDataTable, t])

  const handleModalDelete = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => dispatch(NfeActions.deleteFiscalFigure(
            row?.id,
            () => {
              snackbar.success(t('fiscal register deleted with success'))
              tableRef.current.onQueryChange()
            })),
          textWarning: t('are you sure you want to delete this fiscal register?')
        }
      })
    },
    [createDialog, dispatch, snackbar, t]
  )
  return (
    <Container>
      <FilterRow>
        <div style={ { width: '20vw', marginLeft: 20 } }>
          <Button
            variant="contained"
            color={ colors.secondary }
            onClick={ openModal }
            fullWidth
          >
            {t('new fiscal register')}
          </Button>
        </div>
      </FilterRow>
      <DataTable
        hasPagination={ false }
        columns={ columns }
        data={ getFiscalFigure }
        tableRef={ tableRef }
        onEditClick={ openModal }
        onDeleteClick={ handleModalDelete }
        conditionToDelete={ row => row?.integrationCount === 0 }
        id="list-informative-files"
      />
    </Container>
  )
}

export default FiscalRegisterScreen
