import { markActionsOffline } from 'redux-offline-queue'
import { createActions, createReducer } from 'reduxsauce'

import { AuthenticationTypes } from '../authentication'
import { makeActionFree } from '../stores'

/* Initial State */
const INITIAL_STATE = {
  data: {},
  error: null,
  stateRegistrations: []
}

/**
 * Creating actions and types with reduxsauce.
 */
export const { Types, Creators } = createActions({
  loadUser: ['onSuccess', 'onError'],
  resetUserData: [],
  loadUserSuccess: ['user', 'onSuccess'],
  updateUser: ['user', 'onSuccess'],
  saveUser: ['params', 'onSuccess', 'onError'],
  saveInitialUser: ['params', 'onSuccess', 'onError'],
  saveUserPicture: ['params', 'onSuccess', 'onError'],
  deleteUserOrganization: ['params', 'onSuccess', 'onError'],
  revokeUserAccess: ['params', 'onSuccess', 'onError'],
  loadUserStateRegistrations: ['params', 'onSuccess', 'onError'],
  createUserStateRegistration: ['params', 'onSuccess', 'onError'],
  loadUserStateRegistrationsSuccess: ['stateRegistrations'],
  userError: ['error']
})

makeActionFree('loadUser')
makeActionFree('loadUserSuccess')
makeActionFree('updateUser')
makeActionFree('userError')
makeActionFree('resetUserData')
makeActionFree('saveInitialUser')

/**
 * Assigning offline actions
 */
markActionsOffline(Creators, [
  'loadUser',
  'saveUser',
  'saveUserPicture'
])

/**
 * Reducers functions
 */
const loadUserSuccess = (state = INITIAL_STATE, { user }) => ({
  ...state,
  error: INITIAL_STATE.error,
  data: {
    ...state.data,
    ...user
  }
})

const loadUserStateRegistrationsSuccess = (state = INITIAL_STATE, { stateRegistrations }) => ({
  ...state,
  error: INITIAL_STATE.error,
  stateRegistrations
})

const resetUserData = (state = INITIAL_STATE) => ({
  ...state,
  error: INITIAL_STATE.error,
  data: INITIAL_STATE.data
})

const updateUser = (state = INITIAL_STATE, { user }) => ({
  ...state,
  error: INITIAL_STATE.error,
  data: {
    ...state.data,
    ...user
  }
})

const userError = (state = INITIAL_STATE, { error }) => ({
  ...state,
  error
})

const logout = () => ({ ...INITIAL_STATE })

const resetAuthentication = () => ({ ...INITIAL_STATE })

/**
 * Creating reducer with Types.
 */
export default createReducer(INITIAL_STATE, {
  [Types.LOAD_USER_STATE_REGISTRATIONS_SUCCESS]: loadUserStateRegistrationsSuccess,
  [Types.LOAD_USER_SUCCESS]: loadUserSuccess,
  [Types.UPDATE_USER]: updateUser,

  [Types.USER_ERROR]: userError,
  [Types.RESET_USER_DATA]: resetUserData,

  [AuthenticationTypes.FIREBASE_LOGOUT_SUCCESS]: logout,
  [AuthenticationTypes.RESET_AUTHENTICATION]: resetAuthentication
})

export {
  Types as UserTypes,
  Creators as UserActions
}
