import { call, put, select, takeLatest } from 'redux-saga/effects'

import { isEmpty } from 'lodash'

import { userService } from '@smartcoop/database/services/userService'
import { createStateRegistration, editStateRegistration, getUserStateRegistrations } from '@smartcoop/services/apis/smartcoopApi/resources/stateRegistration'
import {
  getUserByCpf,
  updateUser as updateUserService,
  createProfilePicture as createProfilePictureService,
  updateProfilePicture as updateProfilePictureService,
  deleteUserOrganization as deleteUserOrganizationService,
  revokeUserAccess as revokeUserAccessService
} from '@smartcoop/services/apis/smartcoopApi/resources/user'

import { selectDatabase } from '../database/selectorDatabase'
import { UserActions, UserTypes } from './duckUser'
import { selectUser } from './selectorUser'

function* loadUser({ onSuccess = () => {}, onError = () => {} }) {
  try {
    const { document: doc } = yield select(selectUser)

    const user = yield call(getUserByCpf, {}, { document: doc })

    yield put(UserActions.loadUserSuccess(
      user,
      onSuccess
    ))
  } catch (error) {
    yield put(UserActions.userError(error))
    yield call(onError, error)
  }
}

function* loadUserStateRegistrations({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { data: { data } } = yield call(getUserStateRegistrations, { ...params })

    yield put(UserActions.loadUserStateRegistrationsSuccess(
      data
    ))
    yield call(onSuccess)
  } catch (error) {
    yield put(UserActions.userError(error))
    yield call(onError, error)
  }
}

function* createUserStateRegistration({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(params?.id ? editStateRegistration : createStateRegistration, { ...params }, { stateRegistrationId: params?.id })

    yield call(onSuccess)
  } catch (error) {
    yield put(UserActions.userError(error))
    yield call(onError, error)
  }
}

function* deleteUserOrganization({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(deleteUserOrganizationService, params, { organizationId: params?.organizationId })

    onSuccess()
  } catch (error) {
    yield put(UserActions.userError(error))
    yield call(onError, error)
  }
}

function* revokeUserAccess({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    yield call(revokeUserAccessService, params)

    onSuccess()
  } catch (error) {
    yield put(UserActions.userError(error))
    yield call(onError, error)
  }
}

function* loadUserSuccess({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* updateUser({ onSuccess = () => {} }) {
  yield call(onSuccess)
}

function* saveUser({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { password, ...user } = yield select(selectUser)

    const data = isEmpty(params) ? user : params

    yield call(updateUserService, {
      ...data,
      activeRegister: true
    },
    { userId: user.id }
    )

    yield call(onSuccess)
  } catch (error) {
    yield put(UserActions.userError(error))
    yield call(onError, error)
  }
}

function* saveInitialUser({ params = {}, onSuccess = () => {}, onError = () => {} }) {
  try {
    const { password, ...user } = yield select(selectUser)

    const data = isEmpty(params) ? user : params

    yield call(updateUserService, {
      ...data,
      activeRegister: true
    },
    { userId: user.id }
    )

    yield call(onSuccess)
  } catch (error) {
    yield put(UserActions.userError(error))
    yield call(onError, error)
  }
}

function* saveUserPicture({ params, onSuccess = () => {}, onError = () => {} }) {
  try {
    const user = yield select(selectUser)
    const { data: profilePicture } = isEmpty(user.profilePhoto) ?
      yield call(createProfilePictureService,
        params,
        { userId: user.id }
      ) :
      yield call(updateProfilePictureService,
        params,
        { userId: user.id, photoId: user.profilePhoto.id }
      )

    const database = yield select(selectDatabase)

    if (database) {
      yield call(userService(database).updateProfilePicture, profilePicture?.fileUrl)
    }

    yield call(onSuccess)
  } catch (error) {
    yield put(UserActions.userError(error))
    yield call(onError, error)
  }
}

export default [
  takeLatest(UserTypes.CREATE_USER_STATE_REGISTRATION, createUserStateRegistration),
  takeLatest(UserTypes.LOAD_USER_STATE_REGISTRATIONS, loadUserStateRegistrations),
  takeLatest(UserTypes.LOAD_USER, loadUser),
  takeLatest(UserTypes.LOAD_USER_SUCCESS, loadUserSuccess),
  takeLatest(UserTypes.UPDATE_USER, updateUser),
  takeLatest(UserTypes.SAVE_USER, saveUser),
  takeLatest(UserTypes.SAVE_INITIAL_USER, saveInitialUser),
  takeLatest(UserTypes.DELETE_USER_ORGANIZATION, deleteUserOrganization),
  takeLatest(UserTypes.REVOKE_USER_ACCESS, revokeUserAccess),
  takeLatest(UserTypes.SAVE_USER_PICTURE, saveUserPicture)
]
