import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import required from '@smartcoop/forms/validators/required.validator'

const technicalEvaluationReport = ({ t, props }) => Yup.object().shape({
  period: flow(
    required({ t })
  )(Yup.string()),
  technicianId: Yup.string().nullable(),
  proprietaryId: props?.isTechnical || props?.isAdmin ? Yup.string().nullable() : flow(
    required({ t })
  )(Yup.string()),
  quizIds: flow(
    required({ t })
  )(Yup.string())
})

export default technicalEvaluationReport
