/* eslint-disable no-unused-expressions */
import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import withObservables from '@nozbe/with-observables'
import moment from 'moment'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'

import filter from 'lodash/filter'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isNaN from 'lodash/isNaN'
import map from 'lodash/map'
import omitBy from 'lodash/omitBy'
import orderBy from 'lodash/orderBy'
import toNumber from 'lodash/toNumber'
import toString from 'lodash/toString'

import Grid from '@material-ui/core/Grid'

import { animalService } from '@smartcoop/database/services/animalService'
import { database } from '@smartcoop/database/web-database'
import registerMilkControlSchema from '@smartcoop/forms/schemas/dairyFarm/registerMilkControl.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { getAnimalBirthsById } from '@smartcoop/services/apis/smartcoopApi/resources/animalBirth'
import { LotActions } from '@smartcoop/stores/lot'
import { selectLots } from '@smartcoop/stores/lot/selectorLot'
import { colors } from '@smartcoop/styles'
import { momentBackDateFormat, momentBackDateTimeFormat } from '@smartcoop/utils/dates'
import Button from '@smartcoop/web-components/Button'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import Form from '@smartcoop/web-components/Form'
import InputDate from '@smartcoop/web-components/InputDate'
import InputSelect from '@smartcoop/web-components/InputSelect'
import InputUnit from '@smartcoop/web-components/InputUnit'

import { Container, FormContainer, ButtonsContainer, Item } from './styles'

const RegisterMilkControlForm = (props) => {
  const {
    withoutSubmitButton,
    defaultValues,
    loading,
    onSubmit,
    onCancel,
    animalsList,
    formRef,
    propertyId
  } = props

  const t = useT()
  const dispatch = useDispatch()

  const [animalBirths, setAnimalBirths] = useState([])
  const [lastBirth, setLastBirth] = useState(null)
  const [currentAnimal, setCurrentAnimal] = useState({})
  const [date, setDate] = useState(moment().format(momentBackDateFormat))
  const [automaticLotHandling, setAutomaticLotHandling] = useState(false)

  const lots = useSelector(selectLots)
  const lotsOptions = useMemo(() => map(filter(lots, item => item?.slug === 'em-lactacao'), item => ({ label: `${ item?.code } - ${ item?.name }` ,value: item?.id })), [lots])

  const handleSubmit = useCallback(
    (data) => {
      const currentData = omitBy(data, item => isEmpty(item))
      onSubmit({
        data: {
          ...defaultValues,
          ...currentData,
          id: defaultValues?.id || uuidv4()
        },
        automaticLotHandling
      })
    },
    [defaultValues, onSubmit, automaticLotHandling]
  )

  useEffect(() => {
    dispatch(
      LotActions.loadLots(
        { propertyId }
      )
    )
  }, [propertyId, dispatch, t])

  useEffect(() => {
    formRef?.current?.getFieldRef?.('lotId')?.setValue?.(currentAnimal?.lotId)
  },[currentAnimal, formRef])

  const animals = useMemo(() => {
    const filteredAnimals = filter(animalsList, item => item?.lotSlug === 'em-lactacao' && item?.active)

    return filteredAnimals
  }, [animalsList])


  useEffect(() => {
    setCurrentAnimal(find(animals, item => item?.animalId === defaultValues?.animalId))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[animals, defaultValues])

  const loadAnimalBirths = useCallback(async () => {
    if(currentAnimal) {
      const { data: { data } } = await getAnimalBirthsById({ limit: 9999 }, { propertyId, animalId: currentAnimal?.animalId || currentAnimal?._raw?.animalId || currentAnimal?.animal?.animalId })

      setAnimalBirths(orderBy(data, 'occurrenceDate', 'desc'))
    }
  }, [currentAnimal, propertyId])

  useEffect(() => {
    if(!isEmpty(currentAnimal)) {
      loadAnimalBirths()
    }
  },[currentAnimal, loadAnimalBirths])

  useEffect(() => {
    if(moment(date).diff(moment(currentAnimal?.lastBirth, momentBackDateFormat), 'days') >= 0) {
      formRef?.current?.getFieldRef?.('del')?.setValue?.(toString(moment(date).diff(moment(currentAnimal?.lastBirth, momentBackDateFormat), 'days')))
      setLastBirth(toString(moment(currentAnimal?.lastBirth, momentBackDateFormat).format(momentBackDateFormat)))
    } else {
      const foundBirth = find(animalBirths, birth => moment(birth?.occurrenceDate, momentBackDateTimeFormat).isBefore(moment(date)))
      if(foundBirth) {
        setLastBirth(toString(moment(foundBirth?.occurrenceDate, momentBackDateFormat).format(momentBackDateFormat)))
        const foundDate = moment(foundBirth?.occurrenceDate, momentBackDateTimeFormat)
        formRef?.current?.getFieldRef?.('del')?.setValue?.(toString(moment(date).diff(moment(foundDate, momentBackDateFormat), 'days')))
      } else {
        setLastBirth(toString(moment(currentAnimal?.lastBirth, momentBackDateFormat).format(momentBackDateFormat)))
        formRef?.current?.getFieldRef?.('del')?.setValue?.('')
      }
    }
  },[animalBirths, currentAnimal, date, formRef])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerMilkControlSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <Grid container style={ { justifyContent: 'space-between' } }>
            <Item>
              <InputSelect
                name="animalId"
                label={ t('animal', { howMany: 1 }) }
                defaultValue={ defaultValues?.animalId }
                options={ orderBy(map(animals, item => ({ label: item?.earringCode, value: item?.animalId, animal: item })), item => isNaN(toNumber(item?.label)) ? item?.label : toNumber(item?.label)) }
                onChange={ (_, item) => {
                  setCurrentAnimal(item?.animal?._raw)
                } }
                order={ false }
              />
              <InputSelect
                name="lotId"
                label={ t('lot', { howMany: 1 }) }
                defaultValue={ defaultValues?.lotId }
                options={ lotsOptions }
              />
            </Item>
            <Item>
              <InputDate
                label={ t('date', { howMany: 1 }) }
                name="date"
                fullWidth
                defaultValue={ defaultValues?.date }
                onChange={ setDate }
                pickerProps={
                  {
                    maxDate: moment().format()
                  }
                }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('volume') }
                name="volume"
                fullWidth
                type="float"
                decimalScale={ 1 }
                unit="L"
                defaultValue={ defaultValues?.volume }
              />
            </Item>
            <Item>
              <InputDate
                label={ t('last birth before the registry') }
                name="lastBirth"
                fullWidth
                value={ lastBirth }
                detached
                disabled
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('del') }
                name="del"
                type="integer"
                unit={ t('days') }
                fullWidth
                defaultValue=""
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('ccs') }
                name="ccs"
                type="integer"
                fullWidth
                unit="células/ml"
                defaultValue={ defaultValues?.ccs }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('fat') }
                name="fat"
                type="float"
                fullWidth
                unit="%"
                defaultValue={ defaultValues?.fat }
                decimalScale={ 2 }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('protein') }
                name="protein"
                fullWidth
                type="float"
                unit="%"
                defaultValue={ defaultValues?.protein }
                decimalScale={ 2 }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('lactose') }
                name="lactose"
                fullWidth
                type="float"
                unit="%"
                defaultValue={ defaultValues?.lactose }
                decimalScale={ 2 }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('total solids') }
                name="totalSolids"
                fullWidth
                type="float"
                unit="%"
                defaultValue={ defaultValues?.totalSolids }
                decimalScale={ 2 }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('nul') }
                name="nul"
                unit="mg/dL"
                type="float"
                fullWidth
                defaultValue={ defaultValues?.nul }
                decimalScale={ 2 }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('esd') }
                name="esd"
                type="float"
                unit="%"
                fullWidth
                defaultValue={ defaultValues?.esd }
                decimalScale={ 2 }
              />
            </Item>
            <Item>
              <InputUnit
                label={ t('casein') }
                name="casein"
                type="float"
                unit="%"
                fullWidth
                defaultValue={ defaultValues?.casein }
                decimalScale={ 2 }
              />
            </Item>
            <Item>
              <CheckboxButton
                label="Realiza a troca automática de lote dos animais conforme Controle Leiteiro."
                variant="row"
                checked={ automaticLotHandling }
                onChange={ () => setAutomaticLotHandling(!automaticLotHandling) }
              />
            </Item>
          </Grid>
        </FormContainer>

        {!withoutSubmitButton && (
          <ButtonsContainer>
            <Button
              id="web-cancel-form-button"
              onClick={ onCancel }
              color={ colors.white }
              disabled={ loading }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="web-save-form-button"
              onClick={ () => formRef.current.submit() }
              disabled={ loading }
              style={ { marginRight:10, marginLeft: 10 } }
            >
              <I18n>save</I18n>
            </Button>
          </ButtonsContainer>
        )}
      </Form>
    </Container>
  )
}

RegisterMilkControlForm.propTypes = {
  loading: PropTypes.bool,
  propertyId: PropTypes.string,
  animalsList: PropTypes.array,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  withoutSubmitButton: PropTypes.bool,
  defaultValues: PropTypes.object,
  formRef: PropTypes.object.isRequired
}

RegisterMilkControlForm.defaultProps = {
  loading: false,
  propertyId: null,
  animalsList: [],
  onSubmit: () => {},
  defaultValues: {},
  onCancel: () => {},
  withoutSubmitButton: false
}

const enhance = withObservables(['propertyId'], ({ propertyId }) => ({
  animalsList: animalService(database).observeAnimals(propertyId)
}))

const EnhancedRegisterMilkControlForm = enhance(RegisterMilkControlForm)

export default EnhancedRegisterMilkControlForm