import React, { useRef, useMemo, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  erlenmeyer,
  eyeOpened
} from '@smartcoop/icons'
import { getSoilAnalysisSilasData } from '@smartcoop/services/apis/smartcoopApi/resources/soilAnalysis'
import { useSnackbar } from '@smartcoop/snackbar'
import { FieldActions } from '@smartcoop/stores/field'
import { selectUserDocument } from '@smartcoop/stores/user/selectorUser'
import colors from '@smartcoop/styles/colors'
import { momentFriendlyDateFormat, momentBackDateTimeZoneFormat } from '@smartcoop/utils/dates'
import Badge from '@smartcoop/web-components/Badge'
import DataTable from '@smartcoop/web-components/DataTable'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'

import LinkAnalysisModal from '../LinkAnalysisModal'
import VideoModal from '../VideoModal/VideoModal'
import { Content } from './styles'

const SilasDataModal = (props) => {
  const { id, open, analysisOptions, loadFields } = props
  const t = useT()
  const tableRef = useRef(null)
  const { createDialog } = useDialog()
  const userDocument = useSelector(selectUserDocument)
  const dispatch = useDispatch()
  const snackbar = useSnackbar()

  const columns = useMemo(
    () => [
      {
        title: t('area registry'),
        field: 'matricula_area',
        align: 'left'
      },
      {
        title: t('sample code'),
        field: 'id_smartcoop',
        align: 'left'
      },
      {
        title: t('sample number'),
        field: 'n_amostra',
        align: 'left'
      },
      {
        title: t('report date'),
        field: 'data_laudo',
        align: 'left',
        render: (row) => !isEmpty(row?.data_laudo) ? moment(row?.data_laudo, 'DD/MM/YYYY  HH:mm:ss').format(momentFriendlyDateFormat) : ' - '
      },
      {
        title: t('status'),
        field: 'status',
        align: 'center',
        render: (row) => (
          <Badge
            backgroundColorBadge={ !isEmpty(row?.laudo) ? '#289f30' : '#199cd8' }
            colorBadge={ !isEmpty(row?.laudo) ? '#289f30' : '#199cd8' }
          >
            <I18n>{!isEmpty(row?.laudo) ? t('liberated') : t('in analysis')}</I18n>
          </Badge>
        )
      }

    ], [t]
  )

  const linkToAnalysis = useCallback((row) => {
    createDialog({
      id: 'link-analysis-modal',
      Component: LinkAnalysisModal,
      props: {
        analysisOptions,
        defaultAnalysis: find(analysisOptions, item => item?.item?.analysisCode === row?.id_smartcoop)?.value,
        onConfirm: (analysis) => {
          dispatch(FieldActions.updateFieldAnalysis({
            ...analysis,
            analysisResultDate: moment(row?.data_laudo, 'DD/MM/YYYY  HH:mm:ss').format(momentBackDateTimeZoneFormat),
            results: {
              'argila': row?.argila,
              'areia': row?.areia,
              'silte': row?.silte,
              'ph': row?.ph_h2o,
              'indice_smp': row?.indice_smp,
              'fosforo': row?.p_fosforo,
              'potassio': row?.k_potassio,
              'materia_organica': row?.mo_materia_organica,
              'aluminio': row?.al_aluminio,
              'calcio': row?.ca_calcio,
              'magnesio': row?.mg_magnesio,
              'hidrogenio_aluminio': row?.h_al_hidrogenio_aluminio,
              'ctc': row?.ctc,
              'saturacao_bases': row?.saturacao_de_bases,
              'saturacao_aluminio': row?.saturacao_de_aluminio,
              'enxofre': row?.s_enxofre,
              'zinco': row?.zn_zinco,
              'cobre': row?.cu_cobre,
              'boro': row?.b_boro,
              'manganes': row?.mn_manganes,
              'ferro': row?.fe_ferro
            }
          }, analysis?.id, () => {
            loadFields()
            snackbar.success(
              t('your analysis was linked')
            )
          }))

        }
      }
    })
  }, [analysisOptions, createDialog, dispatch, loadFields, snackbar, t])

  const viewData = useCallback((data) => {
    createDialog({
      id: 'view-data-modal',
      Component: VideoModal,
      props: {
        fileUrl: data?.laudo,
        name: 'Resultado do laboratório'
      }
    })
  }, [createDialog])

  const actions = useMemo(
    () => [
      (row) => ({
        position: 'row',
        onClick: () => linkToAnalysis(row),
        disabled: !row?.data_laudo,
        tooltip: t('link results'),
        iconButtonProps: {
          id: 'link-analysis',
          variant: 'outlined',
          size: 'small',
          color: 'black',
          style: {
            width: 120,
            height: 30,
            fontSize: 12,
            fontWeight: 600,
            borderRadius: 5,
            color: colors.black
          }
        },
        icon: () => (
          <>
            <Icon icon={ erlenmeyer } size={ 14 } />
            <I18n style={ { fontSize: 12 } }>link results</I18n>
          </>
        )
      }),
      (row) => ({
        position: 'row',
        onClick: () => viewData(row),
        disabled: !row?.data_laudo,
        tooltip: t('view'),
        iconButtonProps: {
          id: 'view-analysis',
          variant: 'outlined',
          size: 'small',
          color: 'black',
          style: {
            width: 100,
            height: 30,
            fontSize: 12,
            fontWeight: 600,
            borderRadius: 5,
            color: colors.black
          }
        },
        icon: () => (
          <>
            <Icon icon={ eyeOpened } size={ 14 } style={ { marginRight: 4 } }/>
            <I18n style={ { fontSize: 12 } }>view report</I18n>
          </>
        )
      })
    ],
    [linkToAnalysis, t, viewData]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      disableFullScreen
      maxWidth='lg'
      fullWidth
      disableEsc
      headerProps={ {
        disableTypography: true
      } }
      title={ t('lab analysis results') }
      contentContainerStyle={ { padding: 0 } }
    >
      <>

        <Content>
          <DataTable
            tableRef={ tableRef }
            columns={ columns }
            data={  getSoilAnalysisSilasData }
            params={ { cpf: userDocument } }
            actions={ actions }
            id="silas-data-table"
            hidePagination
            hasPagination={ false }
          />
        </Content>
      </>
    </Modal>
  )
}

SilasDataModal.propTypes = {
  id: PropTypes.string.isRequired,
  analysisOptions: PropTypes.array.isRequired,
  loadFields: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
}

SilasDataModal.defaultProps = {
}

export default SilasDataModal
