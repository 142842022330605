import React, { useState, useCallback } from 'react'

import PropTypes from 'prop-types'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import Button from '@smartcoop/web-components/Button'
import InputSelect from '@smartcoop/web-components/InputSelect'
import Modal from '@smartcoop/web-components/Modal'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'

import { Container, MainText } from './styles'

const LinkAnalysisModal = (props) => {
  const {
    open,
    handleClose,
    id,
    analysisOptions,
    defaultAnalysis,
    onConfirm
  } = props

  const { createDialog } = useDialog()

  const t = useT()

  const [analysis, setAnalysis] = useState(defaultAnalysis)

  const saveLink = useCallback(() => {
    createDialog({
      id: 'confirm-link',
      Component: ConfirmModal,
      props: {
        onConfirm: () => {
          onConfirm(find(analysisOptions, item => item?.value === analysis)?.item)
          handleClose()
        },
        textWarning: t('are you sure you want to link this analysis')
      }
    })
  }, [analysis, analysisOptions, createDialog, handleClose, onConfirm, t])

  return (
    <Modal
      id={ id }
      open={ open }
      title={ <I18n>link analysis</I18n> }
      maxWidth="sm"
      fullWidth
      disableFullScreen
      FooterComponent={
        () => (
          <>
            <Button
              id="close"
              onClick={ handleClose }
              variant="outlined"
              style={ { marginRight: 7 } }
            >
              <I18n>cancel</I18n>
            </Button>
            <Button
              id="save"
              onClick={ saveLink }
              color="black"
              style={ { marginLeft: 7 } }
              disabled={ isEmpty(analysis) }
            >
              <I18n>confirm</I18n>
            </Button>
          </>
        )
      }
    >
      <Container>
        <MainText>
          <I18n>choose the desired analysis</I18n>
        </MainText>

        <InputSelect
          label={ t('select') }
          options={ analysisOptions }
          style={ { paddingBottom: 10, paddingRight: 5 } }
          clearable="true"
          value={ analysis }
          detached
          onChange={ setAnalysis }
        />
      </Container>
    </Modal>
  )
}

LinkAnalysisModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  analysisOptions: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  defaultAnalysis: PropTypes.string,
  onConfirm: PropTypes.func
}

LinkAnalysisModal.defaultProps = {
  onConfirm: () => {},
  defaultAnalysis: null
}

export default LinkAnalysisModal
