import api from '../api'

export const getUserStateRegistrations = (params) => api.get('/v1/state-registrations/user', { params })

export const getStateRegistrationsByUserId = (params, { userId }) => api.get(`/v1/state-registrations/user/${ userId }`, { params })

export const createStateRegistration = (params) => api.post('/v1/state-registrations/user', params)

export const editStateRegistration = (params, { stateRegistrationId }) => api.patch(`/v1/state-registrations/${ stateRegistrationId }/user`, params)

