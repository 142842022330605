import moment from 'moment/moment'
import * as Yup from 'yup'

import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/isEmpty'
import toString from 'lodash/toString'

import date from '@smartcoop/forms/validators/date.validator'
import maxDate from '@smartcoop/forms/validators/maxDate.validator'
import maxNumberValue from '@smartcoop/forms/validators/maxNumberValue.validator'
import minNumberValue from '@smartcoop/forms/validators/minNumberFloatValue.validator'
import required from '@smartcoop/forms/validators/required.validator'

const propertyTechnicalVisit = ({ t, props }) => Yup.object().shape({
  dateVisit: flow(
    date({ t }),
    maxDate({ t, maxReceiptDate: moment().format('YYYY-MM-DD') }),
    required({ t })
  )(Yup.string()),

  observations: Yup.string(),

  hour: flow(
    required({ t })
  )(Yup.string().matches(/^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/, t('invalid hour'))),

  visitMode: flow(
    required({ t })
  )(Yup.string()),

  visitGoalId: flow(
    required({ t })
  )(Yup.string()),

  visitCode: Yup.string(),

  kmControl: Yup.object().shape({
    vehicleId: Yup.string(),

    startMileage: isEmpty(toString(props?.maxKm)) ? Yup.string() : flow(
      minNumberValue({ t, field: t('start mileage below vehicle current mileage'), limit: props?.maxKm })
    )(Yup.string()) ,

    endMileage: Yup.string()
      .when('startMileage', (startMileage, schema) =>
        startMileage
          ? schema.required(t('End mileage is required'))
            .test('end-greater-start', t('end mileage cant be smaller than start mileage'), function (value) {
              const { startMileage: startMileageValue } = this.parent
              return value && parseFloat(value) > parseFloat(startMileageValue)
            })
          : schema
      ),

    traveledMileage: Yup.string(),

    dirtRoadMileage: Yup.string()
      .when('traveledMileage', (traveledMileage, schema) =>
        traveledMileage
          ? schema.required(t('this field is required')).test('dirt-less-traveled', t('dirt road mileage cannot be grater than the total'), function (value) {
            const { traveledMileage: traveledMileageValue } = this.parent
            return !value || (traveledMileageValue && (parseFloat(value) <= parseFloat(traveledMileageValue)))
          }) : schema),

    mileageOnAsphalt: Yup.string()
  }),
  fields: Yup.array()
    .of(
      Yup.object().shape({
        aditionalInformations: Yup.object().shape({
          climateEvent: Yup.string().nullable(),
          phenologicalStage: Yup.string().nullable(),
          stage: Yup.string().nullable(),
          numberOfPlants: Yup.string().nullable()
        }).nullable(),

        estimateUnity: Yup.string().nullable(),

        productivityEstimate:  Yup.string().when('estimateUnity', {
          is: 'kg/ha',
          then: flow(
            required({ t }),
            minNumberValue({ t, field: t('productivity in this unit'), limit: 999 })
          )(Yup.string()),
          otherwise: Yup.string().when('estimateUnity', {
            is: 'sacas/ha',
            then: flow(
              required({ t }),
              maxNumberValue({ t, name: t('productivity in this unit'), customSizeMessage: '999 sacas', max: '999' })
            )(Yup.string()),
            otherwise: Yup.string().nullable()
          })
        })
      })
    )
})

export default propertyTechnicalVisit
