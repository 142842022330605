import React, { useCallback, useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'


import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'
import isString from 'lodash/isString'
import map from 'lodash/map'

import List from '@material-ui/core/List'

import I18n from '@smartcoop/i18n'
import { FieldActions } from '@smartcoop/stores/field'
import { PropertyActions } from '@smartcoop/stores/property'
import {
  selectPropertyPendingManagements,
  selectReloadData
} from '@smartcoop/stores/property/selectorProperty'
import { isValidUUid } from '@smartcoop/utils/formatters'
import Loader from '@smartcoop/web-components/Loader'
import Management from '@smartcoop/web-components/Management'
import { useRoutes } from '@smartcoop/web-containers/routes/authenticated'

import { EmptyState } from './styles'

const PropertyPendingManagementsList = (props) => {
  const { property } = props

  const history = useHistory()
  const { routes } = useRoutes()
  const dispatch = useCallback(useDispatch(), [])
  const mounted = useRef(false)

  const [loading, setLoading] = useState(false)

  const propertyPendingManagements = useSelector(
    selectPropertyPendingManagements
  )
  const reloadData = useSelector(selectReloadData)

  useEffect(() => {
    if (property?.id || reloadData) {
      setLoading(true)
      dispatch(
        PropertyActions.loadPropertyPendingManagements(
          property?.id,
          () => mounted.current && setLoading(false),
          () => mounted.current && setLoading(false)
        )
      )
    }
  }, [dispatch, property, reloadData])

  useEffect(() => {
    mounted.current = true
  }, [])

  useEffect(
    () => () => {
      mounted.current = false
    },
    []
  )


  const handleCropClick = useCallback(
    (management) => {
      dispatch(FieldActions.setCurrentField({
        ...management?.growingSeason?.fieldData,
        polygonCoordinates: isString(management?.growingSeason?.fieldData?.polygonCoordinates) ? JSON.parse(management?.growingSeason?.fieldData?.polygonCoordinates) : management?.growingSeason?.fieldData?.polygonCoordinates
      }))
      dispatch(FieldActions.setFieldNavigationData({ fieldId: management?.growingSeason?.fieldId, childrenPolygonId: management?.growingSeason?.childrenPolygonId && isValidUUid(management?.growingSeason?.childrenPolygonId) ? management?.growingSeason?.childrenPolygonId : management?.growingSeason?.childrenPolygonId && management?.growingSeason?.childrenPolygonId.slice(0, -2), growingSeasonId: management?.growingSeason?.id, defaultManagement: management?.id }))
      history.push(routes.fieldDetails.path.replace(':fieldId', management?.growingSeason?.fieldId))
    },
    [dispatch, history, routes]
  )

  return loading && isEmpty(propertyPendingManagements) ? (
    <Loader width={ 100 } />
  ) : (
    <>
      {isEmpty(propertyPendingManagements) ? (
        <I18n as={ EmptyState }>no found scheduled managements</I18n>
      ) : (
        <List style={ { padding: 0 } }>
          {map(propertyPendingManagements, (cropManagement) => (
            <Management
              key={ cropManagement?.id }
              date={ cropManagement?.predictedDate }
              managementType={ cropManagement?.operation?.name }
              done={ cropManagement?.realizationDate }
              cropManagement={ cropManagement }
              fieldName={ cropManagement.growingSeason?.field?.fieldName }
              onClick={ handleCropClick }
            />
          ))}
        </List>
      )}
    </>
  )
}

PropertyPendingManagementsList.propTypes = {
  property: PropTypes.object
}

PropertyPendingManagementsList.defaultProps = {
  property: {}
}

export default PropertyPendingManagementsList
