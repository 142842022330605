import React, { useState, useEffect, useCallback } from 'react'

import PropTypes from 'prop-types'

import { edit } from '@smartcoop/icons'
import { searchCityById } from '@smartcoop/services/apis/ibgeApi'
import { formatCep } from '@smartcoop/utils/formatters'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'

import {
  ItemContainer,
  MainTitle,
  Badge
} from './styles'

export default function StateRegistrationsItemFragment({
  stateRegistration,
  handleOpenEditModal,
  t
}) {

  const [cityData, setCityData] = useState({})

  const getCity = useCallback(async() => {
    const currentCity = await searchCityById(stateRegistration?.city)
    setCityData(currentCity)
  }, [stateRegistration])

  useEffect(() => {
    getCity()
  }, [getCity])

  return (
    <ItemContainer>
      <div style={ { display: 'flex', flex: 1, flexDirection: 'column' } }>
        <MainTitle>
          <span style={ { marginRight: 10, marginBottom: 10 } }>{ stateRegistration.stateRegistration }</span>
        </MainTitle>
        <div style={ { display: 'flex', flexDirection: 'row', flex: 1 } }>
          <MainTitle style={ { display: 'flex', flex: 1 } }>
            <div>{ cityData?.nome || stateRegistration?.city } - { stateRegistration?.state }</div>
            <div>{`${ stateRegistration?.street }, ${ stateRegistration?.district }`}</div>
          </MainTitle>
          <div style={ { display: 'flex', flex: 1 } }>{formatCep(stateRegistration?.postalCode)}</div>
        </div>
      </div>
      <div style={ { display: 'flex', flexDirection: 'row' } }>
        <Badge
          backgroundColorBadge={ stateRegistration?.terminationDate ? '(216, 27, 96, 0.1)' : '(40, 159, 48, 0.1)' }
          colorBadge={ stateRegistration?.terminationDate ? '(216, 27, 96)' : '(40, 159, 48)' }
        >
          <span>{stateRegistration?.terminationDate ? t('inactive', { gender: 'female' }) : t('active', { gender: 'female' })}</span>
        </Badge>
        <IconButton onClick={ handleOpenEditModal }>
          <Icon icon={ edit } size={ 14 } />
        </IconButton>
      </div>
    </ItemContainer>
  )
}

StateRegistrationsItemFragment.propTypes = {
  stateRegistration: PropTypes.object.isRequired,
  handleOpenEditModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}
