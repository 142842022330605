import * as Yup from 'yup'

import flow from 'lodash/fp/flow'

import date from '@smartcoop/forms/validators/date.validator'
import required from '@smartcoop/forms/validators/required.validator'

const registerInsemination = ({ t, props }) => Yup.object().shape({
  animalsId: Yup.string(),
  inseminationDate: flow(
    date({ t }),
    required({ t })
  )(Yup.string()),
  inseminationType: Yup.string(),
  inseminatorId: Yup.string(),
  bullId: Yup.string(),
  embryoBull: props?.isTypeTe ? flow(
    required({ t })
  )(Yup.string()) : Yup.string(),
  embryoMother: props?.isTypeTe ? flow(
    required({ t })
  )(Yup.string()) : Yup.string(),
  inseminationValue: Yup.string()
})

export default registerInsemination
