import find from 'lodash/find'

import { AVAILABLE_MODULES } from '@smartcoop/stores/module'
import { selectCurrentModule } from '@smartcoop/stores/module/selectorModule'
import { selectFamilyGroupAccess, selectFamilyGroupAccessCanWrite } from '@smartcoop/stores/property/selectorProperty'
import { selectTechnicalWrite } from '@smartcoop/stores/technical/selectorTechnical'


export const selectUser = state => state.user.data

export const selectUserStateRegistrations = state => state.user.stateRegistrations

export const selectUserExtraAttributes = state => {
  const extraAttributes = state.user.data?.extraAttributes ? JSON.parse(state.user.data?.extraAttributes) : {}
  return {
    ...extraAttributes,
    enabledCosts: true,
    enabledBot: true
  }
}

export const selectUserDocument = state => state.user.data?.document

export const selectUserId = state => state.user?.data?.id

export const selectUserHasCCGL = state => find(state.user?.data?.organizationsUser, item => item?.organization?.companyDocument === '88933114000135')

export const selectHasProfileRTC = (state) => !!find(state.user?.data?.organizationsUser, item => item?.organization?.tradeName === 'RTC')

export const selectHasProfileSmartcoop = (state) => !!find(state.user?.data?.organizationsUser, item => item?.organization?.tradeName === 'SMARTCOOP')

export const selectUserCanWrite = state => {
  const currentModule = selectCurrentModule(state)

  const familyGroupAccess = selectFamilyGroupAccess(state)
  const familyGroupAccessCanWrite = selectFamilyGroupAccessCanWrite(state)

  switch (currentModule) {
    case AVAILABLE_MODULES.technical:
      return selectTechnicalWrite(state)
    default:
      return !(familyGroupAccess && !familyGroupAccessCanWrite)
  }
}
