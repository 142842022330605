/* eslint-disable func-names */
/* eslint-disable react/prop-types */
import React, { useMemo, useRef, useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'


import trimMask from '@meta-awesome/functions/src/trimMask'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import includes from 'lodash/includes'
import isEmpty from 'lodash/isEmpty'
import toString from 'lodash/toString'

import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { selectTechnicalVehicles } from '@smartcoop/stores/technical/selectorTechnical'
import { formatKebabCase , formatNumber, formatPlate } from '@smartcoop/utils/formatters'
import Button from '@smartcoop/web-components/Button'
import DataTable from '@smartcoop/web-components/DataTable'
import InputSearchDebounced from '@smartcoop/web-components/InputSearchDebounced'
import Modal from '@smartcoop/web-components/Modal'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'

import RegisterTechnicalVehiclesModal from '../RegisterTechnicalVehiclesModal'
import { Badge } from './styles'

const RegisterVehiclesModal = ({ id, open }) => {

  const tableRef = useRef()
  const t = useT()
  const { createDialog } = useDialog()
  const dispatch = useDispatch()
  const snackbar = useSnackbar()

  const technicalVehicles = useSelector(selectTechnicalVehicles)

  const [debouncedFilterText, setDebouncedFilterText] = useState('')

  const onChangeSearchFilter = useCallback((value) => {
    setDebouncedFilterText(value)
  }, [])

  const loadTechnicalVehicles = useCallback(() => {
    dispatch(TechnicalActions.loadTechnicalVehicles({ limit:9999 }))
  }, [dispatch])

  const columns = useMemo(
    () => [
      {
        title: t('plate'),
        field: 'plate',
        sorting: true,
        defaultSort: 'asc',
        render: row => formatPlate(trimMask(row?.plate))
      },
      {
        title: t('model'),
        sorting: true,
        field: 'brand',
        render: (row) => `${ row?.brand?.description } ${ row?.model }`
      },
      {
        title: t('year', { howMany: 1 }),
        sorting: true,
        field: 'year'
      },
      {
        title: t('last km'),
        sorting: true,
        field: 'maxKm',
        align: 'right',
        render: row => formatNumber(row?.maxKm)
      },
      {
        title: t('status'),
        field: 'active',
        sorting: true,
        render: (row) => (
          <Badge
            backgroundColorBadge={ row?.active ? '(40, 159, 48, 0.1)' : '(216, 27, 96, 0.1)'  }
            colorBadge={ row?.active ? '(40, 159, 48, 1)': '(216, 27, 96, 1)'  }
          >
            {row?.active ? t('active', { gender: 'male' }) : t('inactive', { gender: 'male' }) }
          </Badge>
        )
      }

    ],
    [t]
  )

  const handleDeleteClick = useCallback(
    (_, row = null) =>{
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            dispatch(TechnicalActions.deleteTechnicalVehicle(
              row?.id,
              () => {
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'male',
                    this: t('vehicle')
                  })
                )
                loadTechnicalVehicles()
              },
              loadTechnicalVehicles
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'male',
            this: t('vehicle')
          })
        }
      })
    },
    [createDialog, dispatch, loadTechnicalVehicles, snackbar, t]
  )

  const handleEditClick = useCallback(
    (_, row = null) => {
      createDialog({
        id: 'technical-vehicle-modal',
        Component: RegisterTechnicalVehiclesModal,
        props: {
          onSubmit: loadTechnicalVehicles,
          defaultValues: row
        }
      })
    },
    [createDialog, loadTechnicalVehicles]
  )

  useEffect(() => {
    loadTechnicalVehicles()
  }, [loadTechnicalVehicles])

  return (
    <Modal
      id={ id }
      open={ open }
      maxWidth="xl"
      fullWidth
      disableEsc
      title={
        <div style={ { display: 'flex', flexDirection: 'row' } }>
          <I18n>manage vehicles</I18n>
        </div>
      }
      contentContainerStyle={ { padding: 0, minWidth: 300 } }
    >
      <div style={ { display: 'flex', flex: 1, flexDirection: 'column', flexWrap: 'wrap', padding: '30px' } }>
        <div style={ { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 } }>
          <div style={ { width: '50%' } }>
            <InputSearchDebounced onChange={ onChangeSearchFilter } />
          </div>
          <Button
            id="save"
            color="secondary"
            onClick={ handleEditClick }
            style={ { maxWidth: 100 } }
          >
            {t('register')}
          </Button>
        </div>
        <DataTable
          columns={ columns }
          data={
            filter(technicalVehicles, item =>
              isEmpty(debouncedFilterText)
                ? true
                : includes(formatKebabCase(item?.plate), formatKebabCase(debouncedFilterText))
                || includes(formatKebabCase(item?.model), formatKebabCase(debouncedFilterText))
                || includes(formatKebabCase(item?.brand?.description), formatKebabCase(debouncedFilterText))
                || includes(toString(item?.year), formatKebabCase(debouncedFilterText)))
          }
          tableRef={ tableRef }
          onEditClick={ handleEditClick }
          onDeleteClick={ handleDeleteClick }
          conditionToDelete={ row => isEmpty(toString(row?.maxKm)) }
        />
      </div>
    </Modal>
  )}

RegisterVehiclesModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired
}

RegisterVehiclesModal.defaultProps = {
}

export default RegisterVehiclesModal