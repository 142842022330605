import React, { useMemo, useCallback, useState, useEffect, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import join from 'lodash/join'
import map from 'lodash/map'
import orderBy from 'lodash/orderBy'

import { useDialog } from '@smartcoop/dialog'
import { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { selectTechnicalVisits } from '@smartcoop/stores/technical/selectorTechnical'
import { colors } from '@smartcoop/styles'
import CheckboxButton from '@smartcoop/web-components/CheckboxGroup/CheckboxButton'
import DataTable from '@smartcoop/web-components/DataTable'
import ConfirmModal from '@smartcoop/web-components/Modal/ConfirmModal'
import VisitModeStatus from '@smartcoop/web-components/VisitModeStatus'
import { Badge } from '@smartcoop/web-components/VisitModeStatus/styles'
import PropertyTechnicalVisitModal from '@smartcoop/web-containers/modals/technical/PropertyTechnicalVisitModal'
import PropertyTechnicalVisitScheduleModal from '@smartcoop/web-containers/modals/technical/PropertyTechnicalVisitScheduleModal'

import { CheckboxRow } from './styles'

const TechnicalVisitList = forwardRef(({ filters, createTechnicalVisit, createTechnicalVisitSchedule, loading: externalLoading }, tableRef) => {
  const t = useT()
  const { createDialog, removeDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const snackbar = useSnackbar()

  const technicalVisits = useSelector(selectTechnicalVisits)
  const [loading, setLoading] = useState(false)

  const columns = useMemo(
    () => [
      {
        title: 'Data e Hora',
        field: '[0].sortDate',
        sorting: true,
        defaultSort: 'desc',
        align: 'left',
        render: (row) => moment(row?.[0]?.visitDateTime || row?.[0]?.predictedDate, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm')
      },
      {
        title: 'Produtor',
        field: '[0].users[0].name',
        sorting: true,
        align: 'left',
        render: (row) => row?.[0].users?.[0]?.name
      },
      {
        title: 'Propriedade',
        field: '[0].property.name',
        sorting: true,
        align: 'left',
        render: (row) => row?.[0]?.property?.name
      },
      {
        title: 'Talhão',
        field: '[0].field.fieldName',
        sorting: true,
        align: 'left',
        render: (row) => join(map(row, item => item.field?.fieldName), ', ')
      },
      {
        title: 'Notificado',
        field: 'notify',
        align: 'center',
        render: (row) => (
          <CheckboxRow>
            <CheckboxButton
              value={ row?.[0].notify }
              checked={ row?.[0].notify }
              disabled
              style={ { width: '50px' } }
              hideColor
            />
          </CheckboxRow>
        )
      },
      {
        title: 'Visita',
        field: '[0].visitMode',
        align: 'center',
        sorting: true,
        render: (row) => (
          <CheckboxRow>
            <VisitModeStatus
              mode={ row?.[0]?.visitMode }
            />
          </CheckboxRow>
        )
      },
      {
        title: t('status'),
        field: '[0].status',
        align: 'center',
        sorting: true,
        render: (row) => (
          <Badge
            backgroundColorBadge={ row?.[0]?.status === 'agendada' ? '(255, 202, 10, 0.1)' : '(29, 29, 27, 0.1)'  }
            colorBadge={ row?.[0]?.status === 'agendada' ? colors.secondary : colors.darkGrey  }
          >
            {row?.[0]?.status === 'agendada' ? t('scheduled') : t('recommended') }
          </Badge>
        )
      }
    ], [t]
  )

  const filteredTechnicalVisits = useMemo(() => filter(technicalVisits, item => filters?.fieldId === item?.field?.id || filters?.propertyId === item?.property?.id || filters?.proprietaryId === item?.users?.[0]?.id || isEmpty(filters)),[filters, technicalVisits])

  const onListLoad = useCallback(
    () => {
      dispatch(TechnicalActions.loadTechnicalVisits(
        { limit: 999999 },
        () => setLoading(false),
        () => setLoading(false)
      ))
    },
    [dispatch]
  )

  const dataTableOption = useMemo(
    () => ({
      sorting: true
    }),
    []
  )

  const onSuccessEditingTechnicalVisit = useCallback(
    (scheduledToVisited = false) => {
      snackbar.success(scheduledToVisited ? t('your visit was effected') :
        t('your {this} was edited', {
          howMany: 1,
          gender: 'female',
          this: t('technical visit')
        })
      )
      onListLoad()
      removeDialog({ id: 'view-edit-technical-visit' })
    },
    [onListLoad, removeDialog, snackbar, t]
  )

  const openDetailsTechnicalVisit = useCallback(
    (_, row) => {
      createDialog({
        id: row[0]?.status === 'agendada' ? 'view-edit-technical-visit-schedule' : 'view-edit-technical-visit',
        Component: row[0]?.status === 'agendada' ? PropertyTechnicalVisitScheduleModal : PropertyTechnicalVisitModal,
        props: {
          technicalVisit: row,
          disabled: true,
          onSuccess: onSuccessEditingTechnicalVisit,
          onVisit: () => createDialog({
            id: 'view-edit-technical-visit',
            Component: PropertyTechnicalVisitModal,
            props: {
              technicalVisit: row,
              onSuccess: () => onSuccessEditingTechnicalVisit(true)
            }
          })
        }
      })
    },
    [createDialog, onSuccessEditingTechnicalVisit]
  )

  const handleDelete = useCallback(
    (_, row) => {
      createDialog({
        id: 'confirm-delete',
        Component: ConfirmModal,
        props: {
          onConfirm: () => {
            setLoading(true)
            dispatch(TechnicalActions.deleteTechnicalVisit(
              row[0].visitId,
              () => {
                snackbar.success(
                  t('your {this} was deleted', {
                    howMany: 1,
                    gender: 'female',
                    this: t('technical visit')
                  })
                )
                onListLoad()
              }
            ))
          },
          textWarning: t('are you sure you want to delete the {this}?', {
            howMany: 1,
            gender: 'female',
            this: t('technical visit')
          })
        }
      })
    },
    [createDialog, dispatch, onListLoad, snackbar, t]
  )

  useEffect(() => {
    onListLoad()
  }, [onListLoad])

  return(
    <DataTable
      tableRef={ tableRef }
      columns={ columns }
      data={  !isEmpty(filteredTechnicalVisits) && !loading  ? map(groupBy(orderBy([...filteredTechnicalVisits], ['visitDateTime', 'predictedDate'], ['desc', 'desc']), 'visitId'), item => item)  : [] }
      onRowClick={ openDetailsTechnicalVisit }
      onEditClick={ (_, row) => row[0]?.status === 'agendada' ? createTechnicalVisitSchedule(_, row) :  createTechnicalVisit(_, row) }
      options={ dataTableOption }
      onDeleteClick={ handleDelete }
      id="technical-visit-table"
      loading={ loading || externalLoading }
    />
  )
})

TechnicalVisitList.propTypes = {
  filters: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  createTechnicalVisit: PropTypes.func.isRequired,
  createTechnicalVisitSchedule: PropTypes.func.isRequired
}

TechnicalVisitList.defaultProps = {
}

export default TechnicalVisitList
