import currency from 'currency.js'
import format from 'formatar-valores'

import flow from 'lodash/flow'
import replaceFP from 'lodash/fp/replace'
import toLowerFP from 'lodash/fp/toLower'
import trimFP from 'lodash/fp/trim'
import isNumber from 'lodash/isNumber'
import replace from 'lodash/replace'
import size from 'lodash/size'
import toNumberLodash from 'lodash/toNumber'
import upperCase from 'lodash/upperCase'


export const formatCpfCnpj = (num) => format.cpfCnpj(num)

export const formatCep = (num) => format.cep(num)

export const formatTextToPhone = (num) => {
  const phoneArray = num.match(/(\S{2})(\S{5})(\S{4})/)
  return `(${  phoneArray[1]  }) ${  phoneArray[2]  }-${  phoneArray[3] }`
}
export const formatPhone = (num) => format.telefone(num)

export const formatPhoneV2 = (num) => {
  const phone = num.replace(/[^0-9]/g, '')

  if (size(phone) === 11) {
    return phone.replace(/^(\d\d)(\d)(\d{4})(\d{4}).*/, '($1) $2 $3-$4')
  }

  return phone.replace(/^(\d\d)(\d{4})(\d{4}).*/, '($1) $2-$3')
}

export const formatNCM = (num) => replace(num, /^(\d{4})(\d{2})(\d{2})*/, '$1.$2.$3')

export const formatPlate = (plate) => replace(plate, /^([A-Za-z0-9]{3})([A-Za-z0-9]{4})$/, '$1-$2')

export const formatNumber = (num, locale = 'pt-BR') => {
  try {
    if (num === undefined) {
      return 0
    }

    let number = num
    if (!isNumber(number)) {
      number = Number(num)
    }
    return number.toLocaleString(locale)
  } catch (e) {
    console.log('formatNumber error:', e)
    return num
  }
}

export const formatCurrency = (
  amount,
  decimalCount = 2,
  decimal = ',',
  thousands = '.',
  prefix = 'R$ '
) => {
  try {
    const number = isNaN(Math.abs(decimalCount)) ? 2 : decimalCount
    const negativeSign = amount < 0 ? '-' : ''
    const amountToParse = Math.abs(Number(amount) || 0)

    const i = parseInt(amountToParse.toFixed(number), 10).toString()
    const j = i.length > 3 ? i.length % 3 : 0

    return (
      prefix +
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${ thousands }`) +
      (number
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '')
    )
  } catch (e) {
    console.error(`[Format Currency]: ${ e }`)
    return false
  }
}

export const formatNumberNative = (
  amount,
  decimalCount = 2,
  decimal = ',',
  thousands = '.',
  prefix = 'R$ '
) => {
  try {
    const value = amount.toFixed(decimalCount)
    const stringified = `${ value.replace(/\B(?=(\d{3})+(?!\d))/g, ',') }`.replace(',', '.')
    const array = stringified.split('')
    array[stringified.lastIndexOf(thousands)] = decimal
    return `${ prefix }${ array.join('') }`
  } catch (e) {
    console.error(`[Format Number]: ${ e }`)
    return false
  }
}

export const formatCurrencyIntl = (number) => {
  const formattedNumber = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
    number
  )
  return number >= 0 ? ('+ ').concat(formattedNumber) : formattedNumber.replace('-', '- ')
}

export const toNumber = (num = '') => {
  if (!num) {
    return 0
  }

  if (typeof num === 'string') {
    const strNormalized = num.replace('.', '').replace(',', '.')
    return toNumberLodash(strNormalized)
  }

  return num
}

export const constantCase = (str) => upperCase(str).replace(/ /g, '_')

export const formatCurrencyJs = (amount) =>
  currency(amount, { symbol: 'R$ ', separator: '.', decimal: ',' }).format()

export const formatNumberJs = (amount, precision = 2) =>
  currency(amount, { symbol: '', separator: '.', decimal: ',', precision }).format()

export const formatKebabCase = string => flow(
  toLowerFP,
  replaceFP('á', 'a'),
  replaceFP('ã', 'a'),
  replaceFP('â', 'a'),
  replaceFP('ç', 'c'),
  replaceFP('ú', 'u'),
  replaceFP('ó', 'o'),
  replaceFP('õ', 'o'),
  replaceFP('ô', 'o'),
  replaceFP('é', 'e'),
  replaceFP('ê', 'e'),
  replaceFP('(', ''),
  replaceFP(')', ''),
  replaceFP('*', ''),
  replaceFP('º', ''),
  replaceFP('°', ''),
  trimFP,
  replaceFP(/([a-z])([A-Z])/g, '$1-$2'),
  replaceFP(/[\s_]+/g, '-')
)(string)

export const isValidUUid = (str) => {
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi

  return regexExp.test(str)
}

