import React, { useState, useCallback } from 'react'

import PropTypes from 'prop-types'

import includes from 'lodash/includes'
import replace from 'lodash/replace'
import toString from 'lodash/toString'

import I18n from '@smartcoop/i18n'
import { visibility, like } from '@smartcoop/icons'
import { colors } from '@smartcoop/styles'
import Icon from '@smartcoop/web-components/Icon'
import Modal from '@smartcoop/web-components/Modal'

import useStyles, { Row, Content, IconLike } from './styles'

const VideoModal = (props) => {
  const classes = useStyles()
  const { fileUrl, open, handleClose, name, viewType, accessNumber, liked, onPressLike, likes } = props

  const [isLiked, setIsLiked] = useState(liked)
  const [localLikes, setLocalLikes] = useState(likes)

  const onLike = useCallback(() => {
    setIsLiked(!isLiked)
    onPressLike(!isLiked)

    setLocalLikes(isLiked ? localLikes - 1 : localLikes + 1)
  }, [isLiked, localLikes, onPressLike])

  return (
    <Modal
      id
      open={ open }
      onClose={ handleClose }
      title={
        <Row>
          <I18n>{name}</I18n>
        </Row>
      }
      escape
      maxWidth='lg'
      fullWidth
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { padding: 0 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <link
        rel="stylesheet"
        href="https://video-react.github.io/assets/video-react.css"
      />
      {viewType === 'landscape' ? (
        <div>
          <Content>
            <iframe
              height="500"
              src={ replace(fileUrl,'/watch?v=', '/embed/') }
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title={ name }
              onContextMenu={ (e) => e.preventDefault() }
            />
          </Content>
        </div>
      ): (
        <Content>
          <iframe title={ name } src={ !includes(fileUrl, 'https') ? `${ replace(fileUrl, 'http', 'https') }#toolbar=0&statusbar=1` : `${ fileUrl }#toolbar=0&statusbar=1` } width='100%' height='600'/>
        </Content>
      )}
      {onPressLike ? (
        <div style={ { display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'flex-end', alignItems: 'center', padding: 20 } }>
          <div style={ { alignItems: 'center', display: 'flex', flexDirection: 'row', fontSize: 18, fontWeight: 'bold', marginRight: 20 } }>
            {toString(accessNumber)}
            <Icon
              icon={ visibility }
              color='black'
              style={ {
                marginTop: 4,
                marginLeft: 5
              } }
            />
          </div>
          <IconLike onClick={ onLike }>
            <div style={ { marginTop: 4 } }>
              {toString(localLikes)}
            </div>
            <Icon
              icon={ like }
              style={ { color: isLiked ? colors.green : colors.black } }
              size={ 30 }
            />
          </IconLike>
        </div>
      ) : null}
    </Modal>
  )
}

VideoModal.propTypes = {
  viewType: PropTypes.oneOf(['portrait', 'landscape']),
  name: PropTypes.string.isRequired,
  accessNumber: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  open: PropTypes.bool,
  likes: PropTypes.number,
  liked: PropTypes.bool,
  onPressLike: PropTypes.func,
  handleClose: PropTypes.func.isRequired
}

VideoModal.defaultProps = {
  open: false,
  viewType: 'landscape',
  liked: false,
  onPressLike: null,
  likes: 0
}

export default VideoModal
