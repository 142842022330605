import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'


import moment from 'moment/moment'
import PropTypes from 'prop-types'

import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'


import { useDialog } from '@smartcoop/dialog'
import I18n, { useT } from '@smartcoop/i18n'
import {
  pencil,
  signature
  // signature
} from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectAllModules, selectAllSlugs } from '@smartcoop/stores/authentication/selectorAuthentication'
import { selectCurrentModule } from '@smartcoop/stores/module/selectorModule'
import { UserActions } from '@smartcoop/stores/user'
import colors from '@smartcoop/styles/colors'
import {
  momentBackDateFormat,
  momentFriendlyDateFormat
} from '@smartcoop/utils/dates'
import { downloadFromBase64, createFormData } from '@smartcoop/utils/files'
import { formatCpfCnpj, formatPhone } from '@smartcoop/utils/formatters'
import AvatarInput from '@smartcoop/web-components/AvatarInput'
import Button from '@smartcoop/web-components/Button'
import Icon from '@smartcoop/web-components/Icon'
import GoBackIconButton from '@smartcoop/web-components/IconButton/GoBackIconButton'
import VerticalDotsIconButton from '@smartcoop/web-components/IconButton/VerticalDotsIconButton'
import Loader from '@smartcoop/web-components/Loader'
import MenuPopUp from '@smartcoop/web-components/MenuPopUp'
import Popover from '@smartcoop/web-components/Popover'
import { Title } from '@smartcoop/web-containers/layouts/AuthenticatedLayout/theme'
import ChangeSignatureModal from '@smartcoop/web-containers/modals/ChangeSignatureModal/ChangeSignatureModal'
import EditProfileModal from '@smartcoop/web-containers/modals/profile/EditProfileModal'
import RemoveAccessModal from '@smartcoop/web-containers/modals/profile/RemoveAccessModal'

import {
  Container,
  RowContainer,
  FirstRowContainer,
  UpperRowContainer,
  Item,
  AvatarContainer,
  Top,
  InfoContainer,
  AvatarNameContainer,
  Name,
  ButtonContainer
} from './styles'


const UserInfoFragment = (props) => {
  const {
    user: { name, chatNickname, dateOfBirth, email, cellPhone, document: doc, profilePhoto }
  } = props

  const t = useT()
  const snackbar = useSnackbar()
  const { createDialog } = useDialog()
  const dispatch = useCallback(useDispatch(), [])
  const history = useHistory()

  const allModules = useSelector(selectAllModules)
  const allSlugs = useSelector(selectAllSlugs)
  const currentModule = useSelector(selectCurrentModule)

  const [isAvatarLoading, setIsAvatarLoading] = useState(false)
  const [avatarProfilePicture, setAvatarProfilePicture] = useState('')


  useEffect(() => {
    if(!isEmpty(profilePhoto)) {
      setIsAvatarLoading(true)
      getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_PHOTO_USER_ID, fileKey: profilePhoto?.fileKey })
        .then(({ data: { file: data } }) => {
          setAvatarProfilePicture(downloadFromBase64(data))
          setIsAvatarLoading(false)
        }).catch(() => {
          snackbar.error(t('profile avatar load error'))
        })
    }
  }, [profilePhoto, snackbar, t])



  const showEletronicSignatureButton = useMemo(
    () => allModules.some((item) => item.slug === 'commercialization') && find(allSlugs, slug => slug === 'associated-rural-producer'),
    [allModules, allSlugs]
  )

  const formatted = useMemo(
    () => ({
      phone: formatPhone(cellPhone),
      document: formatCpfCnpj(doc),
      dateOfBirth: moment(dateOfBirth, momentBackDateFormat).format(
        momentFriendlyDateFormat
      ),
      chatNickname
    }),
    [cellPhone, doc, dateOfBirth, chatNickname]
  )

  const openEditProfileModal = useCallback(() => {
    createDialog({
      id: 'edit-profile',
      Component: EditProfileModal
    })
  }, [createDialog])

  const openChangeSignatureModal = useCallback(() => {
    createDialog({
      id: 'change-signature',
      Component: ChangeSignatureModal
    })
  }, [createDialog])

  const onSuccessAvatarChange = useCallback(() => {
    dispatch(
      UserActions.loadUser(
        () => {
          setIsAvatarLoading(false)
          snackbar.success(
            t('your {this} was updated', {
              gender: 'female',
              this: t('profile picture', { howMany: 1 })
            })
          )
        },
        () => {
          setIsAvatarLoading(false)
        }
      )
    )
  }, [dispatch, snackbar, t])

  const handleAvatarChange = useCallback(
    (file) => {
      setIsAvatarLoading(true)

      dispatch(
        UserActions.saveUserPicture(
          createFormData([file]),
          () => onSuccessAvatarChange(),
          () => setIsAvatarLoading(false)
        )
      )
    },
    [dispatch, onSuccessAvatarChange]
  )


  const renderAvatar = useMemo(() => isAvatarLoading ? (
    <Loader width={ 120 } style={ { padding: 0 } } />
  ) : (
    <AvatarInput src={ avatarProfilePicture } onChange={ handleAvatarChange } />
  ), [avatarProfilePicture, handleAvatarChange, isAvatarLoading]
  )

  const openRemoveAccessModal = useCallback(() => {
    createDialog({
      id: 'remove-access',
      Component: RemoveAccessModal,
      props: {
      }
    })
  }, [createDialog])

  return (
    <Container>
      {isNull(currentModule) && (
        <UpperRowContainer>
          <GoBackIconButton onClick={ () => history.goBack() } iconColor={ colors.primary } />
          <Title
            style={ {
              lineHeight: 1,
              fontSize: 18
            } }
          >
            <I18n>go back</I18n>

          </Title>
        </UpperRowContainer>
      )}
      <FirstRowContainer>
        <AvatarContainer>{renderAvatar}</AvatarContainer>
        <InfoContainer>
          <Top>
            <AvatarNameContainer>
              <Name>{name}</Name>
            </AvatarNameContainer>
            <ButtonContainer>
              {showEletronicSignatureButton && (
                <Button
                  id="eletronic-signature"
                  color={ colors.white }
                  style={ { marginRight: 10 } }
                  onClick={ openChangeSignatureModal }
                >
                  <>
                    <Icon
                      icon={ signature }
                      size={ 17 }
                      color={ colors.black }
                      style={ { marginRight: 5 } }
                    />
                    <I18n>eletronic signature</I18n>
                  </>
                </Button>
              )}
              <Button
                id="edit-profile"
                color={ colors.white }
                onClick={ openEditProfileModal }
                style={ { marginRight: 10 } }
              >
                <>
                  <Icon icon={ pencil } size={ 17 } style={ { marginRight: 5 } } />
                  <I18n>edit</I18n>
                </>
              </Button>
            </ButtonContainer>
            <Popover
              popoverId="user-menu"
              Component={ VerticalDotsIconButton }
            >
              <MenuPopUp
                options={ [
                  {
                    icon: null,
                    text: t('disable account'),
                    onClick: () => openRemoveAccessModal()
                  }
                ] }
              />
            </Popover>
          </Top>
          <RowContainer>
            <Item><I18n style={ { fontWeight: 'bold' } }>nickname</I18n>: {chatNickname || name }</Item>
          </RowContainer>
          <RowContainer>
            {formatted?.dateOfBirth && (
              <Item>
                <I18n style={ { fontWeight: 'bold' } }>date of birth</I18n>: {formatted.dateOfBirth}
              </Item>
            )}
            <Item><I18n style={ { fontWeight: 'bold' } }>E-mail:</I18n> {email}</Item>
          </RowContainer>
          <RowContainer>
            <Item><I18n style={ { fontWeight: 'bold' } }>CPF/CNPJ:</I18n> {formatted.document}</Item>
            <Item><I18n style={ { fontWeight: 'bold' } }>phone</I18n>: {formatted.phone}</Item>
          </RowContainer>
        </InfoContainer>
      </FirstRowContainer>
    </Container>
  )
}

UserInfoFragment.propTypes = {
  user: PropTypes.object.isRequired
}

export default UserInfoFragment
