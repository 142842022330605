import React, { useRef } from 'react'

import PropTypes from 'prop-types'

import{ useT } from '@smartcoop/i18n'
import Modal from '@smartcoop/web-components/Modal'
import ActivityCostsForm from '@smartcoop/web-containers/forms/digitalProperty/property/ActivityCostsForm'

import { Container } from './styles'

const ActivityCostsModal = (props) => {
  const {
    open,
    id,
    year,
    onExit,
    costs,
    getCosts,
    cropsOptions
  } = props

  const t = useT()
  const fieldCostsFormRef = useRef(null)

  return (
    <Modal id={ id } open={ open } title={ t('registers by activity') } maxWidth='xl' fullWidth disableEsc onExited={ onExit }>
      <Container>
        <ActivityCostsForm
          ref={ fieldCostsFormRef }
          year={ year }
          costs={ costs }
          getCosts={ getCosts }
          cropsOptions={ cropsOptions }
        />
      </Container>
    </Modal>
  )
}

ActivityCostsModal.propTypes = {
  id: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  cropsOptions: PropTypes.array.isRequired,
  costs: PropTypes.array.isRequired,
  open: PropTypes.bool.isRequired,
  onExit: PropTypes.func.isRequired,
  getCosts: PropTypes.func.isRequired
}

ActivityCostsModal.defaultProps = {
}

export default ActivityCostsModal
