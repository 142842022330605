/* eslint-disable no-nested-ternary */
import React, { useCallback, forwardRef, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'


import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import technicalEvaluationReportSchema from '@smartcoop/forms/schemas/property/reports/technicalEvaluationReport.schema'
import { useT } from '@smartcoop/i18n'
import { getTechnicalPortfolio } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { getUsers } from '@smartcoop/services/apis/smartcoopApi/resources/user'
import { selectModuleIsTechnical, selectModuleIsAdmin } from '@smartcoop/stores/module/selectorModule'
import { TechnicalActions } from '@smartcoop/stores/technical'
import { selectCurrentOwnerId, selectTechnicalForms } from '@smartcoop/stores/technical/selectorTechnical'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import Form from '@smartcoop/web-components/Form'
import InputDateRange from '@smartcoop/web-components/InputDateRange'
import InputSelect from '@smartcoop/web-components/InputSelect'

import { Container } from './styles'

const TechnicalEvaluationReportForm = forwardRef((props, formRef) => {
  const { onSubmit } = props
  const t = useT()
  const dispatch = useDispatch()

  const currentOwnerId = useSelector(selectCurrentOwnerId)
  const isTechnical = useSelector(selectModuleIsTechnical)
  const technicalForms = useSelector(selectTechnicalForms)
  const isAdmin = useSelector(selectModuleIsAdmin)
  const user = useSelector(selectUser)

  const [technicianId, setTechnicianId] = useState(null)
  const [technicalPortfolioData, setTechnicalPortfolioData] = useState(null)
  const [techniciansData, setTechniciansData] = useState([])

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data
      })
    },
    [onSubmit]
  )

  const getTechncialPortfolioData = useCallback(async () => {
    if(isAdmin) {
      const { data } = await getUsers({ limit: 99999, profileId: 6 })
      setTechniciansData(data)
    } else {
      const { data: { data } } = await getTechnicalPortfolio({ limit: 99999 })
      setTechnicalPortfolioData(data)
    }
  }, [isAdmin])

  const getAdminPortfolioData = useCallback(async () => {
    const { data: { data } } = await getTechnicalPortfolio({ limit: 99999, technicianId  })
    setTechnicalPortfolioData(data)
  }, [technicianId])

  useEffect(() => {
    getAdminPortfolioData()
  }, [getAdminPortfolioData, technicianId])

  useEffect(() => {
    dispatch(TechnicalActions.loadTechnicalForms())
    if(isTechnical || isAdmin) {
      getTechncialPortfolioData()
    }
  },[dispatch, getTechncialPortfolioData, isAdmin, isTechnical])


  const producerOptions = useMemo(() => !isTechnical && !isAdmin ? [{ label: user?.name, value: user?.id }] : map(filter(technicalPortfolioData, item => !isEmpty(item.proprietaryTechnician)), item => ({ value: item.id, label: item.name })), [isAdmin, isTechnical, technicalPortfolioData, user.id, user.name])

  const techniciansOptions = useMemo(() => map(techniciansData, item => ({ value: item.id, label: item.name })), [techniciansData])

  const technicalFormsOptions = useMemo(() => map(filter(technicalForms, item => item?.modelSlug === 'pqfl'), item => ({ label: item?.name, value: item?.id })),[technicalForms])

  return (
    <Container>
      <Form
        style={ { display: 'flex', flex: 1, flexDirection: 'column' } }
        ref={ formRef }
        schemaConstructor={ technicalEvaluationReportSchema }
        schemaProps={ { isTechnical, isAdmin } }
        onSubmit={ handleSubmit }
      >
        <InputDateRange
          label="Periodo"
          name="period"
          fullWidth
        />
        <InputSelect
          label={ t('report type') }
          name="quizIds"
          options={ technicalFormsOptions }
          defaultValue=""
          multiple
        />
        {isAdmin ? (
          <InputSelect
            label={ t('technician', { howMany: 1, gender: 'male' }) }
            name="technicianId"
            options={ techniciansOptions }
            onChange={ ({ target }) => setTechnicianId(target.value) }
            defaultValue={ null }
          />
        ) : null}
        <InputSelect
          label={ t('producer', { howMany: 1, gender: 'male' }) }
          name="proprietaryId"
          options={ producerOptions }
          defaultValue={ isAdmin ? null : isTechnical ? currentOwnerId || null : user?.id }
          disabled={ currentOwnerId || (!isTechnical && !isAdmin) }
        />
      </Form>
    </Container>
  )
})

TechnicalEvaluationReportForm.propTypes = {
  onSubmit: PropTypes.func
}

TechnicalEvaluationReportForm.defaultProps = {
  onSubmit: () => {}
}

export default TechnicalEvaluationReportForm
