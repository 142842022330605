/* eslint-disable react/no-danger */
import React, { useCallback, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment/moment'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import flatMap from 'lodash/flatMap'
import flow from 'lodash/fp/flow'
import replaceFP from 'lodash/fp/replace'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import toUpper from 'lodash/toUpper'

import { useT } from '@smartcoop/i18n'
import { emptyFilter } from '@smartcoop/icons'
import { getBucketFile } from '@smartcoop/services/apis/smartcoopApi/resources/bucket'
import { selectUser } from '@smartcoop/stores/user/selectorUser'
import technicalEvaluationReport from '@smartcoop/styles/assets/docs/technicalEvaluationReport'
import technicalEvaluationReportNoProducer from '@smartcoop/styles/assets/docs/technicalEvaluationReportNoProducer'
import { FORM_INPUT_TYPES } from '@smartcoop/utils/constants'
import { momentFriendlyDateTimeFormat } from '@smartcoop/utils/dates'
import { downloadFromBase64 } from '@smartcoop/utils/files'
import EmptyState from '@smartcoop/web-components/EmptyState'

import { Container, CardContainer } from './styles'

const TechnicalEvaluationReport = (props) => {

  const { data, accessToken } = props
  const frameRef = useRef([])
  const user = useSelector(selectUser)

  const t = useT()

  const [height, setHeight] = useState({})
  const [imagesList, setImagesList] = useState({})

  const renderFile = useCallback(async (file) => {
    const { data: { file: fileData } } = await getBucketFile({ bucketId: process.env.REACT_APP_BUCKET_QUIZ_ID, fileKey: file }, accessToken)

    return downloadFromBase64(fileData)
  }, [accessToken])

  const renderRecommendations = (group) => {

    const recommendations = filter(group.answers, item => !isEmpty(item.recommendationsAnswer))
    const recommendationsData = flatMap(recommendations, item => item.recommendationsAnswer)

    return map(recommendationsData, item => `
      <tr>
        <td>${ find(recommendations, recommendation => recommendation.id === item.questionAnswerId)?.quizQuestion?.name }</td>
        <td>
          <p style="text-align: left;">
            ${ item?.recommendation?.recommendation || '-' }
          </p>
          <p style="text-align: left;">
            Causa raiz:
            ${ item?.recommendation?.rootCause || '-' }
          </p>
        </td>
      </tr>
    `).join(' ')
  }


  const onLoadFrame = useCallback((index) => {
    const currentData = { ...height }
    currentData[index] = `${ frameRef.current[index].contentWindow.document.body.scrollHeight + 80 }px`
    setHeight(currentData)
  }, [height])

  return isEmpty(data) ? (
    <EmptyState
      text={ t('no results found') }
      icon={ emptyFilter }
    />
  ) : (
    <Container>
      {map(data, (row, index) => {
        map(filter(flatMap(row?.quiz?.questionGroup, group => flatMap(group?.answers, item => item)), item => item?.quizQuestion?.type === FORM_INPUT_TYPES.image), async item => {
          const currentImages = { ...imagesList }
          const file = await renderFile(item?.answer)
          currentImages[item?.id] = file
          setImagesList(currentImages)
        })
        return (
          <CardContainer
            height={ height[index] }
            ref={ el => {frameRef.current[index] = el} }
            onLoad={ () => onLoadFrame(index) }
            key={ `div-${ index }` }
            title={ `report-${ index }` }
            id={ `iframe-${ index }` }
            scrolling='no'
            srcDoc={ flow(
              replaceFP('{tableData}', map(row?.quiz?.questionGroup, group => !isEmpty(filter(group?.answers, item => item?.quizQuestion?.type !== FORM_INPUT_TYPES?.signature)) ?
                `<table>
          <thead>
            <tr>
              <th scope="col" colSpan="2">➧ ${ toUpper(group?.description === 'default' ? row?.quiz?.name : group?.description) }</th>
            </tr>
          </thead>
          <tbody id="result">
          ${ map(group?.answers, item => item?.quizQuestion?.type !== FORM_INPUT_TYPES?.signature ? `
          <tr>
            <td>${ item?.quizQuestion?.name }</td>
            <td>${ item?.quizQuestion?.type === FORM_INPUT_TYPES.image ? `<img scr="${ imagesList[item?.id] }" height="100" />` : item?.answer }</td>
          </tr>
          ` : '').join(' ') }
          </tbody>
        </table>
        ${ !isEmpty(filter(group?.answers, (answer) => !isEmpty(answer?.recommendationsAnswer))) ?
            `
              <table>
              <thead>
                <tr>
                  <th scope="col" colSpan="2">➧ RECOMENDAÇÕES TÉCNICAS</th>
                </tr>
              </thead>
              <tbody id="result">
                ${ renderRecommendations(group, row) }
                </tbody>
            </table>
              ` : '' }
        ` : '').join(' ')),
              replaceFP('{quizName}', row?.quiz?.name),
              replaceFP('{quizName}', row?.quiz?.name),
              replaceFP('{producerName}', row?.proprietary?.name),
              replaceFP('{technicalName}', row?.technicianOrganizationUser?.user?.name),
              replaceFP('{stateRegisterment}', row?.technicianOrganizationUser?.registry),
              replaceFP('{organizationName}', row?.technicianOrganizationUser?.organization?.tradeName),
              replaceFP('{city}', user?.city),
              replaceFP('{currentDate}', moment().format(momentFriendlyDateTimeFormat)),
              replaceFP('{signatures}', () => {
                const signatures = filter(row?.answers, answer => answer?.quizQuestion?.type === FORM_INPUT_TYPES?.signature)

                return map(signatures, signature => `
              <div class="signature">
              <img class="logo" src="${ downloadFromBase64(signature?.answer) }" />
              <p>${ signature?.quizQuestion?.name }</p>
              </div>
            `)
              }),
              replaceFP('{createdAt}', moment(row?.createdAt).format(momentFriendlyDateTimeFormat))
            )(row?.proprietary?.name ? technicalEvaluationReport : technicalEvaluationReportNoProducer) }
          />
        )

      })}
    </Container>
  )
}

TechnicalEvaluationReport.propTypes = {
  data: PropTypes.array.isRequired,
  accessToken: PropTypes.string
}

TechnicalEvaluationReport.defaultProps = {
  accessToken: null
}

export default TechnicalEvaluationReport
