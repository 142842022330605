import styled from 'styled-components'

export const PeriodFormRow = styled.div`
  display: flex;
  gap: 2em;

  & > *:first-child {
    flex: 2;
  }

  & > *:last-child {
    flex: 1;
  }
`

export const Title = styled.div`
  display: flex;
  font-weight: 700;
  margin-top: 10px;
`