import styled from 'styled-components'

import { colors } from '@smartcoop/styles'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const HeaderTitle = styled.span`
  font-weight: 700;
  font-size: 17px;
`

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${ colors.white };
  padding: 20px;
  border-radius: 5px;
  gap: 2em;
`

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const MainTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  & > span {
    font-weight: 600;
  }
`

export const Badge = styled.div`
  background-color: rgba${ props => props.backgroundColorBadge };
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  width: 80px;
  height: 40px;
  & > span {
    font-size: 13px;
    font-weight: 600;
    font-family: "Open Sans";
    color: rgb${ props => props.colorBadge };
  }
`