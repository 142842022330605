import React, {
  useCallback,
  useState
} from 'react'

import PropTypes from 'prop-types'

import isEmpty from 'lodash/isEmpty'

import registerInseminatorSchema from '@smartcoop/forms/schemas/dairyFarm/registerInseminator.schema'
import I18n, { useT } from '@smartcoop/i18n'
import { colors } from '@smartcoop/styles'
import Button from '@smartcoop/web-components/Button'
import Form from '@smartcoop/web-components/Form'
import InputText from '@smartcoop/web-components/InputText'
import RadioGroup from '@smartcoop/web-components/RadioGroup'

import {
  Container,
  ButtonsContainer,
  FormContainer
} from './styles'

const RegisterInseminatorForm = (props) => {
  const { defaultValues, onSubmit, onCancel, loading, formRef } = props

  const [active, setActive] = useState(defaultValues?.active ?? false)

  const t = useT()

  const handleSubmit = useCallback(
    (data) => {
      onSubmit({
        ...data,
        active: isEmpty(defaultValues) ? true : active
      })
    },
    [active, defaultValues, onSubmit]
  )

  return (
    <Container>
      <Form
        style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
        ref={ formRef }
        schemaConstructor={ registerInseminatorSchema }
        onSubmit={ handleSubmit }
      >
        <FormContainer>
          <InputText
            label={ t('name') }
            name="name"
            fullWidth
            placeholder={ t('name') }
            defaultValue={ defaultValues?.name }
          />
          { isEmpty(defaultValues) ? null : (
            <RadioGroup
              label={ `${ t('status') }:` }
              options={ [
                {
                  label: t('active', { gender: 'male' }),
                  value: true
                },
                {
                  label: t('inactive', { gender: 'male' }),
                  value: false
                }
              ] }
              variant="row"
              value={ active }
              onChange={ (e) => setActive(e.value) }
              detached style={ { alignSelf: 'flex-start' } }
            />
          )}
        </FormContainer>
        <ButtonsContainer>
          <Button
            id="web-cancel-form-button"
            onClick={ onCancel }
            color={ colors.white }
            disabled={ loading }
            fullWidth
            style={ { marginRight: 5 } }
          >
            <I18n>cancel</I18n>
          </Button>
          <Button
            id="web-save-form-button"
            onClick={ () => formRef.current.submit() }
            disabled={ loading }
            fullWidth
            style={ { marginLeft: 5 } }
          >
            <I18n>save</I18n>
          </Button>
        </ButtonsContainer>
      </Form>
    </Container>
  )
}

RegisterInseminatorForm.propTypes = {
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  defaultValues: PropTypes.object.isRequired,
  formRef: PropTypes.object.isRequired
}

RegisterInseminatorForm.defaultProps = {
  loading: false,
  onSubmit: () => {},
  onCancel: () => {}
}

export default RegisterInseminatorForm