import React, { useCallback, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'


import  I18n, { useT } from '@smartcoop/i18n'
import { useSnackbar } from '@smartcoop/snackbar'
import { selectCurrentProperty } from '@smartcoop/stores/property/selectorProperty'
import { TechnicalActions } from '@smartcoop/stores/technical'
import Loader from '@smartcoop/web-components/Loader'
import Modal from '@smartcoop/web-components/Modal'
import RegisterTechnicalVehiclesForm from '@smartcoop/web-containers/forms/technical/RegisterTechnicalVehiclesForm'

import useStyles from './styles'

const RegisterTechnicalVehiclesModal = (props) => {
  const {
    id,
    open,
    onSubmit,
    handleClose,
    defaultValues
  } = props

  const formRef = useRef(null)
  const classes = useStyles()
  const snackbar = useSnackbar()
  const dispatch = useCallback(useDispatch(), [])
  const t = useT()
  const currentProperty = useSelector(selectCurrentProperty)
  const [isLoading, setIsLoading] = useState(false)

  const closeModal = useCallback(
    () => {
      setIsLoading(false)
      handleClose()
    }, [handleClose]
  )

  const onSuccess = useCallback(
    () => {
      snackbar.success(
        t(`your {this} was ${  defaultValues?.id ? 'edited' :'registered' }`, {
          howMany: 1,
          gender: 'male',
          this: t('vehicle')
        })
      )
      onSubmit()
      closeModal()
    }, [closeModal, defaultValues, onSubmit, snackbar, t]
  )

  const handleSubmit = useCallback(
    (data) => {
      setIsLoading(true)
      dispatch(TechnicalActions.createTechnicalVehicle(
        {
          propertyId: currentProperty?.id,
          ...defaultValues,
          ...data
        },
        onSuccess,
        () => setIsLoading(false),
        true
      ))
    },
    [dispatch, currentProperty, defaultValues, onSuccess]
  )

  return (
    <Modal
      id={ id }
      open={ open }
      title={
        <>
          <I18n params={ { this: t('vehicle') } }>{'register {this}'}</I18n>
        </>
      }
      disableFullScreen
      disableEsc
      classes={ { paper: classes.modalBackground } }
      contentContainerStyle={ { minWidth: 300 } }
      headerProps={ {
        titleClass: classes.title,
        disableTypography: true
      } }
    >
      <>
        {
          isLoading ? <Loader width={ 100 } /> :
            <RegisterTechnicalVehiclesForm
              formRef={ formRef }
              defaultValues={ defaultValues }
              onSubmit={ handleSubmit }
              onCancel={ closeModal }
              loading={ isLoading }
              propertyId={ currentProperty?.id }
            />
        }
      </>
    </Modal>
  )}

RegisterTechnicalVehiclesModal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  onSubmit: PropTypes.func,
  defaultValues: PropTypes.object
}

RegisterTechnicalVehiclesModal.defaultProps = {
  handleClose: () => {},
  onSubmit: () => {},
  defaultValues: {}
}

export default RegisterTechnicalVehiclesModal