/* eslint-disable no-nested-ternary */
import React, { useState, useMemo, useCallback, useEffect, useRef, memo } from 'react'
import { isMobile } from 'react-device-detect'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'

import html2pdf from 'html2pdf.js'
import PropTypes from 'prop-types'

import filter from 'lodash/filter'
import find from 'lodash/find'
import groupBy from 'lodash/groupBy'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'

import { useT } from '@smartcoop/i18n'
import { emptyFilter , printer } from '@smartcoop/icons'
import { getCosts } from '@smartcoop/services/apis/smartcoopApi/resources/costs'
import { createGrowingSeasonReport } from '@smartcoop/services/apis/smartcoopApi/resources/growingSeasons'
import { getReport } from '@smartcoop/services/apis/smartcoopApi/resources/property'
import { createTechnicalVisitReport, getTechnicalEvaluationReport } from '@smartcoop/services/apis/smartcoopApi/resources/technical'
import { selectCurrentModule } from '@smartcoop/stores/module/selectorModule'
import { colors } from '@smartcoop/styles'
import EmptyState from '@smartcoop/web-components/EmptyState'
import Icon from '@smartcoop/web-components/Icon'
import IconButton from '@smartcoop/web-components/IconButton'
import Loader from '@smartcoop/web-components/Loader'
import EndOfSeasonReport from '@smartcoop/web-containers/fragments/digitalProperty/EndOfSeasonReport'
import IncomeReport from '@smartcoop/web-containers/fragments/digitalProperty/IncomeReport'
import TechnicalEvaluationReport from '@smartcoop/web-containers/fragments/technical/TechnicalEvaluationReport'
import TechnicalVisitReport from '@smartcoop/web-containers/fragments/technical/TechnicalVisitReport'
import { withLayout } from '@smartcoop/web-containers/layouts'

import { ContainerList, Container } from './styles'

const ReportDownloadScreen = ({ defaultReportData = null }) => {
  const t = useT()
  const { reportId = null } = useParams()
  const contentRef = useRef(null)

  const currentModule = useSelector(selectCurrentModule)
  const [loading, setLoading] = useState(false)
  const [report, setReport] = useState(null)
  const [years, setYears] = useState([])
  const [showPlagueReports, setShowPlagueReports] = useState(true)
  const [showManagements, setShowManagements] = useState(true)
  const [showTimeline, setShowTimeline] = useState(true)
  const [reportData, setReportData] = useState(null)
  const [accessToken, setAccessToken] = useState(null)

  const loadReport = useCallback(async () => {
    const response = await getReport({ reportId })
    const parsed = JSON.parse(response?.data?.reportData)
    setAccessToken(parsed?.access)
    setReport(parsed)
  },[reportId])

  useEffect(() => {
    if(defaultReportData?.showReport) {
      setReport(defaultReportData)
    } else if(isEmpty(report) && !isEmpty(reportId)){
      loadReport()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultReportData])

  const onGenerate = useCallback(async () => {
    try{
      const updatedReport = { ...report }
      delete updatedReport.property
      delete updatedReport.owner
      delete updatedReport.user
      delete updatedReport.access

      setLoading(true)
      if(report?.year) {
        setYears([report?.year])
      }
      if(report?.sowingYears) {
        setYears(report?.sowingYears)
      }
      if(report?.selectedForm === 'technicalVisit') {
        const params = {
          ...updatedReport,
          startDate: report?.period?.from,
          endDate: report?.period?.to || report?.period?.from
        }
        delete params.period
        const response = await createTechnicalVisitReport(params, accessToken)
        setReportData(response?.data || [])
      } else if (report?.selectedForm === 'technicalEvaluation') {
        if(!isEmpty(report?.period?.from)) {
          const params = {
            ...updatedReport,
            startDate: report?.period?.from,
            endDate: report?.period?.to || report?.period?.from
          }
          delete params.period

          const response = await getTechnicalEvaluationReport(params, accessToken)
          setReportData(response?.data?.data || [])
        }
      } else if(report?.selectedForm === 'endOfSeason') {
        setShowTimeline(report?.timeline)
        setShowManagements(report?.cropManagement)
        setShowPlagueReports(report?.plagueReport)
        const response = await createGrowingSeasonReport({ sowingYears: years, ...updatedReport, timeline: true, cropManagement: true, plagueReport: true, propertyId: report?.propertyId }, accessToken)
        setReportData(response?.data?.data || [])
      }else if (report?.selectedForm === 'dre') {
        const response = await getCosts({ ...updatedReport, propertyId: report?.propertyId, lotTypes: report?.lotTypes }, accessToken)
        if(report?.generatedModel === 'milk') {
          setReportData(response?.data ? filter(response?.data, item => find(item?.activities, activity => activity?.activity?.slug === 'pecuaria-de-leite')) : [])
        } else {
          setReportData(response?.data || [])
        }
      }
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }, [accessToken, report, years])

  useEffect(() => {
    if(!isEmpty(report)) {
      onGenerate()
    }
  },[onGenerate, report])

  const handlePrint = useReactToPrint({
    content: () => contentRef.current
  })

  const handleDownload = useReactToPrint({
    content: () => contentRef.current,
    print: async (printIframe) => {
      const opt = {
        margin: [30, 30, 30, 30],
        filename: 'relatorio-smartcoop.pdf',
        image: { type: 'jpeg', quality: 0.2 },
        html2canvas: { scale: 2 },
        jsPDF: {
          format: 'a4',
          orientation: 'portrait',
          hotfixes: ['px_scaling'],
          unit: 'px'
        },
        pageBreak: { mode: 'legacy' },
        compressPDF: true
      }

      const blob = html2pdf()

      await blob
        .set(opt)
        .from(printIframe)
        .toPdf()
        .save()
    }
  })

  const dreData = useMemo(() => {
    if (report?.generatedModel === 'milk') {
      return groupBy(filter(map(reportData, cost => ({ ...cost, value: cost?.rubricItem?.rubricGroup?.operationType === 'debit' && cost?.value ? cost?.value * -1 : cost?.value || 0 })), item => isEmpty(item?.cropManagementId)), 'rubricClassification')
    }
    return groupBy(map(reportData, cost => ({ ...cost, value: cost?.rubricItem?.rubricGroup?.operationType === 'debit' && cost?.value ? cost?.value * -1 : cost?.value || 0 })), 'rubricClassification')
  },[report, reportData])


  const renderReport = useMemo(() => ({
    'endOfSeason': <EndOfSeasonReport accessToken={ accessToken } reportValues={ report } data={ reportData } showTimeline={ showTimeline } showManagements={ showManagements } showPlagueReports={ showPlagueReports } />,
    'technicalVisit': <TechnicalVisitReport accessToken={ accessToken } reportValues={ report } data={ groupBy(reportData, 'visitId')  } showRecommendations={ report?.showRecommendations } />,
    'technicalEvaluation': <TechnicalEvaluationReport accessToken={ accessToken } reportValues={ report } data={ reportData  } showRecommendations={ report?.showRecommendations } />,
    'dre': <IncomeReport isMobile={ isMobile } accessToken={ accessToken } reportValues={ report } model={ report?.generatedModel } groupedBy={ report?.generatedGroup } data={ dreData } years={ years }/>
  }[report?.selectedForm]), [accessToken, dreData, report, reportData, showManagements, showPlagueReports, showTimeline, years])

  return loading ? (
    <Loader width={ 100 } />
  ) : isEmpty(reportData) && !defaultReportData?.showReport ? (
    <ContainerList style={ { justifyContent: 'center', alignItems: 'center' } }>
      <EmptyState
        text={ t('no results found') }
        icon={ emptyFilter }
      />
    </ContainerList>
  ) : (
    <Container>
      <div style={ { position: 'fixed', top: currentModule ? '70px' : '2vh', right: '1vw', zIndex: 99999 } }>
        <IconButton
          tooltip={ t('save') }
          onClick={  isMobile ? handleDownload : handlePrint }
          shadow
          style={ { backgroundColor: '#fff', borderRadius: 20 } }
        >
          <Icon size={ 20 } icon={ printer } color={ colors.black } />
        </IconButton>
      </div>
      <ContainerList  ref={ contentRef } >
        {renderReport}
      </ContainerList>
    </Container>
  )
}
ReportDownloadScreen.propTypes = {
  defaultReportData: PropTypes.object
}

ReportDownloadScreen.defaultProps = {
  defaultReportData: null
}

export default memo(withLayout('report')(ReportDownloadScreen))
